.wrapper {
  padding: 20px;
  .prompt {
    color: #f5222d;
  }
  .row {
    padding-left: 15px;

    .col {
      margin-bottom: 35px;

      .prompt {
        color: #f5222d;
      }
    }

    .clockIcon {
      svg {
        color: #bfbfbf;
      }
    }

    .customInput {
      .suffix {
        position: absolute;
        margin-top: 35px;
        margin-left: 10px;
      }
    }
  }

  .tips {
    padding-left: 10px;
    color: #999;
    padding-bottom: 20px;

    ul {
      list-style: none;
    }

    .attention {
      float: left;
    }
  }
}
