.topStyles {
  padding: 32px 0 32px 16px;
  position: relative;
  .circleLine {
    position: absolute;
    top: 32px;
    left: 20px;
    width: 188px;
    height: 188px;
    opacity: 0.5;
    border-radius: 50%;
    background: rgba(0, 118, 255, 0.05);
    box-sizing: border-box;
    padding: 4px;
    background-image: linear-gradient(180deg, rgba(37, 130, 183, 1), rgba(68, 120, 155, 0.25));
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #1c202380;
    }
  }
}

.bottomStyles {
  width: 100%;
  .tabsStyle {
    width: 100%;
    :global {
      .ant-tabs-nav-list,
      .ant-tabs-nav-operations {
        height: 32px;
      }
      .ant-tabs-tab {
        font-size: 14px;
        font-weight: 400;
        color: #94bdec;
        line-height: 20px;
        padding: 5px 16px;
        border-radius: 2px;
        border: 1px solid #295281;
        height: 32px;
        &:hover {
          background: rgba(74, 144, 226, 0.15);
        }
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 8px !important;
      }
      .ant-tabs-tab-active {
        height: 32px;
        background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
        border-radius: 2px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
        .ant-tabs-tab-btn {
          color: #fff !important;
        }
        &:hover {
          background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
        }
      }
      .ant-tabs-ink-bar {
        height: 1px;
        background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
      }
      .ant-tabs-nav-operations {
        margin-left: 8px;
        border-radius: 2px;
        border: 1px solid #295281;
      }
    }
  }
}

.bottomChartsStyles {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :global {
    .ant-spin-nested-loading {
      height: 100%;
    }
    .ant-spin-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.pieAndLineflexStyles {
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
}
