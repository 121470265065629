.workCenter {
  position: relative;
  margin-bottom: 0;
}
.topSelect {
  margin-top: 8px;
  margin-bottom: 15px;
}
.workCenterDatePicker {
  position: absolute;
  left: 435px;
  top: 59px;
}
