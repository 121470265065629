.outShowBaseInfo {
  border: 1px solid rgba(var(--base-text-color), 0.15);
  background: rgba(var(--base-text-color), 0.03);
}

.col {
  width: 100px;
  background: rgba(var(--base-text-color), 0.03);
}

.downloadBill {
  position: sticky;
  top: 0;
  left: 0;
  padding-left: 28px;
  z-index: 999;
}
