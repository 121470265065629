.stepsStyle {
  width: 100%;
  height: 110px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .ant-steps-item-title::after {
    background: var(--disabled-color) !important;
  }
}

.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  .col {
    margin-bottom: 30px;
  }
  // 本来是做数字输入框的后置单位
  // .colUnit {
  //   margin-bottom: 30px;
  //   label::before {
  //     display: inline-block;
  //     margin-right: 4px;
  //     color: #ff4d4f;
  //     font-size: 14px;
  //     font-family: SimSun, sans-serif;
  //     line-height: 1;
  //     content: '*';
  //   }
  // }
}
