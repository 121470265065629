.table_text {
  font-size: 14px;
  .red {
    color: #d62500;
    padding: 0 5px;
    font-weight: 500;
  }
}

.search {
  width: 100%;
  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}
