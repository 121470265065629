.energyTitle {
  width: 100%;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  .energyDate {
    font-size: 14px;
    font-weight: 400;
    color: var(--mx-primary-color);
    margin-left: 5px;
  }
}
.energyStatisticsSwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 94px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  position: relative;
  .verticalLineLeft {
    position: absolute;
    height: 85px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    width: 1px;
    left: 34%;
  }
  .verticalLineRight {
    position: absolute;
    height: 100%;
    background: #39393a;
    width: 10px;
    left: 95%;
    z-index: 100;
  }
  .verticalLineCenter {
    position: absolute;
    height: 85px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    width: 1px;
    left: 50%;
  }
}

.cardStyles {
  // border: 1px solid red ;
  height: 94px;
  // width: 120px;
  margin: 0 auto;
  text-align: center;

  .singleCard {
    height: 94px;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    margin: 0;
    // .withBorder {
    //   border-right: 1px solid rgba(255, 255, 255, 0.15);
    // }
    &::after {
      content: '';
      position: absolute;
      top: 5px; /* 距离上方5px */
      right: 0;
      bottom: 0;
      width: 1px;
      height: 85px;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
    // &.noLine::after {
    //   visibility: hidden;
    // }
    .carImg {
      margin-top: 10px;
    }
    .energyName {
      height: 22px;
      font-size: 14px;
      margin-top: 3px;
      font-weight: 400;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      margin-bottom: 4px;
      padding-right: 4px;
    }
  }
}

.noData {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.45);
  margin: 0 auto;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // width: 113px;
}
