.content {
  padding: 14px 32px;
  :global {
    .ant-form-item-label > label {
      color: var(--mx-text-secondary-color);
    }
  }
  .basicTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 16px;
  }
}
.stickyShadowFooter {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
  background: var(--mx-module-bg-color);
}
