.wrapper {
  overflow: hidden;
  padding: 20px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .rowLeft {
    display: flex;
    .titleSpan {
      margin-bottom: 0;
    }
    .selectBox {
      width: 240px;
      margin-left: 16px;
    }
    .machineList {
      display: flex;
      gap: 8px;
    }
    .machineBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      cursor: pointer;
      .iconStyle {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      &:hover {
        color: var(--mx-primary-color);
      }
    }
    .active {
      background: rgba(74, 144, 226, 0.15);
      border: 1px solid var(--mx-primary-color);
      color: var(--mx-primary-color);
    }
  }
}

.middleIndex {
  display: flex;
  gap: 8px;
  .indexCard {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 8px 16px;
    width: calc((100% - 24px) / 4);
    font-size: 14px;
    .indexName {
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .indexInfo {
      margin-top: 8px;
      display: flex;
      color: var(--mx-text-secondary-color);
      align-items: center;

      .left {
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .right {
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .val {
        color: #52e7ff;
      }
      .splitLine {
        height: 16px;
        width: 1px;
        margin: 0 8px 0 24px;
        background-color: var(--mx-base-border-color);
      }
    }
  }
}
