.wrapper {
  padding: 20px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-text-secondary-color);
  line-height: 20px;
  padding: 8px 0;
}

.value {
  font-weight: 500;
  color: var(--mx-text-base-color);
  line-height: 20px;
}

.tableTipSty {
  font-weight: 400;
  color: var(--mx-text-desc-color);
  line-height: 22px;
}

.bindBtnSty {
  margin-bottom: 8px;
}
