.steps {
  margin-top: 20px;
  margin-bottom: 32px;
}

.contentWrapper {
  width: 100%;
  height: 100%;

  .dividerText {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--mx-text-base-color);
    text-shadow: none !important;
  }

  .processText {
    margin-bottom: 8px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: var(--mx-text-base-color);
  }
  .addRowBtn {
    margin-top: 16px;
    margin-bottom: 30px;
    width: 100%;
    height: 32px;
  }
}
