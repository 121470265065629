.modal {
  .tips {
    display: flex;
    justify-content: flex-end;
  }
}

.count {
  color: #d62500;
  font-weight: 500;
  padding: 0 6px;
}

.table_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
