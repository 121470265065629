.wrapper {
  margin-bottom: 15px;
}

.tips {
  display: flex;
  .blue {
    padding: 0 5px;
    color: var(--mx-primary-color);
  }
  .info {
    padding-top: 2px;
    font-size: 14px;
  }
}
