.energyStatisticsSwiper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardStyles {
  height: 88px;
  background: rgba(0, 118, 255, 0.05);
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid transparent;
  background-image: linear-gradient(
      to right,
      rgba(0, 153, 255, 0.3),
      rgba(0, 153, 255, 1) 50%,
      rgba(0, 153, 255, 0.3) 100%
    ),
    linear-gradient(to bottom, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 0) 50%, rgba(0, 153, 255, 0.3) 100%),
    linear-gradient(to left, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 1) 50%, rgba(0, 153, 255, 0.3) 100%),
    linear-gradient(to top, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 0) 50%, rgba(0, 153, 255, 0.3) 100%);
  background-origin: border-box;
  background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
  background-position: top left, top right, bottom right, bottom left;
  background-repeat: no-repeat;
  overflow: hidden;

  .info {
    background: rgba(0, 118, 255, 0.05);
    border-radius: 2px;
    .firstRow {
      display: flex;
      align-items: center;
      padding: 12px 12px 0 12px;
      justify-content: space-between;
      :first-child {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
        vertical-align:top;
      }
      :last-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        vertical-align:top;
      }
    }
    .secondRow {
      display: flex;
      align-items: center;
      padding: 0 12px;
      :first-child {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      :last-child {
        padding-left: 4px;
        padding-bottom: 6px;
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.65);
        line-height: 17px;
      }
    }
  }
}
