.content {
  padding: 20px 34px;
}
.steps {
  padding: 0 90px;
}
.firstStep {
  padding: 0 48px;
}
.txt {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.45);
  padding-left: 4px;
}
.finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 274px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
}
