$prefixCls: mx-mentions;

.#{$prefixCls} {
  width: 100%;
  min-height: 180px;

  &__input {
  }

  &__suggestions {
    transform: translate(10px, 10px);
    max-height: 300px;
    width: 200px;
    overflow-y: auto;
    // background: var(--body-background);
    // box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

    &__list {
      background-color: var(--component-background);
    }

    &__item {
      box-sizing: border-box;
      &-focused {
        background: var(--line-color);
      }
      .point {
        padding: 8px;
        .display {
          // height: 20px;
          line-height: 20px;
          font-size: 14px;
          word-break: break-all;
        }
        .description {
          height: 14px;
          line-height: 14px;
          font-size: 12px;
        }
      }
    }
  }
  textarea {
    border-color: var(--mx-base-border-color);
  }
}
