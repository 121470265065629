.plantCard {
  height: 229px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.codeInfo {
  display: flex;
  height: 91px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  .codeInfoLeft {
    width: 100px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
    text-align: center;
    padding: 20px 8px;

    div:nth-child(2) {
      margin-top: 8px;
    }
  }
  .codeCard {
    flex: 1;
    padding-right: 8px;
    width: 0;
    .dataNullStyle {
      height: 72px;
      background: #2d2d2d;
      margin: 8px 0 11px 0px;
      line-height: 72px;
      text-align: center;
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

.histogramBox {
  display: flex;
  height: 137px;
  .leftInfo {
    width: 90px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    text-align: center;
    line-height: 137px;
  }
}

.bgLine {
  width: 100%;
  height: 72px;
  margin: 8px 0 11px 0px;
  display: flex;
  background-size: 10px 10px;
  background-color: rgba(156, 156, 156, 1);
  position: relative;
  background-image: -webkit-linear-gradient(
    -45deg,
    #535354 25%,
    transparent 25%,
    transparent 50%,
    #535354 50%,
    #535354 75%,
    transparent 75%,
    transparent
  ) !important;
  background-image: linear-gradient(
    -45deg,
    #535354 25%,
    transparent 25%,
    transparent 50%,
    #535354 50%,
    #535354 75%,
    transparent 75%,
    transparent
  );
  .codeCardItem {
    cursor: pointer;
    position: absolute;
    border-left: 3px solid #ffffff;
    box-sizing: border-box;
    padding: 12px 16px;
    height: 72px;

    div:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div:nth-child(2) {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.plantTitle {
  margin-bottom: 8px;
  span:nth-child(1) > img {
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
  div:nth-of-type(1) > span:nth-child(1) {
    width: 177px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 20px;
  }
  div:nth-of-type(1) > span:nth-child(2) {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
  }
}

.echarts {
  margin: 8px 0px 8px 0px;
  flex: 1;
  width: 0;
  height: 123px;
  .energyNullStyle {
    height: 123px;
    background: #2d2d2d;
    text-align: center;
    line-height: 123px;
    color: rgba(255, 255, 255, 0.65);
    margin: 0px 8px;
  }
}

.hoverStyle {
  width: 180px;
  height: 58px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
}
