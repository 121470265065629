.outer35 {
  width: 168px;
  height: 20px;
  overflow-wrap: break-word;
  text-align: left;
  white-space: nowrap;
  font-size: 0;
  margin: 14px auto 45px;
}

.txt28 {
  font-size: 14px;
  font-family: PingFangSC-Regular, sans-serif;
  color: rgba(var(--base-text-color), 0.35);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info29 {
  font-size: 14px;
  font-family: PingFangSC-Regular, sans-serif;
  color: rgba(24, 144, 255, 1);
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label1 {
  width: 40px;
  height: 40px;
  // margin: 98px 0 0 514px;
  margin: 68px auto 10px;
}
