.slot-field {
  display: flex;
  gap: 12px;
  height: 66px;
  .ant-form-item {
    flex: 1;
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
  }
  .ant-row.ant-form-item-row {
    flex-direction: column !important;
  }
}
