.addRowBtn {
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
}

.content {
  :global {
    .ant-select.ant-select-in-form-item {
      width: 100%;
    }
    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}
