.baseCol {
  text-align: center;
}
// 字段数据列
.typeCol {
  @extend .baseCol;
  width: 20px !important;
  border: 0 !important;
  border-right: 0 !important;
  padding: 12px 45px !important;
  border-top-left-radius: 4px !important;
  &::before {
    background-color: #262d36 !important;
  }
}
// 原始数据列
.befCol {
  @extend .baseCol;
  border: 0 !important;
  padding: 12px 40px !important;
  border-top-right-radius: 10px !important;
  &::before {
    background-color: transparent !important;
  }
}

// 空白数据列
.spaceCol {
  border: 0 !important;
  padding: 12px 40px !important;
  background-color: transparent !important;
  &::before {
    background-color: transparent !important;
  }
}

// 编辑后数据列
.editedCol {
  @extend .baseCol;
  padding: 12px 42px !important;
  border-bottom: 0 !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px;
}

.editedShowCol {
  padding: 12px 42px !important;
  border-bottom: 0 !important;
  background: white !important;
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px;
}

// 模式对话框的表格样式
.modal {
  :global {
    .ant-table {
      line-height: 1.25 !important;
      font-size: 13px !important;
    }
    .ant-table-thead > tr > th {
      text-align: center;
    }
    .ant-table-thead > tr > th:nth-child(4) {
      background-color: var(--primary-color);
    }
  }
}
