.toolBar {
  margin-bottom: 20px;
}
.container {
  position: relative;
  .gateway_box {
    position: absolute;
    top: 8px;
    left: 285px;
    z-index: 999;
  }
}