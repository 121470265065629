.helperClass {
  z-index: 10000 !important;
  color: #1890ff;
}

.liStyle {
  list-style: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  padding: 5px 10px;
  margin-bottom: 8px;
  border-radius: 2px;
  height: 32px;
  span {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
  }
}
