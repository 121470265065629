.personalFormContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0px;

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    .formTitle {
      width: 88px;
      background-image: url(../../imgs/small_title_bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 4px;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    // padding: 8px 16px 10px;
    padding: 0px 16px;
    height: 0px;
  }
}
