.tableStyles {
  margin-top: 8px;

  :global {
    .ant-table-summary {
      display: table-header-group;
    }
  }
}

.summaryCellStyle {
  background-color: var(--mx-tree-selected-bg-color) !important;
}
