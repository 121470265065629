.content {
  background-color: rgb(35, 35, 36);
  position: relative;
}
.empty {
  position: absolute;
  left: 44%;
  top: 28%;
}
.rightContent {
  font-size: 16px;
  // padding-left: 32px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  .descriptionTitle {
    padding-bottom: 16px;
  }
  .tablePadding {
    padding-top: 20px;
  }
  .msgTitle {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
  }
}
.toggleBtn {
  cursor: pointer;
  position: absolute;
  z-index: 11;
  top: 48%;
  margin-top: -80px;
  width: 20px;
  height: 66px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}
