.customNode {
  width: 100%;
  height: 100%;

  .nodeTitle {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 4px;
    .title {
      font-weight: 400;
      font-size: 14px;
      color: var(--mx-text-base-color);
      line-height: 22px;
    }
  }
  .nodeBox {
    height: calc(100% - 30px);
    border-radius: 2px;
    border: 1px solid rgba(74, 144, 226, 0.3);
    width: 100%;
  }
}
