.descNode {
  font-weight: 400;
  width: max-content;
  font-size: 12px;
  line-height: 20px;
  z-index: 10;
  color: var(--mx-text-secondary-color);
  .monitorTitle {
    display: flex;
    gap: 8px;
    margin-bottom: 2px;
  }
  .indicatorRow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    width: max-content;
  }
  .value {
    color: #faad14;
  }
}
