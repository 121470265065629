.deviceCards {
  width: 100%;
  display: flex;
  padding: 15px 24px;
  background-color: #1c202380;

  .singleCard {
    width: 100%;
    height: 72px;
    // height: 200px;
    background: rgba(0, 118, 255, 0.05);
    border-radius: 2px;
    position: relative;

    border: 1px solid transparent;
    background-image: linear-gradient(
        to right,
        rgba(0, 153, 255, 0.3),
        rgba(0, 153, 255, 1) 50%,
        rgba(0, 153, 255, 0.3) 100%
      ),
      linear-gradient(to bottom, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 0) 50%, rgba(0, 153, 255, 0.3) 100%),
      linear-gradient(to left, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 1) 50%, rgba(0, 153, 255, 0.3) 100%),
      linear-gradient(to top, rgba(0, 153, 255, 0.3), rgba(0, 153, 255, 0) 50%, rgba(0, 153, 255, 0.3) 100%);
    background-origin: border-box;
    background-size: 100% 1px, 1px 100%, 100% 1px, 1px 100%;
    background-position: top left, top right, bottom right, bottom left;
    background-repeat: no-repeat;
    overflow: hidden;

    .singleCardIn {
      width: 100%;
      height: 100%;
      padding: 14px 16px 0 16px;
      border-radius: 2px;
      .card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .firstRow {
      width: 48%;
      height: 100%;

      .firstRow_first {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .firstRow_firstP {
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          line-height: 17px;
          margin: 0;
        }
      }
      .firstRowP {
        // margin: 0;
        // margin-bottom: 0px;
        // padding-bottom: 16px;
        font-weight: 600;
        color: #00adff;
        font-size: 18px;
        line-height: 25px;
        background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .secondRow {
      width: 48%;
      height: 100%;
      :first-child {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        line-height: 17px;
        margin: 0;
        // padding-bottom: 4px;
      }
      :last-child {
        font-size: 18px;
        font-weight: 600;
        color: #00adff;
        line-height: 25px;
        background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .partitionImgStyle {
      position: absolute;
      right: 16px;
      bottom: 10px;
      width: 61px;
      height: 59px;
    }
  }
  .singleCard + .singleCard {
    margin-left: 16px;
  }
  .otherRow {
    width: 48%;
    height: 100%;
    margin-bottom: 10px;
    .otherRowFirst {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      width: 100%;

      .firstCircle {
        width: 12px;
        height: 12px;
        background: #59d744;
        box-shadow: 0px 2px 4px 0px rgba(89, 215, 69, 0.5);
        border: 2px solid #91ff80;
        border-radius: 50%;
        margin-top: 2px;
      }
      .secondCircle {
        width: 12px;
        height: 12px;
        background: #ff4d4f;
        box-shadow: 0px 2px 4px 0px rgba(255, 77, 79, 0.5);
        border: 2px solid #ff9192;
        border-radius: 50%;
        margin-top: 2px;
      }
      .firstP {
        // width: 31%;
        width: 44px;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        line-height: 17px;
        padding: 0px 9px 0 8px;
      }
      .dashedLine {
        // width: 30%;
        height: 1px;
        border: 1px dashed rgba(255, 255, 255, 0.15);
        margin-right: 5%;
        margin-top: 7px;
      }
      .secondP {
        // width: 30%;
        font-size: 14px;
        font-weight: 600;
        color: #00adff;
        line-height: 20px;
        background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .otherRowFirst + .otherRowFirst {
      margin-top: 10px;
    }
  }
  .deviceImgStyle {
    position: absolute;
    right: 18px;
    bottom: 14px;
    width: 61.33px;
    height: 69.33px;
  }
}

.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
  color: #427dc3;
}

@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}
