.desc {
  padding: 20px 40px;
}

.applyLog {
  max-height: 500px;
  overflow-y: auto;
}

.danger {
  color: #f00;
}
