.list {
  display: flex;
  height: 30px;
  margin-bottom: 16px;
  align-items: center;
  .rank {
    display: inline-block;
    width: 20px;
    line-height: 20px;
    border-radius: 2px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin-right: 4px;
  }
  .info {
    flex: 1;
    .nameAndValue {
      color: rgba(255, 255, 255, 0.65);
      display: flex;
      margin-bottom: -8px;
      font-size: 12px;
    }
    .value {
      flex: 1;
      text-align: right;
      color: rgba(255, 255, 255, 0.85);
    }
    .progress {
      border: 4px dashed rgba(255, 255, 255, 0.1);
    }
  }
}
