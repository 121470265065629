$primaryColor: var(--primary-color);

.value {
  .tag {
    margin-bottom: 5px;
  }
}

.tagValue {
  .tagItem {
    margin-bottom: 4px;
  }
}

.ouModal {
  min-height: 550px;

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }
}
