$component-background: var(--component-background);

.wrapper {
  padding: 20px;
  min-height: calc(100vh - 100px);
  .version {
    width: 66px;
    height: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    color: var(--text-color);
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }
  .tip {
    width: 300px;
    border: 1px dashed #a3a3a3;
    border-radius: 2px;
  }
}

.body {
  flex: 1;
  // margin: 0 10px 10px 10px;
  background-color: $component-background;
  padding-bottom: 60px;
}

.list {
  // padding: 0 20px;
  & > div:last-child {
    border-bottom: none !important;
  }
}

.popConfirm {
  .title {
    font-weight: bold;
  }
  .red {
    color: red;
  }
  .gray {
    color: #999999;
  }
}
