.reportedCECon {
  display: flex;
  flex-direction: column;
  flex: 6;
  height: 304px;

  .CarbonInfo {
    display: flex;
    flex: 1;
    .productCarbonInfo {
      flex: 1;
      height: 240px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      :global {
        .ant-spin-nested-loading,
        .ant-spin-container {
          height: 100%;
        }
      }
    }
    .stageCarbonInfo {
      flex: 2;
      padding-left: 24px;
      // min-height: 232px;
      z-index: 1;
      .title {
        margin-bottom: 16px;
      }
      .stageItem {
        display: flex;
        color: rgba(255, 255, 255, 0.85);
        margin-bottom: 24px;

        .left {
          width: 200px;
          padding-right: 16px;
          position: relative;
          display: flex;
          align-items: center;
          img {
            &:nth-of-type(1) {
              width: 184px;
              position: absolute;
            }
            &:nth-of-type(2) {
              width: 26px;
              margin: auto 8px;
            }
          }
          .serial {
            width: 50px;
            text-align: center;
          }
        }
        .right {
          flex: 1;
          .stageInfo {
            display: flex;
            line-height: 18px;
            > div {
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.radioStyles {
  :global {
    .ant-radio-button-wrapper {
      color: #7fa8d7;
      border-inline-start: 1px solid transparent;
    }
    .ant-radio-button-wrapper:hover {
      background: rgba(74, 144, 226, 0.15);
    }
    .ant-radio-button-wrapper:first-child {
      border-inline-start: 1px solid rgba(0, 173, 255, 0.5);
      margin-right: 8px;
    }
    .ant-radio-button-wrapper:last-child {
      border-inline-start: none;
    }
    .ant-radio-button-wrapper-checked {
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      color: #fff;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: #fff;
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
    }
  }
}
