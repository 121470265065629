.searchArea {
  padding: 0 10px;
  margin: 12px 0;
}
.filter_text {
  color: red;
}

.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .treeBox {
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  }
  :global {
    .ant-spin-nested-loading {
      height: 100px;
    }
  }
}
