.filterArea,
.searchArea {
  padding: 0 10px;
  margin-bottom: 12px;

  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.filterArea {
  padding-top: 12px;
  display: flex;
  align-items: center;

  .label {
    flex: 1;
  }
}
