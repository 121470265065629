.layout {
  min-height: calc(100vh - 180px);
  background-color: var(--mx-page-bg-color);
}
.content {
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  background-color: var(--mx-module-bg-color);
  padding: 15px 10px;
  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 66px;
    background: var(--mx-disabled-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mx-text-base-color);

    &:hover {
      background: rgba(var(--mx-main-color), 0.4);
    }
  }

  .swiperBox {
    padding: 20px;
  }
}
