.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;
  .formWrapper {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    background: rgba(var(--base-text-color), 0.03);
    border-radius: 3px;
    border: 1px solid rgba(var(--base-text-color), 0.15);
    :global {
      .ant-form-item {
        margin-bottom: 0px!important;
      }
    }
  }
}

.formListWrapper {
  padding-left: 20px;
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.totalAmount {
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  padding: 7px 10px;
}
