.timeTip {
  display: flex;
  margin-left: 80px;
  flex-direction: row;
  align-items: center;
  position: absolute;
  width: 400px;
  margin-top: -32px;

  span {
    font-size: 12px;
    margin-left: 3px;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;

  .suffix {
    margin-right: 10px;
  }

  .form {
    display: flex;
    flex-direction: row;

    .clockIcon {
      position: absolute;
    }
  }
}
