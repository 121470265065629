.wrapper {
  padding: 20px;
}

.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.calendar {
  :global {
    .ant-picker-calendar-mode-switch {
      display: none;
    }
  }
}

.dayOff {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(119, 150, 186, 0.2);
}

.calendarSelectPurple {
  :global {
    .ant-select-selector {
      background-color: rgba(246, 51, 255, 0.4) !important;
      border: 1px solid #f633ff !important;
    }
  }
}

.calendarSelectBrown {
  :global {
    .ant-select-selector {
      background-color: rgba(255, 77, 79, 0.4) !important;
      border: 1px solid #ff4d4f !important;
    }
  }
}

.calendarSelectBlue {
  :global {
    .ant-select-selector {
      background-color: rgba(0, 173, 255, 0.4) !important;
      border: 1px solid #00adff !important;
    }
  }
}

.calendarSelectGreen {
  :global {
    .ant-select-selector {
      background-color: rgba(78, 190, 28, 0.4) !important;
      border: 1px solid #4ebe1c !important;
    }
  }
}

.calendarSelectYellow {
  :global {
    .ant-select-selector {
      background-color: rgba(252, 180, 29, 0.4) !important;
      border: 1px solid #fcb41d !important;
    }
  }
}

.calendarSelectUnSchedule {
  :global {
    .ant-select-selector {
      color: rgba(255, 255, 255, 0.25) !important;
      // border: 1px solid #fcb41d !important;
    }
  }
}

.calendarCell {
  :global {
    .ant-space-item {
      width: 85%;
    }
  }
}
