.treeArea {
  padding-top: 4px;
  height: 96%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .treeBox {
    flex: 1;
    overflow: auto;
  }
  :global {
    .ant-spin-nested-loading {
      height: 100px;
    }
  }
}
.titieRenderIcon:hover {
  color: var(--mx-primary-color);
}
.treeTitle {
  // display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  .treeBtn {
    display: none;
  }
}
.treeTitle:hover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .treeBtn {
    display: flex;
  }
}

.title {
  width: 168px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.titleImg {
  width: 16px;
  height: 16px;
}
