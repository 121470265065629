$component-background: var(--component-background);
.cardContainer {
  overflow-x: auto;

  .cardItem {
    margin-right: 9px;
    width: 194px;
    height: 88px;
    display: inline-block;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.page {
  padding: 8px 13px 8px;
  position: relative;
  background-color: var(--mx-page-bg-color);
}

.page::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url('./imgs/bg.svg');
  opacity: 0.6;
}

.breadcrumb {
  padding: 0px 30px;
}
.cardContainerMoreThanTree {
  display: flex;
  padding-bottom: 10px;
  overflow-x: auto;
  .cardItem {
    margin-right: 9px;
    flex: 1;
    min-width: 194px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.layout {
  min-height: calc(100vh - 129px);
  background-color: transparent;
}

.content {
  background: var(--mx-module-bg-color);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: var(--mx-disabled-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mx-text-base-color);

    &:hover {
      background: rgba(var(--mx-main-color), 0.4);
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.filter {
  margin-bottom: 10px;
  overflow: hidden;
}
.commonBtn {
  float: right;
  margin-left: 10px;
}
.tabContent {
  padding: 20px;
  .topDivSty {
    width: 100%;
    display: flex;
    .topLeftDivSty {
      width: 660px;
      padding-right: 10px;
    }
    .topRightDivSty {
      width: calc(100% - 660px);
    }
  }
}

.chartContainer {
  position: relative;
  height: calc(100vh - 400px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortBtn {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_box {
  display: flex;
  border-bottom: 1px solid #303030;
  height: 47px;
}

.spanCursor {
  color: var(--mx-primary-color);
  margin: 5px 0 0 10px;
  cursor: pointer;
  display: inline-block;
}

.position_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.currentSelected {
  margin-bottom: 12px;
  .currentSelectedName {
    font-size: 14px;
    color: var(--mx-text-base-color);
    margin-right: 12px;
  }
}
