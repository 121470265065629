.wrapper {
  padding: 20px;
}

.text {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
