.outer_wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  // background-image: url(./imgs/video.mp4);
  // background-size: 100% auto;
  // background-repeat: no-repeat;
  position: relative;
  .bg_wrapper {
    position: relative;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
      object-fit: cover;
    }
    .item_annotation {
      position: absolute;
      bottom: 35%; /* 目标区域的相对 Y 位置 */
      left: 66%; /* 目标区域的相对 X 位置 */
      width: 60px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 2px;
      transition: transform 0.5s ease;
      pointer-events: none;
      z-index: 11;
      .name_box {
        width: max-content;
        padding: 2px 8px;
        font-size: 16px;
        background: rgba(9, 49, 108, 0.8);
        border-radius: 2px;
        border: 2px solid rgb(76, 226, 255);
        text-align: center;
      }
      .position_img {
        margin-top: 20px;
        width: 30px;
        height: 48px;
        animation: bounce 1s infinite ease-in-out;
      }
      .light_img {
        width: 100%;
        height: 10px;
        animation: scale 1s infinite ease-in-out;
      }
    }
    .highlight {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; /* 覆盖图片的宽度 */
      height: 100%; /* 覆盖图片的高度 */
      pointer-events: none;
      z-index: 1;
    }
    .region {
      fill: transparent;
      stroke: none;
      pointer-events: auto; /* 允许鼠标事件 */
      transition: fill 0.3s ease;
      z-index: 2;
    }

    /* 鼠标悬浮高亮样式 */
    .region:hover {
      fill: url(#gradient); /* 悬浮时应用渐变 */
      z-index: 3;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    pointer-events: none; /* 允许交互事件 */
    .inner_top {
      height: 56px;
      width: 100%;
      pointer-events: auto;
    }
    .inner_center {
      height: 0px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 17px 4px;
      .center_left_box {
        height: 100%;
        width: 360px;
        pointer-events: auto;
      }
      .center_center_box {
        height: 100%;
        flex: 1;
        position: relative;
      }
      .center_right_box {
        height: 100%;
        width: 360px;
        pointer-events: auto;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0); /* 初始位置 */
  }
  50% {
    transform: translateY(-15px); /* 上升位置 */
  }
  100% {
    transform: translateY(0); /* 回到初始位置 */
  }
}

@keyframes scale {
  0% {
    transform: scale(1.5); /* 初始大小 */
  }
  50% {
    transform: scale(1); /* 放大到1.5倍 */
  }
  100% {
    transform: scale(1.5); /* 缩小回原始大小 */
  }
}
