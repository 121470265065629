$height: calc(100vh - 344px);
$component-background: var(--component-background);
$heading-color: var(--heading-color);

.wrapper {
  padding: 0;
  background: none;
  // min-height: calc(100vh - 115px) !important;
}

.wrapperFilter {
  background-color: $component-background;
  padding: 14px 10px 24px 0;
  display: flex;

  .formItem {
    margin: 0 10px;
  }

  .label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: $heading-color;
    font-size: 14px;
    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}

.treeSearchValue {
  color: #f50;
}

.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .searchArea {
    padding: 0 10px;
    margin: 12px 0;
  }
  .treeBox {
    flex: 1;
    overflow: auto;
  }
  :global {
    .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
      align-items: flex-start;
    }
  }
}

.layout {
  background: none;
  min-height: calc(100vh - 150px);
}

.sider {
  margin-right: 10px;
  min-height: $height;
  transition: all 0.2s;
  background-color: var(--component-background);
}

.content {
  background: $component-background;
  border-radius: 2px;
  position: relative;
  // margin-left: 10px;

  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.titleButtonUnChecked {
  background: #232324 !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.65);
}

.titleButtonChecked {
  background: rgba(74, 144, 226, 0.2) !important;
  border-radius: 2px !important;
  border: 1px solid var(--mx-primary-color) !important;
  color: var(--mx-primary-color);
}

.tabContent {
  padding: 0 20px;
}

.filter {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  // flex-wrap: wrap;
}

.dateSwitch {
  // display: flex;
  align-items: center;
  float: left;
  overflow: hidden;
  margin-bottom: 10px;
  .datePickerArea {
    margin-left: 15px;
    display: inline-block;
  }
  .switchBtn {
    // max-width: 262px;
    display: inline-block;
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

.cardContainer {
  overflow-x: auto;
  white-space: nowrap;
  // padding-bottom: 10px;
  .cardItem {
    margin-right: 9px;
    width: 220px;
    display: inline-block;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.cardContainerMoreThanTree {
  display: flex;
  padding-bottom: 10px;
  overflow-x: auto;
  .cardItem {
    margin-right: 9px;
    flex: 1;
    min-width: 155px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.chartContainer {
  position: relative;
  height: calc(100vh - 400px);
  min-height: 450px;
  margin: 20px 0;
}
