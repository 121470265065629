.info {
  // height: 30%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  padding: 16px 16px 8px 16px;
  margin-top: 24px;
  // margin-bottom: 24px;
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    .icon {
      width: 16px;
      height: 16px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      margin: 0;
      padding-left: 8px;
    }
  }
}

.defaultInfo {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
}

@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}

.chartContainerDiv {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
