.formListWrapper {
  position: relative;
  padding-top: 12px;
  padding-left: 16px;
  padding-bottom: 6px;
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);

  .btnPosition {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
  }
}

.warnInfo {
  color: #e64242;
  font-size: 14px;
}
