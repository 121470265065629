.spaceContent {
  width: 100%;
  padding: 20px 0px 6px 0px;
  :global {
    .ant-space-item {
      width: 100%;
    }
  }
}

.btnSty {
  padding: 0 8px;
  height: 48px;
  width: 100%;
}

.titleButtonUnChecked {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background: rgba(74, 144, 226, 0.09);
}

.titleButtonChecked {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(74, 144, 226, 0.2) !important;
  border-radius: 2px !important;
  border: 1px solid var(--mx-primary-color) !important;
  color: var(--mx-primary-color);
}

.disabledSty {
  display: flex;
  align-items: center;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background: #2d2d2d;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.btnContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .btnNameSty {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
  }
  .tagContent {
    width: 94px;
    height: 24px;
    border-radius: 2px;
    text-align: center;
  }
}
