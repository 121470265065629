.modal {
  .tips {
    display: flex;
    justify-content: space-between;
  }
}

.tip {
  font-size: 12px;
  color: #f00;
  margin: 10px 0;
}
