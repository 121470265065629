.unitDetail {
  background-color: #232324;
  height: 100%;
  min-height: 843px;
  .tabWrapper {
    padding: 0;
  }
  .panel {
    padding: 18px 20px;
    .title {
      & > span:first-child {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 17px;
      }
      & > span:last-child {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
        margin-left: 10px;
      }
    }
    .cardWrapper {
      display: flex;
      gap: 8px;

      .left,
      .right {
        padding: 12px 16px;
        height: 76px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
      }
      .left {
        flex: 1;
        .unit {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
          line-height: 20px;
        }
        .specific {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .num {
            font-size: 20px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.85);
            line-height: 28px;
          }
          .val {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.65);
            & > span:last-child {
              font-weight: 600;
              margin-left: 5px;
              &.plus {
                color: #ff4d4f;
              }
              &.minus {
                color: #59d744;
              }
              &.none {
                color: rgba(255, 255, 255, 0.85);
              }
            }
          }
        }
      }
      .right {
        flex: 2;
        display: flex;
        .menuVal {
          padding: 0 16px;
          border-left: 1px solid rgba(255, 255, 255, 0.12);
          flex: 1;
          & > div:nth-child(1) {
            color: rgba(255, 255, 255, 0.65);
            line-height: 20px;
          }
          & > div:nth-child(2) {
            margin-top: 4px;
            font-size: 20px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.85);
            line-height: 28px;
          }
        }
        .peakVal {
          padding-right: 16px;
          flex: 2;
          .valLine {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > div:nth-child(1) {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.65);
            }
            & > div:nth-child(2) {
              border: 1px dashed rgba(255, 255, 255, 0.12);
              flex-grow: 1;
            }
            & > div:nth-child(3) {
              font-size: 16px;
              font-weight: 600;
              color: rgba(255, 255, 255, 0.85);
              padding: 0 8px;
            }
            & > div:nth-child(4) {
              font-size: 12px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.65);
              padding: 0 8px;
            }
          }
        }
      }
    }
  }
}
