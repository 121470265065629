.wrapperStyles {
  padding: 20px;
  min-height: calc(100vh - 128px) !important;
}

.content {
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    height: 200px;
    width: 200px;
    display: inline-block;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 25px;
  }
}

.container {
  position: relative;

  .noMap {
    position: absolute;
    top: calc(50% - 56px);
    left: calc(50% - 140px);
    text-align: center;

    .img {
      width: 48px;
      height: 48px;
    }
    .desc {
      width: 280px;
      line-height: 22px;
      margin-top: 24px;
      color: rgba(255, 255, 255, 0.65);
      font-size: 14px;
      font-weight: 408;
    }
  }
}

.graph {
  flex: 1;
  height: calc(100vh - 440px);
  background: var(--mx-bg-image, var(--mx-light-bg-image, #fff));
  border: 1px solid var(--mx-base-border-color);
  position: relative;

  body {
    min-width: 40px;
  }
}
