.productonTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--mx-text-base-color);
  line-height: 24px;
  margin-bottom: 16px;
}
.activityContent {
  height: 100%;
  width: 100%;
  .activityTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 14px;
  }
  .analysisTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 16px;
  }
  .blueSpan {
    color: #00adff;
  }
  .GwpTitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--mx-text-base-color);
    line-height: 22px;
  }
  .Gwp {
    margin-top: 8px;
    width: 100%;
    height: 100px;
    background: #2d2d2d;
    border-radius: 2px;
    opacity: 0.9;
    border: 1px solid var(--mx-light-border-color);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: var(--mx-text-secondary-color);
    line-height: 22px;
    margin-bottom: 26px;
    .GwpFirst {
      width: 41.42%;
      position: relative;
      height: 100%;
      .picCarbonFoot {
        margin-top: 17px;
        height: 68px;
        width: 80px;
        margin-left: 27px;
        margin-right: 44px;
      }
      .carbonFootTitle {
        position: absolute;
        top: 39px;
        left: 151px;
      }
      .carbonFootNum {
        font-size: 24px;
        font-weight: 500;
        color: #59d744;
        line-height: 32px;
        left: 269px;
        top: 33px;
        position: absolute;
      }
      .right_Line {
        width: 1px;
        height: 68px;
        background-color: var(--mx-base-border-color);
        position: absolute;
        right: 0;
        top: 16px;
      }
    }
    .GwpSecond {
      width: 29.19%;
      height: 100%;
      position: relative;
      .unitTitle {
        position: absolute;
        left: 22px;
        top: 39px;
      }
      .unit {
        position: absolute;
        right: 66px;
        top: 39px;
        font-size: 18px;
        font-weight: 500;
        color: rgb(var(--mx-main-color));
        line-height: 26px;
      }
      .right_Line {
        width: 1px;
        height: 68px;
        background-color: var(--mx-base-border-color);
        position: absolute;
        right: 0;
        top: 16px;
      }
    }
    .GwpThird {
      position: relative;
      height: 100%;
      width: 29.39%;
      .typeTitle {
        position: absolute;
        left: 22px;
        top: 39px;
      }
      .type {
        position: absolute;
        right: 66px;
        top: 39px;
        font-size: 18px;
        font-weight: 500;
        color: rgb(var(--mx-main-color));
        line-height: 26px;
      }
    }
    .unitCode {
      font-size: 14px;
      font-weight: 500;
      color: var(--mx-text-secondary-color);
      line-height: 22px;
    }
  }
}
.content {
  :global {
    .ant-form-item-label > label {
      color: var(--mx-text-secondary-color);
    }
    .ant-form-item-control {
      color: var(--mx-text-base-color);
    }
  }
}
