.box {
  margin-top: 10px;
  position: relative;
}

.boxBorder {
  padding: 20px 0 0 15px;
  border-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
}
.btn {
  position: absolute;
  top: 5px;
  right: 15px;
}
.thirdUl {
  list-style-type: '-';
  padding-inline-start: 5px;
}
