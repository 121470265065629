.footerRow {
  position: relative;
  height: 0px;
  width: 100%;

  .rowWrapper {
    position: relative;
    background-color: #232324;
    transition: all 0.2s;
    .expand {
      position: absolute;
      cursor: pointer;
      left: calc(50% - 80px);
      align-items: center;
      width: 160px;
      padding: 0 24px;
      line-height: 24px;
      background: #222223;
      box-shadow: 0px -2px 4px 0px #222223;
      border-radius: 5px 5px 0px 0px;
      font-size: 12px;
      display: flex;

      transition: top 0.2s;
      &:hover {
        background-color: var(--mx-primary-color);
        color: #fff;
      }
    }
    .desc {
      flex: 1;
      text-align: left;
    }
    .detailContent {
      display: flex;
      width: 100%;
      padding-top: 8px;
      justify-content: space-between;

      .leftSummary {
        padding: 16px 12px;
        width: 172px;
        flex-shrink: 0;
        color: var(--mx-text-secondary-color);
        text-align: center;
        background: rgba(255, 255, 255, 0.05);
        .splitLine {
          height: 1px;
          width: 100%;
          margin: 16px 0;
          background-color: var(--mx-base-border-color);
        }

        .top {
          color: var(--mx-error-color);
          padding: 0 4px;
        }
        .high {
          color: var(--mx-warning-color);
          padding: 0 4px;
        }
        .middle {
          color: var(--mx-alert-info-color);
          padding: 0 4px;
        }
      }
      .rightTable {
        height: 160px;
        width: calc(100% - 180px);
      }
    }
  }
}
