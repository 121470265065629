.wrapper {
  min-height: calc(100vh - 128px) !important;
  padding: 20px;
}

.input_box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  input {
    outline: none;
    border: none;
  }
}

.empty_box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
