.SelectProduct {
  .headerSearch {
    display: flex;
    justify-content: space-between;
    .rightTip {
      color: var(--mx-text-desc-color);
    }
  }
  .searchInput {
    width: 360px;
    margin-right: 10px;
    margin-bottom: 16px;
  }
}

.standardVal {
  display: flex;
  align-items: center;
  gap: 8px;
  & > span {
    color: var(--mx-text-base-color);
  }
}

.checkData {
  .btnRow {
    margin-bottom: 32px;
  }
  .dataTitle {
    font-size: 16px;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin: 0 0 16px;
  }
  .chartTip {
    color: var(--mx-text-desc-color);
    line-height: 22px;
    font-size: 14px;
    & > span {
      margin-left: 4px;
    }
  }
  .chartRow {
    margin-bottom: 32px;
  }
}

.tipRow {
  color: var(--mx-text-secondary-color);
  margin-top: 20px;
}

.uploadRow {
  margin: 32px 0 70px;
  height: 108px;
  :global {
    .ant-form-item-explain.ant-form-item-explain-connected {
      margin-top: 20px;
    }
  }
}

.generateBaseLine {
  .middleInfo {
    padding: 8px 32px;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.12);
    .infoCol {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .colVal {
        color: var(--mx-text-base-color);
        & > span {
          color: var(--mx-text-secondary-color);
        }
      }
    }
  }
}
