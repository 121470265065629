.content {
  :global {
    .ant-form-item .ant-form-item-control-input-content {
      flex: auto;
      max-width: 100%;
      max-height: 76px;
      overflow: auto;
    }
  }
  .specialTip {
    color: var(--mx-text-desc-color);
  }
}

.contentWrapper {
  padding: 0 24px 24px 24px !important;
}

.treeBox {
  height: 500px;
  overflow-y: auto;
  background-color: transparent;
}
