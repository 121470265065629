.drawWrapper {
  width: 100%;
  margin-top: 8px;
  position: relative;

  .legend {
    position: absolute;
    z-index: 20;
    top: 8px;
    right: 16px;
    color: var(--mx-text-base-color);
    font-size: 12px;
    display: flex;
    gap: 30px;

    .item {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
    }
    .rect {
      width: 12px;
      height: 12px;
    }
  }

  .graph {
    width: 100%;
    height: 100%;
    background: var(--mx-bg-image, var(--mx-light-bg-image, #fff));
    border: 1px solid var(--mx-base-border-color);
    position: relative;

    path {
      pointer-events: none;
    }

    body {
      min-width: 40px;
    }
  }
}

.normalWrapper {
  height: calc(100vh - 278px);
}

.highWrapper {
  height: calc(100vh - 210px);
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}
