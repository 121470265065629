// g6 主体位置

// :global {
//   canvas {
//     position: relative;
//     z-index: 1;
//   }
//   // 网格位置
//   .g6-grid-container {
//     z-index: 0 !important;
//     *,
//     ::before,
//     ::after {
//       // 覆盖 vuetify  background-repeat: no-repeat;
//       background-repeat: repeat;
//     }
//   }
// }

.bgImg {
  background: url('../ViewEnergyMediumTopo/imgs/bg.svg');
}

.topoProcess_container {
  width: 100%;
  height: 250px;
  border: 1px solid #ffffff20;
  position: absolute;
}

.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
}
@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}
