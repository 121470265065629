.card {
  width: 100%;
  transition: all 0.3s;
  background-color: var(--mx-base-bg-color);
  &:hover {
    transform: scale(1.03);
  }
}

.picWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80.8%;
  overflow: hidden;

  .pic {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.middleInfo {
  width: 100%;
  height: 50px;

  .infoStyle {
    width: 100%;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    padding-left: 1.92%;
    color: var(--mx-text-base-color);
  }

  .reportName {
    font-size: 16px;
    font-weight: 600;
  }
}

.bottomInfo {
  width: 100%;
  height: 33px;
  border-top: 1px solid var(--mx-base-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 6px;
  box-sizing: border-box;

  .sameStyle {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px 8px !important;
  }

  .sameStyleNames {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    background-color: var(--mx-primary-color);
    height: 24px;
    border-radius: 16px;
    color: #ffffff;

    span {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      padding: 0px 8px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .btn {
    position: absolute;
    right: 0px;
  }
}
