.analysisDrawer {
  background: linear-gradient(180deg, #2a2a2a 0%, #1f1f1f 100%);
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.12), 0px 9px 28px 0px rgba(0, 0, 0, 0.2),
    0px 6px 16px -8px rgba(0, 0, 0, 0.32);
  :global {
    .ant-drawer-header {
      display: none;
    }
    .ant-drawer-wrapper-body {
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);
      }
    }
    .ant-divider-horizontal {
      margin: 0px;
    }
    .ant-drawer-body {
      padding: 0px;
    }
  }
  .titleWrapper {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleTxt {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
      line-height: 24px;
    }
  }
  .contentWrapper {
    padding: 16px;

    .headerLiquid {
      position: relative;
      margin-bottom: 16px;
      overflow: hidden;
      .liquidBox {
        margin: -38px;
        position: relative;
        z-index: 2;
      }
      .chartCard {
        position: absolute;
        top: 6px;
        left: 72px;
        height: 72px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        align-items: center;
        width: calc(100% - 72px);
        background: linear-gradient(270deg, #48c40a 0%, #49aa19 100%);
        &::after {
          content: '';
          position: absolute;
          width: 96px;
          height: 96px;
          border-radius: 50%;
          background: #232324;
          left: -80px;
        }

        .cardLeft {
          margin-left: 28px;
          & > div:first-child {
            font-size: 12px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.65);
            line-height: 17px;
            margin-bottom: 8px;
          }
          & > div:last-child {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 160px;
          }
        }
        .cardRight {
          margin-right: 12px;
          & > div:first-child {
            width: 48px;
            height: 20px;
            background: #ffffff;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 600;
            color: #49aa19;
            line-height: 20px;
            text-align: center;
            margin-bottom: 8px;
            margin-left: 10px;
          }
          & > div:last-child {
            font-size: 12px;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.65);
            line-height: 17px;
          }
        }
        .splitLine {
          height: 48px;
          border: 1px solid rgba(255, 255, 255, 0.15);
        }
      }
    }
    .timeCard {
      width: 368px;
      height: 62px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px 2px 0px 0px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 12px;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 8px;
      .topLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        margin-bottom: 4px;
        color: rgba(255, 255, 255, 0.85);
        .lineRight {
          font-weight: 400;
          color: rgba(255, 255, 255, 0.85);
          & > span:last-child {
            margin-left: 4px;
          }
        }
      }

      .normal {
        color: #49aa19;
      }
      .tip {
        color: #4a8fe1;
      }
      .warning {
        color: #d89614;
      }
      .danger {
        color: #ff4d4f;
      }
    }
    .footerPie {
      margin-top: 24px;
      .pieTitle {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
      }
    }
  }
}
