.bottom_wrapper {
  pointer-events: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  // padding: 0px 120px;
  .left_wrapper {
    height: 96px;
    padding: 1px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .inner {
      background: rgba(0, 118, 255, 0.05);
      height: 100%;
      border-radius: 4px;
      display: flex;
      align-items: center;

      .number_box {
        width: 120px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        .label {
          font-weight: 500;
          color: #ffffff;
        }
        .value {
          font-family: YouSheBiaoTiHei;
          font-size: 21px;
          color: #ffffff;
          background: linear-gradient(180deg, #00aeff 0%, #65ffe9 39%, #00d4ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .swipper {
        // flex: 1;
        width: 310px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px 21px 0;
        position: relative;
        .swiper_prev_btn {
          top: 36px;
          left: -14px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }

        .swiper_next_btn {
          top: 36px;
          right: 4px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }

        .cardStyles {
          // border: 1px solid red ;
          height: 88px;
          max-width: 96px;
          margin: 0 auto;
          text-align: center;

          .singleCard {
            height: 88px;
            background-image: url(../../imgs/platformDefault.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            :first-child {
              font-family: YouSheBiaoTiHei;
              font-size: 21px;
              color: #ffffff;
              background: linear-gradient(180deg, #00aeff 0%, #65ffe9 39%, #00d4ff 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              line-height: 22px;
              padding-top: 15px;
              margin: 0 auto;
            }
            :last-child {
              font-size: 12px;
              font-weight: 600;
              color: #ffffff;
              line-height: 16px;
              padding-top: 6px;
              margin: 0;
            }
          }
        }
      }
    }
    .background {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      backdrop-filter: blur(10px);
      z-index: 1;
    }
    .moving_element {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      animation: moveAround 8s linear infinite;
      border-radius: 10px;
      /* 圆心和边框一致 */
      transform: translate(-10px, -10px);
      background-image: radial-gradient(#fff 40%, transparent 80%);
    }
  }
  .right_wrapper {
    height: 96px;
    padding: 1px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .inner {
      height: 100%;
      background: rgba(0, 118, 255, 0.05);
      padding: 0 18px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img_wrapper {
        width: 60px;
        height: 60px;
        position: relative;
        z-index: 2;
        :first-child {
          width: 60px;
          height: 60px;
          position: absolute;

          top: 0;
          left: 0;
          animation: rotateImage 1s linear infinite;
        }
        :last-child {
          width: 24px;
          height: 24px;
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
        }
      }
      .right_day_box {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        .label {
          font-weight: 600;
          font-size: 15px;
          color: #ffffff;
          margin: 0 8px;
        }
        .value {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          .each_number {
            width: 30px;
            height: 42px;
            background-image: url(../../imgs/number_bg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center center; /* 背景图居中 */
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
          }
        }
        .day {
          font-weight: 600;
          font-size: 15px;
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
    .background {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      backdrop-filter: blur(10px);
      z-index: 1;
    }
    .moving_element {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      animation: moveAround 8s linear infinite;
      border-radius: 10px;
      /* 圆心和边框一致 */
      transform: translate(-10px, -10px);
      background-image: radial-gradient(#fff 40%, transparent 80%);
    }
  }
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveAround {
  0%,
  100% {
    top: 0px;
    left: 0px;
  }
  33.33% {
    top: 0px;
    left: 100%;
  }
  50% {
    top: 100%;
    left: 100%;
  }
  83.33% {
    top: 100%;
    left: 0px;
  }
}
