.boxBody {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  border-top: none;
  height: 249px;
  display: flex;
}

.boxLeftPart {
  width: 50%;
  padding: 16px 0 0 34px;
}

.boxRightPart {
  padding: 16px 0 0 34px;
}

.showBaseInfo {
  margin-top: 8px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  background: rgba(var(--base-text-color), 0.03);
}
