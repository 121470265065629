.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
}

.contentWrap {
  margin-left: 32px;
}

.col {
  margin-bottom: 30px;
}
