.wrapper {
  margin-bottom: 15px;
}
.tips {
  display: flex;
  .red {
    padding: 0 5px;
    color: red;
  }
}
.meterTags::before {
  display: inline-block;
  content: '*';
  line-height: 1;
  font-size: 14px;
  color: #ff4d4f;
  font-family: SimSun, sans-serif;
  margin-right: 4px;
}
