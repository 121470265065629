.topLeftBorder {
  position: absolute;
  left: -8px;
  top: -8px;
  width: 3px;
  height: 64px;
  background-color: #5d5d5d;
}

.LeftBlueBorder {
  position: absolute;
  left: -9px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background-color: #14d0e4;
}

.bottomLeftBorder {
  position: absolute;
  left: -8px;
  bottom: -8px;
  width: 3px;
  height: 64px;
  background-color: #5d5d5d;
}

.topRightBorder {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 3px;
  height: 64px;
  background-color: #5d5d5d;
}
.bottomRightBorder {
  position: absolute;
  right: -8px;
  bottom: -8px;
  width: 3px;
  height: 64px;
  background-color: #5d5d5d;
}

.rightBlueBorder {
  position: absolute;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background-color: #14d0e4;
}

.topBorder {
  position: absolute;
  left: -1.5%;
  top: -8px;
  width: 103%;
  height: 3px;
  background-color: #5d5d5d;
}

.bottomBorder {
  position: absolute;
  left: -1.5%;
  bottom: -8px;
  width: 103%;
  height: 3px;
  background-color: #5d5d5d;
}

.CardStyle {
  // width: 300px;
  :global {
    .ant-card-head-title {
      font-size: 17px;
    }
  }
}
