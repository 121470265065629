.dashboardSty {
  z-index: 99;
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: #3e3e3e;
  height: 112px;
  width: 500px;
  border-radius: 2px;
  .dividerSty {
    height: 80%;
    border-color: #ffffff15;
  }
  .eachDivSty {
    padding: 16px;
    width: 200px;
    .dashboardInnerContentSty {
      margin-right: -20px;
      padding-right: 10px;
      height: 56px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #ffffff40;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #ffffff10;
      }
    }
    .noPermissionSty {
      padding-top: 16px;
      color: #ffffff65;
      text-align: center;
    }
    .dashboardTitleSty {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }
    .dashboardItemSty {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
      .dashboardItemTitleSty {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 20px;
      }
      .dashboardItemContentSty {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
      }
      .alarmColor {
        color: #ff4d4f;
      }
    }
  }
}

.alarmTitleSty {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
