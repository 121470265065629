.inputNum {
  :global {
    .ant-input-number {
      border: 1px solid red;
    }
  }
}

.inputNumDefault {
}
