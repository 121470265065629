.wrapperStyles {
  padding: 0;
  background-color: transparent;
}

.layout {
  min-height: calc(100vh - 130px);
  background-color: transparent;
}

.content {
  background: #232324;
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  // height: 100%;
}

.toggleBtn {
  cursor: pointer;
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -80px;
  width: 20px;
  height: 66px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}

.iconStyle {
  scale: 0.67;
  width: 28px;
  height: 28px;
  padding-bottom: 4px;
  padding-right: 4px;
}
