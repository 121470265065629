.content {
  padding: 20px;
  min-height: calc(100vh - 200px) !important;
  :global {
    .ant-progress.ant-progress-show-info .ant-progress-outer {
      margin-inline-end: calc(-2em - 8px);
      padding-inline-end: calc(2em + 8px);
      padding-left: 22px;
    }
  }
}
.contentTable {
  // margin-top: 10px;
  // padding: 10px;
  min-height: calc(100vh - 230px);
}

.progress_div {
  display: flex;
  align-items: center;
}
.modalContent {
  :global {
    .ant-progress.ant-progress-show-info .ant-progress-outer {
      margin-inline-end: calc(-2em - 8px);
      padding-inline-end: calc(2em + 8px);
      padding-left: 22px;
    }
  }
}
.prog {
  margin-left: 63px;
  margin-top: 3px;
  width: 86%;
}
.ant_progress_text {
  text-align: left;
  height: 32px;
  line-height: 32px;
  position: absolute;
  left: -62px;
  overflow: hidden;
  width: 80px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messageTextStyle {
  max-width: 200px;
  margin-top: 5px;
  p {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.wrapperPadding {
  padding: 20px;
}
