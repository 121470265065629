.modal {
  .tips {
    display: flex;
    justify-content: flex-end;
  }

  .pageSyle {
    // :global(
    //     .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    //       .ant-select-selector
    //   ) {
    //   border-color: rgba(var(--base-text-color),0.15) !important;
    // }
  }
}
