.wrapper {
  min-height: calc(100vh - 128px) !important;
  padding: 0;
}

.contentBody {
  display: flex;
  padding: 20px 0;
  min-height: 780px;
  background-color: transparent;
  .leftCol {
    width: 480px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0px 20px;
    .leftColTopContent {
      display: flex;
      justify-content: space-between;
    }
  }

  .rightCol {
    // flex: 1;
    width: calc(100% - 480px);
    // height: 78vh;
    // overflow-y: auto;
  }
}

.content {
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    height: 200px;
    width: 200px;
    display: inline-block;
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 25px;
  }
}
