.titleExtraContent {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rateSettingSty {
  margin-left: 15px;
  color: var(--mx-primary-color);
  font-weight: 600;
  cursor: pointer;
}

.nameAndTimeSty {
  display: flex;
  align-items: center;
  .currentSelectedName {
    width: 210px;
    border-right: 1px solid #ffffff40;
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .nameTextStyle {
      color: rgba(255, 255, 255, 0.85);
      font-weight: 500;
    }
  }
}

.cardContent {
  margin: 12px 0;
  display: flex;
  .mainEntryCardContent {
    border-radius: 2px;
    border-top: 3px solid #177ddc;
    position: relative;
    display: flex;
    padding-left: 16px;
    align-items: center;
    height: 72px;
    width: 25%;
    margin-right: 12px;
    background: rgba(255, 255, 255, 0.1);
    .mainCardImg {
      width: 48px;
      height: 48px;
    }

    .mainCardTitle {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 17px;
    }

    .mainCardValue {
      padding-top: 2px;
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 25px;
    }
    .tagSty {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
  .mainExitCardContent {
    border-radius: 2px;
    border-top: 3px solid #49aa19;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    height: 72px;
    width: 25%;
    margin-right: 12px;
    background: rgba(255, 255, 255, 0.1);

    .mainCardTitle {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 17px;
    }

    .mainCardValue {
      padding-top: 2px;
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 25px;
    }

    .tagSty {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }
  .restCardContent {
    display: flex;
    align-items: center;
    height: 72px;
    width: 50%;
    background: rgba(255, 255, 255, 0.1);
    .restCardLossContent {
      flex: 1;
      margin-right: 8px;
      padding-left: 16px;
      .restCardContentTitle {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 17px;
      }
      .restCardContentValue {
        padding-top: 2px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 25px;
      }
    }
  }
}

.lossInfoContent {
  width: 100%;
  padding: 6px 12px;
  background-color: #e6424205;
  border-radius: 2px;
  border: 1px solid rgba(230, 66, 66, 0.3);
  word-wrap: break-word;
  word-break: normal;
}

.nolossInfoContent {
  padding: 6px 12px;
  background: rgba(82, 196, 26, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(82, 196, 26, 0.3);
  color: #52c41a;
}
