.content {
  padding: 0px !important;
  min-height: calc(100vh - 130px) !important;
}
.btnBlank {
  margin-bottom: 10px;
}

.wrapperPadding {
  padding: 20px;
}
