.rightButtons {
  position: absolute;
  right: 20px;
  top: 130px;
  display: flex;
  align-items: flex-start;

  .checkboxStyle,
  .unitStyle {
    margin-right: 16px;
    margin-top: 5px;
  }
}

.selectStyle {
  margin-top: 16px;
  margin-bottom: 14px;
}

.timeStyle {
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-text-secondary-color);
  line-height: 22px;
}
