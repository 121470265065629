.wrapper {
  width: 100%;

  .item {
    width: calc(50% - 8px);
  }

  .itemInput {
    width: calc(50% - 8px);
  }
  .select {
    width: calc(50% - 8px);
    margin-right: 16px;
  }
}
