.tabStyle {
  height: calc(100vh - 216px);
  :global {
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
      background-color: #00adff;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
}

.Eleclegend {
  width: 10px;
  height: 10px;
  background-color: red;
  margin-right: 8px;
}

.Powerlegend {
  width: 10px;
  height: 10px;
  background-color: skyblue;
  margin-right: 8px;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
}
