.operationArea {
  margin-bottom: 10px;
}

.tabs {
  :global {
    .ant-tabs-nav {
      &::before {
        content: none;
      }
    }
  }
}

.wrapper {
  padding: 20px;
  min-height: calc(100vh - 128px) !important;
}

.detail {
  padding: 20px;
  min-height: calc(100vh - 128px) !important;
  :global {
    .ant-descriptions-row > th {
      padding-bottom: 5px;
    }

    .ant-descriptions-row > td {
      padding-bottom: 30px;
    }
  }
}

.table {
  margin-top: 10px;
}

.tips {
  display: flex;
  justify-content: space-between;

  .red {
    padding: 0 5px;
    color: red;
  }
}

.ruleGroup {
  padding: 0 5px;
  color: red;
}

.color_0 {
  &:before {
    content: '● ';
    color: #bfbfbf;
  }
}

.color_1 {
  &:before {
    content: '● ';
    color: #00a854;
  }
}
