.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .searchArea {
    padding: 0 10px;
    margin: 14px 0;
  }

  .treeSearchValue {
    color: #f50;
  }

  .treeBox {
    flex: 1;
    overflow-x: hidden;

    .iconStyle {
      scale: 0.67;
      width: 28px;
      height: 28px;
      padding-bottom: 4px;
      padding-right: 4px;
    }
  }

  .treeStyle {
    height: calc(100vh - 260px);

    /* 1,滚动条 */
    ::-webkit-scrollbar {
      width: 10px;
      /* 纵向滚动条 宽度 */
      height: 5px;
      /* 横向滚动条 高度 */
      background: #1d1d1d;
      /* 整体背景 */
      border-radius: 10px;
      /* 整体 圆角 */
    }

    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #3e3e3e;
      // -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
    }
  }
}
