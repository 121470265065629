.submitDataCon {
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 152px;
  .submitInfo {
    display: flex;
    gap: 16px;
    .submitInfoItem {
      position: relative;
      display: flex;
      flex: 1;

      .left_img {
        position: relative;
        width: 80px;
        height: 80px;
        z-index: 1;
        :first-child {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 0;
          left: 0;
          animation: rotation 1s linear infinite;
        }
        :last-child {
          width: 32px;
          height: 32px;
          position: absolute;
          top: calc(50% - 16px);
          left: calc(50% - 16px);
        }
      }
      .detail {
        position: relative;
        flex: 1;
        margin: 4px 0 4px -40px;
        height: 72px;
        padding: 8px 0 10px 48px;
        background: linear-gradient(90deg, rgba(82, 231, 255, 0.1) 0%, rgba(82, 231, 255, 0.15) 100%);
        border-radius: 0px 2px 24px 0px;
        border: 1px solid rgba(82, 231, 255, 0.1);
        // border-image: linear-gradient(90deg, rgba(82, 231, 255, 0.1), rgba(82, 231, 255, 0.3)) 1 1;

        .count {
          font-weight: 500;
          font-size: 24px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 32px;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 22px;
        }
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
