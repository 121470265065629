.wrapper {
  min-height: calc(100vh - 130px) !important;
}

.full_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 30px;
  cursor: pointer;
  // background: url("./imgs/fullScreen.png") center no-repeat;
  .icon {
    color: #047197 !important;
  }
  .text {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
    background: linear-gradient(90deg, #047197 0%, #0fffef 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.borderStyles {
  height: 100%;
  display: flex;
  flex-direction: column;
  // border-left: 1px solid rgba(255, 255, 255, 0.15);
  // border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.rightContentStyles {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn {
  width: 126px;
  height: 32px;
  background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .bottomLine {
    position: absolute;
    bottom: 0;
    width: 76px;
    height: 1px;
    background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
  }
}

.imgDivJumpStyle {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.bgImgStyle {
  height: 100%;
  background-position: center;
  background-size: cover;
}
