$height: calc(100vh - 344px);
$component-background: var(--component-background);
$heading-color: var(--heading-color);

.wrapperContent {
  padding: 0;
  background: none;
}

.filter {
  background-color: $component-background;
  display: flex;
  flex-wrap: wrap;

  .formItem {
    margin: 0 10px;
  }

  .label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: $heading-color;
    font-size: 14px;
    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}

.container {
  min-height: calc(100vh - 180px) !important;
  background: none;
}

.sider {
  margin-right: 10px;
  min-height: $height;
  transition: all 0.2s;
  background-color: var(--component-background);
}

.content {
  background-color: $component-background;
  position: relative;
  padding-bottom: 10px;
  .lightToggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: #c1c1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--base-text-color), 0.75);
    &:hover {
      background: #7d7d7d;
    }
  }
  .darkToggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: #383839;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--base-text-color), 0.75);
    &:hover {
      background: #555555;
    }
  }
}

.toolBar {
  padding: 0 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    margin-bottom: 18px;
  }

  .title {
    line-height: 32px;
    font-weight: 500;
    margin-right: 24px;
  }
  .highTxt {
    color: var(--primary-color);
  }
}

.unit {
  padding: 0 16px;
  margin-bottom: 10px;
  text-align: right;
}

.main {
  min-height: $height;
  padding: 20px;
}

.emptyWrap {
  min-height: $height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topFilter {
  margin: 10px;
}
