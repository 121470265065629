.wrapper {
  padding: 0;
  background-color: transparent;
  .layout {
    min-height: calc(100vh - 130px);
    background-color: var(--mx-page-bg-color);
  }
  .content {
    background: var(--mx-page-bg-color);
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
    position: relative;
    display: flex;
    flex-direction: column;

    .filterTop {
      padding: 20px 20px 0px 20px;
      background-color: var(--mx-module-bg-color);
    }

    .operationArea {
      margin-top: 10px;
      padding: 20px;
      background-color: var(--mx-module-bg-color);
      flex: 1;
    }

    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 66px;
      background: var(--mx-disabled-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--mx-text-base-color);

      &:hover {
        background: rgba(var(--mx-main-color), 0.4);
      }
    }
  }
}

.unitFormItems {
  width: 100%;
  :global {
    .ant-space-item {
      width: 50%;
    }
  }
}

.DatePicker {
  width: 100%;
}

.InputNumber {
  width: 100%;
}
.Tabs {
  position: relative;
  top: 63px;
  left: 79px;
  z-index: 2;
}

.Space {
  position: absolute;
  top: 182px;
  left: 102px;
  width: 594px;
  height: 56px;
  background: rgba(var(--mx-main-color), 0.08);
  border: 1px solid var(--mx-light-border-color);
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  color: var(--mx-text-secondary-color);
  line-height: 17px;
  display: flex;
  flex-wrap: wrap;
}

.ReactEcharts {
  position: absolute;
  z-index: 0;
  left: -8px;
  top: -28px;
}

.BaselineModal {
  position: relative;
  :global {
    .ant-modal .ant-modal-header {
      margin-bottom: 0;
    }
  }
}

.advancedValueSty {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tooltipSty {
  width: 100%;
}
.advancedSpan {
  cursor: pointer;
  width: auto;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-primary-color);
  line-height: 22px;
}

.page_content_copy {
  background: #232324;
  border-radius: 2px;
  position: relative;
  padding: 20px;
  :global {
    .ant-input-number-group > .ant-input-number:first-child {
      width: 440px;
    }
  }
}

.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.information {
  max-height: 314px;
  border: 1px solid var(--mx-light-border-color);
  padding: 29px 27px;
  overflow: hidden;
  margin-bottom: 24px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.capacityTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--mx-text-base-color);
  line-height: 24px;
  margin-bottom: 21px;
  margin-top: 12px;
}

.addRow {
  width: 100%;
  margin-top: 16px;
  .rowInnerBtn {
    width: 100%;
  }
}
