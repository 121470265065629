.wrapper {
  padding: 0;
  background-color: transparent;
  min-width: 1366px;
  .layout {
    min-height: calc(100vh - 130px);
    background-color: transparent;
  }
  .content {
    background: #232324;
    border-radius: 2px;
    position: relative;
    // margin-left: 10px;
    padding: 20px;

    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      top: 50%;
      margin-top: -33px;
      width: 20px;
      height: 66px;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
    .rightPane {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .dateArea {
      display: flex;
      align-items: center;
      margin: 0 0 16px;
    }
  }

  .analysisChartLoading {
    height: 100%;
    :global {
      .ant-spin-container {
        height: 100%;
      }
    }
  }
}
