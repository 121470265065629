.cardItem {
  height: 74px;
  background: #2d2d2d;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px 16px;

  &:hover {
    border: 1px solid var(--primary-color);
    cursor: pointer;
  }
  p {
    margin-bottom: 0px;
  }

  .firstLine {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .firstLine_left,
    .firstLine_right {
      font-weight: 400;
    }

    span {
      margin-left: 8px;
    }
  }

  .secondLine {
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }
}

.cardItemClicked {
  background: rgba(74, 144, 226, 0.1);
  border: 1px solid var(--primary-color);
}

.swiperWrapper {
  position: relative;
  margin: 0 24px;
}

.swiper_prev_btn {
  top: 28px;
  left: -24px;
}

.swiper_next_btn {
  top: 28px;
  right: -24px;
}
