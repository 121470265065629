@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('../../../../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
}

.swiperStyle {
  background-color: #1c202380;
  height: 71px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 21px 6px 21px;

  .imgStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.cardStyles {
  height: 100%;
  width: 100%;
  font-size: 24px;
  // font-weight: 600;
  color: #ffffff;
  line-height: 25px;
  text-align: center;
  font-family: 'YouSheBiaoTiHei';
}
