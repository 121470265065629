.col {
  &.checked {
    background: var(--mx-primary-color);
    color: var(--mx-btn-primary-color);
  }
  &.disabled {
    color: var(--mx-text-disabled-color);
    cursor: not-allowed;
    pointer-events: none;
  }
}
