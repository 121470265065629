.modal {
  .tips {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
  }
  .tip {
    font-size: 12px;
    color: #f00;
    margin: 10px 0;
  }
}
