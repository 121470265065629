.wrapper {
  padding: 20px;
}

.operationArea {
  margin-bottom: 10px;
}

.modal {
  :global {
    .ant-radio-wrapper {
      margin-bottom: 15px;
      min-width: 40px;
      padding-right: 20px;
    }
  }
}

.image {
  :global {
    .ant-image-img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}
