.recordList {
  background: var(--mx-module-bg-color);
  .recordListItem {
    &:hover {
      cursor: pointer;
      background: var(--mx-page-bg-color);
    }
  }
  .active {
    color: var(--mx-primary-color);
    background: var(--mx-page-bg-color);
    border-left: 1px solid var(--mx-primary-color);
  }
}
