.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
  .card_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top_card {
      height: 58px;
      width: 100%;
      padding: 0px 8px;
      background-image: url(../../../../imgs/right_elec_card.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      opacity: 0.9;
      background-color: linear-gradient(180deg, #041b33 0%, #093f72 100%);
      padding: 8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_title {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .spin_bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 43px;
          height: 43px;
          background-image: url(../../../../imgs/right_spin.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          animation: rotateImage 1s linear infinite;
        }
        .fixed_bg {
          position: absolute;
          top: 10px;
          left: 10px;
          // transform: translate(-50%, -50%); /* 水平和垂直居中 */
          width: 24px;
          height: 24px;
          background-image: url(../../../../imgs/fix_square.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
        .label {
          margin-left: 60px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .right_value {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .value {
          font-family: YouSheBiaoTiHei;
          font-size: 21px;
          color: #ffffff;
          background: linear-gradient(180deg, #00aeff 0%, #65ffe9 39%, #00d4ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .unit {
          margin: 0px 6px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .bottom_swipper {
      flex: 1;
      height: 0px;
      width: 100%;
      margin: 4px 0px;
      position: relative;
      display: flex;
      align-items: center;
      .swiper_prev_btn {
        left: -16px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }

      .swiper_next_btn {
        right: -15px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.swip_card_wrapper {
  height: 100%;
  background: linear-gradient(180deg, rgba(128, 195, 243, 0) 0%, rgba(74, 144, 226, 0.1) 100%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .img_sty {
    width: 54px;
    height: 55px;
    margin-top: 10px;
  }
  .label {
    font-weight: 500;
    color: #ffffff;
    width: 100%;
    padding: 0 4px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .value {
    font-family: YouSheBiaoTiHei;
    font-size: 15px;
    color: #ffffff;
    background: linear-gradient(180deg, #00aeff 0%, #65ffe9 39%, #00d4ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: -webkit-fill-available;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .light_line {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
  }
}

.date_picker {
  width: 120px;
  height: 24px;
  padding: 4px 12px;
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
