.subtitle {
  display: flex;
  align-items: center;
  position: relative;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 40px;
  font-style: normal;
  margin-bottom: 8px;
  flex: 1;
  img {
    margin-right: 4px;
    width: 16px;
    height: 18px;
  }
}
.rightContent {
  flex: 1;
  text-align: right;
}
