.leftSider {
  background-color: #232324;
  height: calc(100vh - 150px);
  width: 100%;
  .filterArea {
    padding: 20px 10px 0 10px;
    margin-bottom: 12px;
    .option {
      width: 86px;
      display: inline-block;
    }

    .selectBox {
      min-width: 80px;
      width: calc(100% - 86px);
      display: inline-block;
    }
  }

  .searchArea {
    padding: 20px 10px;
  }
  .splitLine {
    margin: 16px 10px;
    .divider {
      margin: 0;
    }
  }
  .rootNode {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-left: 12px;
    margin-bottom: 10px;
    height: 32px;
    cursor: pointer;
    & > img {
      width: 16px;
      height: 16px;
    }
    & > div {
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.75);
      line-height: 20px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }
  .highLight {
    color: #00adff;
  }
  .selected {
    background-color: #404040;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 2px;
      height: 32px;
      background-color: #00adff;
    }
  }

  .treeArea {
    overflow: hidden;
    height: calc(100% - 95px);
    margin-top: 20px;

    :global {
      .ant-tree-switcher-noop {
        width: 14px;
      }
      .ant-tree .ant-tree-treenode {
        padding: 0px;
        margin-bottom: 4px;
        line-height: 32px;
        &:hover {
          background-color: rgba(64, 64, 64);
        }
      }
      .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        flex: unset;
        width: 100%;
        overflow: hidden;
      }
      .ant-tree .ant-tree-node-content-wrapper:hover {
        background-color: rgb(255, 255, 255, 0);
      }
      .ant-tree-title {
        line-height: 32px;
      }
      .ant-tree-list-holder-inner {
        padding-bottom: 20px;
      }
      .ant-tree-switcher {
        color: var(--primary-color);
        line-height: 32px;
        margin-left: 8px;
      }
      .ant-tree .ant-tree-node-content-wrapper {
        padding-right: 24px;
      }
      .ant-tree-title {
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
      }

      .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
      }
      .ant-tree-treenode-selected {
        background-color: rgba(64, 64, 64, 1);
        position: relative;

        &:before {
          content: '';
          width: 2px;
          height: 100%;
          background-color: #00adff;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgba(64, 64, 64);
      }
    }

    .nodeTxt {
      white-space: nowrap;
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.tree_box {
  display: flex;
  overflow: auto;
  height: calc(100vh - 242px);
  :global {
    .ant-tree {
      flex: 1;
    }
  }
}

.treeNodeTitle {
  display: flex;
  justify-content: space-between;
  .treeNodeName {
  }
  .treeNodeRate {
    font-size: 12px;
  }
}

.pulsGreen {
  color: #59d744;
}

.minusRed {
  color: #ff4d4f;
}

.defaultWhite {
  color: rgba(255, 255, 255, 0.85);
}

.nullWhite {
  color: rgba(255, 255, 255, 0.65);
}
