.drag_box {
  padding: 10px 0;
  display: flex;
  height: 300px;
  width: 1000px;
  .drag_left,
  .drag_right {
    background: #232324;
    border-radius: 4px;
    border: 1px solid #434343;
  }
  .drag_left {
    width: 170px;
    margin-right: 10px;
    padding: 10px 0;
    overflow-y: auto;
    user-select: none;
    .listItem {
      padding: 5px 5px 5px 10px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: rgba(74, 144, 226, 0.2);
        color: var(--mx-primary-color);
      }
    }
  }
  .drag_right {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .drag_right_top {
    flex: 1;
    overflow: hidden;
  }
}
.isDragging {
  background-color: rgba(74, 144, 226, 0.2);
  color: var(--mx-primary-color);
}
.tag {
  padding: 5px 0;
}
.title_tips {
  font-size: 12px;
  padding: 0 5px;
  color: #767677;
}
.dateTable {
  width: 860px;
  margin-bottom: 10px;
}

.form_content {
  position: relative;
  .workDay {
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 20px;
  }
  .divider {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .slot {
    width: 100%;
    height: 25px;
  }
  .listDivider {
    width: 100%;
    height: 1px;
    margin-top: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .shiftType {
    position: relative;
    margin-top: 20px;
  }

  .deleteItems {
    position: absolute;
    right: 0;
    top: 26px;
    width: 60px;
    height: 32px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
  }
  .deleteItemsCreate {
    position: absolute;
    right: 0;
    top: 8px;
    width: 60px;
    height: 32px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
  }
  :global {
    .BorderButton_btn__b {
      width: 100%;
    }
  }
  .addRowBtn {
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 2px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #4a90e2;
  }
  .bottomSpace {
    margin-top: 20px;
    margin-left: 26px;
  }
}

.shiftTypeInput {
  width: 464px;
}
