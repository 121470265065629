.left_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top_bg {
    border: transparent;
    width: 100%;
    height: 42px;
    background-image: url(../../imgs/subtittlebg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .top_bg_text {
      display: inline-block;
      height: 100%;
      line-height: 42px;
      margin-left: 19px;
      font-family: YouSheBiaoTiHei;
      font-size: 21px;
      color: #ffffff;
    }
  }
  .content_wrapper {
    flex: 1;
    height: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #041b33 0%, #093f72 100%);
    border: 1px solid rgba(74, 144, 226, 0.7);
    border-top: transparent;
    border-radius: 0px 0px 4px 4px;
    opacity: 0.9;
    position: relative;
    .content_top_box {
      height: 0px;
      flex: 11;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .content_center_box {
      height: 0px;
      flex: 8;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .content_bottom_box {
      height: 0px;
      flex: 14;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .light_line {
      bottom: 0;
      width: 100%;
      height: 2px;
    }
  }
}
