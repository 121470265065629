// .img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

.img {
  width: 100%;
  height: 100%;

  .home {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
