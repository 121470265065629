$primaryColor: var(--primary-color);

.desc {
  padding: 10px 0 10px;
}

.addBtn {
  height: 28px;
  width: 100px;

  span {
    font-size: 12px;
  }

  svg {
    color: grey;
    font-size: 12px;
  }
}

.table {

}

.footer {
  position: sticky;
  left: 0;
  padding-left: 32px;
  bottom: 0;
  background-color: var(--component-background);
  height: 60px;
  width: 100%;
  margin-bottom: -20px;
}

.ouModal {
  .space {
    width: 800px;
  }

  .tips {
    .red {
      padding: 0 5px;
      color: red;
    }
  }
}
