.deviceBoard {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  .deviceItem {
    flex: 1;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px 16px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 28px;
    .name {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      line-height: 22px;
      margin-bottom: 4px;
    }
  }
}
