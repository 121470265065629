.distributionOfPCFCon {
  display: flex;
  flex-direction: column;
  flex: 5;
  height: 274px;
  :global {
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }
}
.distributionChart {
  flex: 1;
  height: 230px;
  width: 100%;
  overflow: hidden auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
