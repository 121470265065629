.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;
  .col {
    margin-bottom: 35px;
  }
}

.tableRow {
  height: 65px;
}

.row_form_item {
  :global {
    .ant-form-item-control {
      max-width: none !important;
    }
  }
}
