$primaryColor: var(--primary-color);

html.theme-dark {
  --mx-bg-image: url('~@maxtropy/components/es/components/Wrapper/DarkWrapper/bg.svg');
}

html.theme-light,
html.theme-yellow-light {
  --mx-light-bg-image: url('./assets/images/bg.svg');
}

.App {
  height: 100%;
}
.page_wrapper {
  padding: 20px !important;
}
.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 32px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}
.sticky-footer-c {
  position: sticky;
  left: 0;
  padding-left: 32px;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}

.sticky-footer-left {
  position: sticky;
  left: 0;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}

.modal-form-content {
  padding: 32px 72px 8px 72px !important;
}

.ant-form-item__longLabel {
  label {
    font-size: 12px;
  }
}

.ant-empty-image {
  height: 88px;
}

.swiper-button-prev {
  position: absolute;
  left: 0px;
  top: 48px;
  z-index: 99;
  cursor: pointer;
  svg {
    color: rgba(255, 255, 255, 0.75);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  background-color: transparent;
  -webkit-text-fill-color: var(--mx-text-base-color);
  transition: background-color 5000s ease-in-out 0s !important;
}

.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 48px;
  z-index: 99;
  cursor: pointer;
  svg {
    color: rgba(255, 255, 255, 0.75);
  }
}

::-webkit-scrollbar {
  border-radius: 0;
  background: var(--mx-scrollbar-bg);
  width: 8px;
  height: 8px;
}

//滚动条上滑块
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-bg);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: var(--mx-scrollbar-thumb-hover-bg);
}

.mb-8 {
  margin-bottom: 8px !important;
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.tableScroll {
  .ant-table-body {
    scrollbar-width: thin;
    scrollbar-color: var(--mx-scrollbar-thumb-bg) var(--mx-scrollbar-bg);
  }
}
