.topContent {
  padding: 6px 28px 0 28px;
  .contentHeader {
    color: var(--mx-text-secondary-color);
    line-height: var(--mx-line-height-normal);
    & > span {
      color: var(--mx-text-base-color);
    }
  }
  .tipRow {
    color: var(--mx-text-secondary-color);
    margin-top: 8px;
  }
}
.checkRow {
  margin-top: 16px;
  padding: 0 28px;
  display: flex;
  justify-content: flex-start;
  gap: 13px;
  .selectedTip {
    color: var(--mx-text-secondary-color);
    & > span {
      color: #e64242;
    }
  }
}
.bottomContent {
  padding: 0 28px;
  height: 300px;
  overflow-y: auto;
}
