.indicatorValue_card_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .itemcard_wrapper {
    width: 182px;
    height: 64px;
    background: rgba(var(--mx-main-color), 0.05);
    border-radius: 2px;
    padding: 8px 16px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    .itemcard_wrapper_top_label {
      color: var(--mx-text-secondary-color);
      line-height: 22px;
      width: 150px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .itemcard_wrapper_top_value {
      font-weight: 500;
      font-size: 16px;
      color: var(--mx-text-base-color);
      line-height: 24px;
    }
  }
}

.chart_box_inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
