.operationArea {
  margin-bottom: 10px;

  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      &::before {
        content: none;
      }
    }
  }
}

.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;
  .col {
    :global {
      .ant-form-item-label > label .ant-form-item-tooltip {
        color: var(--mx-warning-color);
      }
    }
  }
}

.detail {
  padding: 20px;
  :global {
    .ant-descriptions-row > th {
      padding-bottom: 5px;
    }

    .ant-descriptions-row > td {
      padding-bottom: 30px;
    }
  }
}

.tips {
  display: flex;
  justify-content: space-between;

  .red {
    padding: 0 5px;
    color: red;
  }
}

.ruleGroup {
  padding: 0 5px;
  color: red;
}
