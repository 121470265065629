.wrapperContent {
  padding: 0;
  position: relative;
}

.gasHot {
  position: absolute;
  top: 8vh;
  left: 32vw;
  width: 260px;
  height: 240px;
  background-color: transparent;
  z-index: 20;
  cursor: pointer;
}

.deviceHot {
  position: absolute;
  top: 55vh;
  left: 54vw;
  width: 240px;
  height: 220px;
  z-index: 20;
  cursor: pointer;
}

.powerHot {
  position: absolute;
  top: 6vh;
  right: 15vw;
  width: 260px;
  height: 240px;
  background-color: transparent;
  z-index: 20;
  cursor: pointer;
}

.energyHot {
  position: absolute;
  top: 40vh;
  right: 20vw;
  width: 240px;
  height: 220px;
  background-color: transparent;
  z-index: 20;
  cursor: pointer;
}
