.tree_box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .treeStyle {
    :global {
      .ant-tree-switcher {
        display: none;
      }

      .ant-tree-node-content-wrapper {
        padding-left: 10px;
        height: 32px;
        font-size: 14px;
        line-height: 32px;
        // color: rgba(var(--), 0.75);

        &:hover {
          background-color: transparent;
        }
      }

      .ant-tree-node-selected {
        position: relative;
        overflow: hidden;
        background-color: var(--mx-tree-selected-bg-color);

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: var(--mx-primary-color);
        }
      }
    }
  }
}
