.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  :global {
    .ant-tree-show-line .ant-tree-switcher {
      background-color: unset;
    }
  }
  .col {
    margin-bottom: 30px;
  }
  .titleBefore {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: rgba(230, 66, 66, 1);
    margin-right: 2px;
    border-radius: 50%;
    margin-top: 4px;
  }
  .section {
    padding: 16px;
    background: rgba(var(--mx-main-color), 0.05);
    height: 100%;
    margin-top: 16px;
    .sectionTop {
      display: flex;
      justify-content: space-between;
    }
    .searchInput {
      max-width: 374px;
      display: block;
    }
  }
  .siteTreeSearchValue {
    color: rgba(230, 66, 66, 1);
  }
  .energyUnitTree {
    :global {
      .ant-tree-treenode:last-child {
        margin-bottom: 20px !important;
      }
      .ant-tree-switcher {
        line-height: 30px;
      }
    }
  }

  .middleSpace {
    position: absolute;
    right: 0;
    top: -34px;
  }

  :global {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}
