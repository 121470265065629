.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;

  :global {
    .mx-rc-form.mx-rc-form-standard.ant-form-horizontal .mx-rc-form-item.ant-form-item {
      .ant-form-item-label {
        max-width: 140px;
      }
    }
  }
}
