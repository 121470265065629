.comparedModal {
  display: flex;
  min-height: 530px !important;

  .leftCol {
    width: 260px;
    padding-right: 24px;
    position: relative;
    :global {
      .ant-form-item.mx-rc-form-item {
        margin-bottom: 16px;
      }
    }

    .compareSection {
      padding: 20px 0 32px;
    }

    .deviceList {
      margin: -8px 0 8px;
      .deviceItem {
        margin-bottom: 8px;
        line-height: 22px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.65);
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        padding: 0 8px;
        display: flex;
        justify-content: space-between;
        .label {
          margin-right: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .value {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #fff;
        }
      }
    }
    .compareTime {
      margin-bottom: 0 !important;
      :global {
        .ant-form-item-row {
          flex-direction: row;
          .ant-form-item-label {
            padding: 0;
            line-height: 32px;
            > label::after {
              visibility: visible;
            }
          }
          .ant-form-item-control {
            flex: 1;
          }
        }
      }
    }

    .btnArea {
      width: 100%;
      justify-content: center;
      position: absolute;
      bottom: 0;
    }
  }
  .rightCol {
    flex: 1;
    padding-left: 24px;
    border-left: 1px solid #303030;

    .dateSwitch {
      margin-bottom: 16px;
    }
  }
}
