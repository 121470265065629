.rateCard,
.card {
  height: 76px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 12px 0 12px 16px;
}

.rateCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rateStyle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .dividerStyles {
    padding-right: 10px;
    border-color: rgba(255, 255, 255, 0.15);
    height: 54px;
  }
}

.pulsGreen {
  color: #59d744;
}

.pulsGreenBack {
  background: #59d744;
}

.minusRed {
  color: #ff4d4f;
}

.minusRedBack {
  background: #ff4d4f;
}

.defaultWhite {
  color: rgba(255, 255, 255, 0.85);
}

.xxBlue {
  color: var(--mx-primary-color);
}

.xxBlueBack {
  background: var(--mx-primary-color);
}

.greenBack {
  background: linear-gradient(270deg, rgba(146, 237, 121, 0.25) 0%, rgba(89, 215, 68, 0.25) 100%);
}
.redBack {
  background: linear-gradient(270deg, rgba(255, 132, 134, 0.25) 0%, rgba(255, 77, 79, 0.25) 100%);
}
.blueBack {
  background: linear-gradient(270deg, rgba(129, 195, 243, 0.25) 0%, rgba(74, 144, 226, 0.25) 100%);
}

.nullWhite {
  color: rgba(255, 255, 255, 0.65);
}

.titleStyle {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 20px;
  margin-bottom: 4px;
}

.numStyle {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px;
}

.borderSameStyle {
  width: 10px;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

.otherBorderSameStyle {
  width: 2px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.leftTop {
  position: absolute;
  top: 0;
  left: 0;
}
.rightTop {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(90deg);
}
.rightBottom {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.leftBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(270deg);
}

.lowerStyle {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  position: absolute;
  right: 16px;
  top: 29px;
}
