.tag_box {
  display: flex;
  height: 32px;
  font-size: 14px;
  color: rgba(255,255,255,0.8);
  padding: 0 5px;
  border-radius: 2px;
  border: 1px solid transparent;
  align-items: center;
  .text {
    flex: 1;
    text-align: center;
  }
  .icon {
    transition: all 0.5s;
    width: 20px;
    padding-left: 5px;
    text-align: center;
    cursor: pointer;
    &:hover {
      font-size: 16px;
    }
  }
}