.siteTreeSearchValue {
  color: #f50;
}

.treeBackground {
  // height: 265px;
  border: 1px solid var(--mx-base-border-color);
  border-radius: 2px;
  padding: 24px 27px;
}

.tag {
  line-height: 18px;
  padding: 0px 8px;
  background: rgba(250, 173, 20, 0.2);
  font-size: 12px;
  border-radius: 2px;
  color: #faad14;
  display: inline-block;
  margin-left: 4px;
}
