.dateSwitch {
  // display: flex;
  align-items: center;
  float: left;
  overflow: hidden;
  margin-bottom: 18px;
  // justify-content: space-between;
  display: flex;
  width: 100%;
  .datePickerArea {
    margin-left: 8px;
    display: inline-block;
  }
  .switchBtn {
    // max-width: 262px;
   
    display: inline-block;
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

// @media only screen and(max-width:1300px) {
//   .hidden {
//     display: none !important;
//   }
// }
