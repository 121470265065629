.list {
  width: 100%;
  height: 100%;

  .filterWrapper {
    width: 100%;
    height: 80px;
    padding: 24px 20px;
    margin-bottom: 10px;
    background-color: var(--mx-module-bg-color);

    :global {
      .ant-row {
        margin-top: 0 !important;
      }

      .ant-form-item {
        margin-top: 0 !important;
      }
    }
  }

  .tableWrapper {
    width: 100%;
    height: calc(100% - 80px - 10px);
    padding: 20px 18px;
    background-color: var(--mx-module-bg-color);
  }
}
