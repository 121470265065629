.top {
  padding: 25px 20px 20px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  margin-top: 20px;
  .des {
    // width: 800px;
    background-color: #262d36;
    padding: 10px;
    margin-bottom: 10px;
    :global {
      .ant-descriptions-item-content {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.extra {
  font-size: 12px;
  margin-top: 5px;
  width: 800px;
  p {
    display: inline-block;
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  background: rgba(var(--base-text-color), 0.1);
  width: calc(100% - 268px);
  margin-left: -30px;
  padding: 14px 60px;
  text-align: right;
  z-index: 999;
}

.red {
  color: #f5222d;
}
.area {
  padding: 20px 0;
  .line {
    padding: 5px 0;
  }
}
