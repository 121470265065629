.operationArea {
  margin-bottom: 10px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
.top_label {
  color: var(--mx-text-secondary-color);
  padding-right: 4px;
}
.thirdUl {
  list-style-type: '-';
  padding-inline-start: 5px;
}

.helperClass {
  z-index: 10000 !important;
  color: #1890ff;
}

.liStyle {
  list-style: none;
  border: 1px solid #ffffff20;
  font-size: 14px;
  padding: 10px;
}
