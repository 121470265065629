.content {
  position: relative;
  .Title {
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 16px;
    position: absolute;
    top: -46px;
    left: 0;
    z-index: 100;
  }
}

.activityTitle {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 500;
  color: var(--mx-text-base-color);
  line-height: 24px;
  margin-bottom: 16px;
}
.blueSpan {
  color: #00adff;
}
.activityDescription {
  margin-bottom: 8px;
}
