.infoItem {
  flex: 1;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 8px;
  background: linear-gradient(
    90deg,
    rgba(82, 231, 255, 0.1) 0%,
    rgba(82, 231, 255, 0.15) 50%,
    rgba(82, 231, 255, 0.1) 100%
  );
  border-radius: 2px;
  border: 1px solid rgba(82, 231, 255, 0.1);

  img {
    width: 16px;
    height: 16px;
  }

  .detail {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 4px;
    .desc {
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
    }

    .count {
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.analysisOfPCFCon {
  display: flex;
  flex-direction: column;

  .analysisInfo {
    display: flex;
    height: 40px;
    flex: 1;
    gap: 16px;
  }
}

.analysisChartInfo {
  position: relative;
  height: 232px;
  padding-top: 16px;
  width: 100%;
  display: flex;
  .analysisChartTitle {
    position: absolute;
  }
  .analysisChart {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    :global {
      .ant-spin-nested-loading,
      .ant-spin-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: center;
      }
    }
  }
}

.flexBox {
  display: flex;
  flex-direction: column;
  flex: 10;
  height: 500px;

  .analysisChartInfo {
    display: flex;
    flex: 5 5;
  }
}
