.wrapperStyles {
  padding: 20px;
  min-height: calc(100vh - 130px) !important;
}
.search_box {
  padding-top: 5px;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.time_box {
  display: flex;
  justify-content: space-between;
}
.tips_box {
  margin: 18px 0;

  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.text3 {
  color: #ff4d4f;
}
.text2 {
  color: #dca306;
}
.text {
  color: #00adff;
  padding: 0 4px;
}
.empty_box {
  margin-top: 10%;
}
.banner_box {
  height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.btn_box {
  padding: 15px 15px 0;
  .btn_item_icon {
    width: 24px;
  }
  .btn_item {
    // width: 124px;
    cursor: pointer;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    &.selected {
      color: var(--mx-primary-color);
      border: 1px solid var(--mx-primary-color);
      background: rgba(74, 144, 226, 0.2);
    }
  }
}
