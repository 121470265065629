.wrapper {
  padding: 20px;
  min-height: calc(100vh - 220px) !important;
}

.pulsGreen {
  color: #59d744;
}

.minusRed {
  color: #ff4d4f;
}

.defaultWhite {
  color: rgba(255, 255, 255, 0.85);
}

.nullWhite {
  color: rgba(255, 255, 255, 0.65);
}
