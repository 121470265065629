:global {
  html.theme-dark {
    --mx-uet-divider-color: rgba(255, 255, 255, 0.12);
  }
}

.DividerStyle {
  :global {
    margin: 10px 0;
    border-block-start-color: var(--mx-uet-divider-color, #dee2e9);
  }
}

.FormCardItemStyle {
  :global {
    .ant-form-item {
      margin: 4px 0;
      margin-bottom: 4px !important;
    }
    .ant-form-item .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.65) !important; // 全主题都用这个颜色，因为外层卡片颜色不变
    }
  }
}

.TagStyle {
  display: flex;
  align-items: center;
  :global {
    .ant-tag {
      background-color: #fff;
      border-color: #fff;
    }
  }
  .nameSty {
    font-size: 18px;
    font-weight: 700;
    margin-right: 12px;
    color: rgba(255, 255, 255, 0.85) !important;
    // color: var(--mx-text-base-color);
  }
}

.relatedPartyMaint {
  :global {
    .ant-table-tbody > tr > td {
      padding: 0px !important;
      line-height: 40px;
    }
  }
}

.card_wrapper {
  margin-bottom: 16px;
  border: 1px solid;
  border-color: var(--mx-base-border-color);
  background: transparent;
  .formCardItemStyle {
    :global {
      .ant-form-item {
        margin: 4px 0;
        margin-bottom: 4px !important;
      }
    }
  }
}
