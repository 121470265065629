.wrapperStyles {
  // height: calc(100vh - 280px);
  height: 100%;
  display: flex;
  flex-direction: column;
  :global {
    .x6-graph-svg-viewport {
      body {
        min-width: auto;
      }
    }
  }
}

.header {
  margin: 0 20px 20px;
  height: 32px;
  display: flex;
  align-items: center;

  .headerTxt {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 16px;
      margin-right: 8px;
      background: #1890ff;
    }
  }
}

.container {
  flex: 1;
  background: var(--mx-bg-image, var(--mx-light-bg-image, #fff));
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commonNode {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.nodeContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  .nodeFlexTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    .titleName {
      flex: 1;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .nodeTitle {
    display: flex;
    align-items: center;
    // justify-content: start;
    width: 100%;
    height: 48px;
    font-size: 16px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
    .titleName {
      flex: 1;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .nodeIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    margin-right: 4px;
  }

  .nodeExpandIcon {
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
  }

  .nodeTitleLine {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }

  .expandContent {
    flex: 1;
    overflow: auto;
    padding: 0 6px;
    margin-bottom: 12px;

    .contentItem {
      height: 24px;
      margin-top: 4px;
      display: flex;
      align-items: center;

      .itemRect {
        height: 4px;
        width: 4px;
        margin: 0 8px;
        background: white;
      }

      > span {
        flex: 1;
        margin-left: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.layOut {
  background: var(--component-background);

  .leftSider {
    margin-right: 10px;
    // border: 1px #2e2e2f solid;
    background: var(--component-background);

    &.bordered {
      border: 1px #2e2e2f solid;
    }
    .leftContent {
      background: var(--component-background);
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 12px 20px 12px;
      width: 430px;
      border: 1px #2e2e2f solid;
      overflow-y: scroll;
    }
  }
}

.toggleBtn {
  cursor: pointer;
  position: absolute;
  z-index: 11;
  top: 48%;
  margin-top: -80px;
  width: 20px;
  height: 66px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
}

.toolitipWrapper {
  width: 200px;
  max-height: 219px;
  overflow: auto;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  .toolitipTime {
    padding-left: 12px;
    padding-top: 8px;
    font-size: 12px;

    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 20px;
    width: 100%;
    margin-bottom: 8px;
  }
  .name {
    margin-left: 12px;
    display: flex;
    .productionBaseBefore {
      width: 8px;
      height: 8px;
      background: rgba(119, 202, 78, 1);
      margin-right: 4px;
      border-radius: 50%;
      margin-top: 6px;
    }
    .processBefore {
      width: 8px;
      height: 8px;
      background: #854eca;
      margin-right: 4px;
      border-radius: 50%;
      margin-top: 6px;
    }
    .workCenterBefore {
      width: 8px;
      height: 8px;
      background: var(--mx-primary-color);
      margin-right: 4px;
      border-radius: 50%;
      margin-top: 6px;
    }
    .workStationBefore {
      width: 8px;
      height: 8px;
      background: #c09234;
      margin-right: 4px;
      border-radius: 50%;
      margin-top: 6px;
    }
    .unitNodeBefore {
      width: 8px;
      height: 8px;
      background: #54a32d;
      margin-right: 4px;
      border-radius: 50%;
      margin-top: 6px;
    }
    .nameDetail {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
  }
  .energyDetail {
    padding-left: 24px;
    padding-top: 5px;
    padding-right: 12px;
    font-size: 12px;
    width: 100%;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
    .totalConsumption {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
    }
  }
}

.area_outer {
  min-height: 730px;
  height: 920px;
  display: flex;
  flex-direction: column;
  // .area_top {
  // }
  .area_bottom {
    flex: 1;
    overflow: hidden;
  }
  .layOut {
    height: 100%;
    display: flex;
    .layout_right {
      flex: 1;
      height: 100%;
    }
  }
}
.wrapperStylesFull {
  height: 100%;
  display: flex;
  flex-direction: column;
  :global {
    .x6-graph-svg-viewport {
      body {
        min-width: auto;
      }
    }
  }
}
.wrapperRef {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.area_outerFull {
  min-height: 730px;
  height: 970px;
  display: flex;
  flex-direction: column;

  // .area_top {
  // }
  .area_bottom {
    flex: 1;
    overflow: hidden;
  }
  .layOut {
    height: 100%;
    display: flex;
    .layout_right {
      flex: 1;
      height: 100%;
    }
  }
}
