.energyStatisticsSwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c202380;
  padding: 20px 0 21px 0;
  // height: 100%;
}

.cardStyles {
  // border: 1px solid red ;
  height: 158px;
  width: 120px;
  margin: 0 auto;
  text-align: center;
  .singleCard {
    height: 128px;
    background-image: url(../../assets/platformDefault.png);
    background-size: contain;
    background-repeat: no-repeat;
    &:hover {
      background-image: url(../../assets/platformHover.png);
      scale: 1.1;
      cursor: pointer;
    }
    :first-child {
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      line-height: 28px;
      padding-top: 20px;
      margin: 0;
    }
    :last-child {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
      padding-top: 8px;
      margin: 0;
    }
  }
}
