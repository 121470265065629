.rightPlanContent {
  position: relative;

  .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .formStyle {
    background: #2d2d2d;
    margin-bottom: 8px;
    padding: 16px 16px 0 16px;
    position: relative;

    :global {
      .ant-form-item {
        margin-bottom: 16px;
      }
    }

    .formBtn {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
  }

  .tabAndCheckBox {
    position: relative;

    .tabStyle {
      width: 90%;
    }

    .checkBoxStyle {
      position: absolute;
      right: -5px;
      bottom: 28px;
    }

    .borderStyle {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      bottom: 15px;
    }
  }
}

.planDetailStyle {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
}
