.wrapperStyles {
  // height: calc(100vh - 130px) !important;
  padding: 0;
  background-color: transparent;
}

.layout {
  min-height: calc(100vh - 130px);
  background-color: var(--mx-page-bg-color);
}

.content {
  background: var(--mx-module-bg-color);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
}

.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.toggleBtn {
  cursor: pointer;
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -80px;
  width: 20px;
  height: 66px;
  background: var(--mx-disabled-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mx-text-base-color);

  &:hover {
    background: rgba(var(--mx-main-color), 0.4);
  }
}
