.cusCard {
  :global {
    .ant-card-actions {
      background: #00000065 65% !important;
    }
    .ant-card-actions > li {
      margin: 4px 0;
    }
    .ant-card-body {
      height: 120px;
      background-color: var(--mx-card-dark-bg-color);
    }
    .ant-card-meta-title {
      // width: 250px;
      // height: 50px;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap;

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; //这里写的数字是几就是超过几行剩余的显示省略号
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &:hover {
    box-shadow: 0px 0px 5px var(--mx-primary-color);
  }
}

.content {
  // padding: 0px !important;
  min-height: calc(100vh - 110px) !important;
}
