.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;

  .searchArea {
    padding: 0 10px;
    margin: 12px 0;
  }

  .treeBox {
    flex: 1;
    overflow-x: hidden;
  }
}

.iconStyle {
  scale: 0.67;
  width: 28px;
  height: 28px;
  padding-bottom: 4px;
  padding-right: 4px;
}
