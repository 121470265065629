.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--mx-primary-color);
}

.dividerSty {
  :global {
    .ant-divider-horizontal.ant-divider-with-text {
      color: #1e5a7c;
    }
    .ant-divider {
      position: relative;

      .ant-divider-inner-text {
        padding: 0 14px;
        color: var(--mx-primary-color);
        font-size: 14px;
      }
    }
  }
}

.compareSection {
  padding: 20px 0;
}
