.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;
}

.modal {
  .tips {
    display: flex;
    justify-content: space-between;
    .red {
      padding: 0 5px;
      color: red;
    }
  }
}
