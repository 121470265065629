.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .searchArea {
    padding: 0 10px;
    margin: 14px 0;
  }
  .treeBox {
    flex: 1;
    overflow: hidden;
  }
}
