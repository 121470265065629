.content {
  padding: 0px !important;
  min-height: calc(100vh - 130px) !important;
}

.tabStyles {
  :global {
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0px;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}
