.addRowBtn {
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
  height: 32px;

  :global {
    .ant-btn {
      width: 100%;
    }
  }
}

.content {
  :global {
    .ant-select.ant-select-in-form-item {
      width: 100%;
    }
    .ant-form-item-label > label {
      color: var(--mx-text-secondary-color);
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 12px 10px;
    }
  }
}
