.btn {
  width: 76px;
  height: 32px;
  background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  .bottomLine {
    position: absolute;
    bottom: 0;
    width: 76px;
    height: 1px;
    background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
  }
}
