.btnStyle,
.formStyle,
.pStyle,
.infoStyle,
.hintP {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formItemStyle {
  width: 550px;
}

.pStyle {
  height: 24px;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  line-height: 24px;
  margin-top: 147px;
  margin-bottom: 70px;
}

.infoStyle {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: #e02020;
  line-height: 20px;
  padding-bottom: 36px;
  padding-top: 12px;
}

.hintP {
  margin-top: 50px;
  opacity: 90%;
}

.wrapper {
  min-height: calc(100vh - 130px) !important;
}
