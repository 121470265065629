
.title {
  margin-top: 20px;
  font-weight: 600;
  padding-left: 10px;
  margin-bottom: 10px;
}

.content {
  margin-top: 10px;

  .tips {
    padding-left: 10px;
    padding-bottom: 10px;

    .num {
      margin: 0 3px;
      color: red;
    }
  }
}

.row {
  width: 700px;
  padding: 0 20px;
}

.margin {
  margin-left: -10px;
  margin-right: -10px;
}

.modal {
  padding: 10px 0;
}
