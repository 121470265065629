.wrapper {
  min-height: calc(100vh - 128px) !important;
  padding: 20px;
}

.tips {
  font-size: 12px;
  padding: 2px 0;
  margin-top: 14px;
}
