%padding {
  padding: 0 20px;
}
.content {
  padding: 0;
}
.meterRecordModalTable {
  :global {
    td {
      padding: 12px 0px;
    }
  }
}

.spanStyle {
  padding-left: 8px;
}

.filterWrapper {
  @extend %padding;
  padding: 20px 10px 0px 10px;
}

.spaceStyle {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: var(--mx-bg-image);
    opacity: 0.6;
  }

  height: 10px;
  background-color: var(--mx-page-bg-color);
}

.contentTable {
  margin-top: 10px;
  padding: 10px;
  min-height: calc(100vh);
}
.content {
  padding: 0px;
  min-height: calc(100vh - 130px);
}
.button {
  margin-left: 10px;
}
.tabs {
  :global {
    .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
      bottom: inherit;
      top: 0;
    }
    .ant-tabs-nav {
      &::before {
        content: none;
      }
      margin-bottom: 0px;
      padding: 0 20px;
    }
  }
}

.tabsStyle {
  :global {
    .ant-tabs-nav {
      margin-bottom: 10px;
      margin-left: 0px;
      height: 32px;
    }

    .ant-tabs-nav-wrap {
      flex: none;
      border-radius: 4px;
    }

    .ant-tabs-ink-bar {
      background: transparent;
    }

    .ant-tabs-tab {
      padding: 0 20px;
      display: flex;
      justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    .ant-tabs-tab-active {
      background: linear-gradient(270deg, #2cc4f9 0%, #0277bd 100%);

      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
}

.custom-pagination .ant-pagination-prev a,
.custom-pagination .ant-pagination-next a {
  color: black !important;
}

.iptStyle {
  width: 280px;
  height: 32px;
}
.suffixIcon {
  height: 20px;
  font-size: 14px;

  font-weight: 500;
  color: rgba(255, 255, 255, 0.25);
  line-height: 20px;
  border: none;
}
