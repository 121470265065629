.center_wrapper {
  height: 148px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  .center_wrapper_top {
    margin-bottom: 16px;
  }
  .center_content_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    .itemcard_wrapper {
      width: calc((100% - 16px) / 3);
      height: 68px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 2px;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      .itemcard_wrapper_top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .itemcard_wrapper_top_label {
          color: rgba(255, 255, 255, 0.65);
          line-height: 22px;
          width: 150px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 6px;
        }
        .itemcard_wrapper_top_value {
          font-weight: 500;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 22px;
        }
      }
      .itemcard_wrapper_bottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .itemcard_wrapper_bottom_label {
          color: rgba(255, 255, 255, 0.65);
          line-height: 22px;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
