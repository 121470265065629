.wrapper {
  max-height: 800px;
  overflow-y: auto;

  .itemWrapper {
    margin-bottom: 5px;
  }

  .item {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid var(--mx-base-border-color);
    position: relative;
    .drag {
      position: absolute;
      top: 12px;
      right: 20px;
      opacity: 0;
      font-size: 16px;
    }
    &:hover,
    &:active {
      background: var(--mx-base-bg-color);
      .drag {
        opacity: 1;
      }
    }

    border-radius: 5px;

    .icon {
      margin-right: 8px;
      font-size: 16px;
    }
  }
}
