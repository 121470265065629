.input_box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  input {
    outline: none;
    border: none;
  }
}
.flex_outer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  .split_line {
    padding: 0 2px;
  }
}
