.operationArea {
  margin-bottom: 10px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
.applyLog {
  max-height: 500px;
  overflow-y: auto;
}

.danger {
  color: #f00;
}
