.content {
  padding: 20px;
  :global {
    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .stepsStyle {
    width: 100%;
    height: 32px;
    display: flex;
    padding: 24px 174px 37px 174px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  .firstStep {
    .searchInput {
      width: 360px;
      margin-right: 10px;
      margin-bottom: 16px;
    }
  }
  .secondStep {
    padding: 0 32px;
    .bomContent {
      height: 100%;
      width: 100%;
      .bomTitle {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
  .thirdStep {
    padding: 17px 32px 0px 32px;
    :global {
      .ant-select.ant-select-in-form-item {
        width: 308px;
      }
      .ant-input {
        height: 80px;
        width: 685px;
      }
    }
  }
}

.downLoadSpace {
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.45);
  line-height: 32px;
  .downLoadIcon {
    color: var(--mx-warning-color);
    font-size: 14px;
  }
  .tips {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.45);
    line-height: 32px;
    margin-bottom: 74px;
  }
  .errorMsg {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #e64242;
    line-height: 22px;
    margin-bottom: 8px;
  }
}

.emptyDetail {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.45);
  line-height: 22px;
}
.downLoadDiv {
  color: 'var(--mx-primary-color)';
}
