.wrapper {
  padding: 20px;
  min-height: calc(100vh - 115px) !important;
  .col {
    //margin-bottom: 30px;
  }
  .form_content {
    :global {
      .ant-form-item-control-input-content {
        display: flex;
      }
    }
  }
}
