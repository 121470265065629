$component-background: var(--component-background);
.wrapper {
  padding: 20px;
}

.container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  // overflow: hidden;
  .breadcrumb {
    background-color: $component-background;
    padding: 7px 30px;
  }
}

.templateWrapper {
  background-color: $component-background;
  padding: 20px;
  margin: 10px 10px 0 10px;
  .version {
    width: 66px;
    height: 24px;
    background: var(--border-color-split);
    border-radius: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }
  .tip {
    width: 300px;
    border: 1px dashed #a3a3a3;
    border-radius: 2px;
  }
}

.body {
  flex: 1;
  // margin: 0 10px 10px 10px;
  background-color: $component-background;
  padding-bottom: 60px;
}

.list {
  // padding: 0 20px;
  & > div:last-child {
    border-bottom: none !important;
  }
}

.popConfirm {
  .title {
    font-weight: bold;
  }
  .red {
    color: red;
  }
  .gray {
    color: #999999;
  }
}

.stickyFooter {
  position: sticky;
  left: 0;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}
