.deviceModal {
  min-height: 585px;

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }

  .table_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;

    .tips {
      padding-bottom: 5px;

      .red {
        color: #d62500;
        padding: 0 5px;
        font-weight: 500;
      }

      svg {
        margin-left: 15px;
        color: #fee17a;
      }

      .grey {
        margin-left: 5px;
        color: rgba(var(--base-text-color),0.35);
        font-size: 12px;
      }
    }
  }

  .action {
    .text {
      color: rgba(255,255,255, 0.65);
    }

    .href {
      padding: 0 5px;
      cursor: pointer;
    }
  }
}

.popConfirm {
  font-size: 14px;
  .title {
    font-weight: bold;
  }
  .red {
    color: red;
  }
  .gray {
    color: #999999;
  }
}
