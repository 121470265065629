.wrapper {
  min-height: calc(100vh - 115px) !important;

  .col {
    margin-bottom: 30px;
  }
  // 本来是做数字输入框的后置单位
  // .colUnit {
  //   margin-bottom: 30px;
  //   label::before {
  //     display: inline-block;
  //     margin-right: 4px;
  //     color: #ff4d4f;
  //     font-size: 14px;
  //     font-family: SimSun, sans-serif;
  //     line-height: 1;
  //     content: '*';
  //   }
  // }
}
