%padding {
  padding: 0 20px;
}

.meterRecordModalTable {
  :global {
    td {
      padding: 12px 0px !important;
    }
  }
}

.spanStyle {
  padding-left: 8px;
}

.filterWrapper {
  @extend %padding;
}

.spaceStyle {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: var(--mx-bg-image);
    opacity: 0.6;
  }

  height: 10px;
  background-color: var(--mx-page-bg-color);
}

.content {
  padding: 20px;

  .operationArea {
    margin-bottom: 10px;
  }
}

.tabsStyle {
  :global {
    .ant-tabs-nav {
      margin-bottom: 10px !important;
      padding: 0 !important;
      height: 32px;
    }

    .ant-tabs-nav-wrap {
      flex: none !important;
      margin-right: 8px;
      border: 1px solid var(--mx-base-border-color);
      border-radius: 4px;
    }

    .ant-tabs-ink-bar {
      background: transparent;
    }

    .ant-tabs-tab {
      padding: 0 20px;
      display: flex;
      justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
      border-left: 1px solid var(--mx-base-border-color);
    }

    .ant-tabs-tab-active {
      background: var(--mx-btn-primary-bg-color) !important;

      .ant-tabs-tab-btn {
        color: var(--mx-btn-primary-color) !important;
      }
    }
  }
}

//   导入抄表弹窗样式
.popUp_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.193);
  z-index: 99;
  user-select: none;
  display: none;

  .popUp {
    width: 900px;
    height: 500px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: black;
    .popUp_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid black;
      height: 100px;
      span:nth-child(1) {
        font-weight: bold;
        font-size: 30px;
      }
    }

    .popUp_con {
      div:nth-child(1) {
        button {
          width: 200px;
          height: 50px;
          background-color: rgb(22, 155, 213);
          border-radius: 8px;
          border: none;
          color: white;
        }
      }

      div:nth-child(2) {
        margin-top: 50px;

        button {
          width: 200px;
          height: 50px;
          border-radius: 8px;
          background: white;
          color: black;
          border: 1px solid black;
        }

        div {
          margin: 0;
          color: black;
        }
      }
    }
  }
}

// 导入日志样式
.toChannel_box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.193);
  z-index: 99;
  user-select: none;
  display: none;
  color: black;

  .toChannel {
    width: 900px;
    height: 500px;
    background: gray;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid black;
      height: 100px;

      span:nth-child(1) {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
}

.custom-pagination .ant-pagination-prev a,
.custom-pagination .ant-pagination-next a {
  color: black !important;
}
