.border {
  width: 420px;
  height: 300px;
  background: rgba(18, 18, 18, 0.7);
  border: 1px solid;
  border-image: linear-gradient(360deg, rgba(0, 173, 255, 0), rgba(0, 173, 255, 1), rgba(0, 173, 255, 0.01)) 1 1;
  p {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
}

:global {
  .ant-carousel .slick-dots {
    bottom: 0;
  }
  .ant-carousel .slick-dots li.slick-active,
  .ant-carousel .slick-dots li {
    width: 0;
  }

  .ant-carousel .slick-dots li.slick-active button,
  .ant-carousel .slick-dots li button {
    opacity: 1;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: var(--mx-primary-color);
  }

  .ant-carousel .slick-dots li button {
    background: rgba(255, 255, 255, 0.3);
  }
}
