.wrapperStyles {
  min-height: calc(100vh - 128px) !important;
}

.form_box {
  :global {
    .mx-rc-form .mx-rc-form-item.ant-form-item {
      margin-bottom: 15px;
    }
  }
}
.form_content {
  width: 80%;
  margin: 20px auto 0px;
}
.row_line {
  display: flex;
  justify-content: flex-start;
  // margin-bottom: 10px;
}
.flex {
  display: flex;
  align-items: center;
}
