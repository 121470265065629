.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  // .col {
  //   margin-bottom: 30px;
  // }
  // 本来是做数字输入框的后置单位
  // .colUnit {
  //   margin-bottom: 30px;
  //   label::before {
  //     display: inline-block;
  //     margin-right: 4px;
  //     color: #ff4d4f;
  //     font-size: 14px;
  //     font-family: SimSun, sans-serif;
  //     line-height: 1;
  //     content: '*';
  //   }
  // }
}
.form_col_gap {
  :global {
    .mx-rc-form > .ant-row {
      column-gap: 10px !important;
    }
    .mx-rc-form > .ant-row > .ant-col-12:nth-child(odd) {
      margin-right: 0px;
    }
  }
}
// .content {
//   margin-left: 28px;
//   p {
//     margin-bottom: 25px;
//   }
//   .btn {
//     margin-bottom: 10px;
//   }
// }

.btnMouseEnter {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  // background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnMouseLeave {
  display: none;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.squareBadge {
  :global {
    .ant-badge-status-dot {
      border-radius: 0%;
      width: 3px;
      height: 12px;
    }
  }
}

.notesMonth {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.disabledDateStyle {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(119, 150, 186, 0.2);
}
