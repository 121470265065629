.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  .col {
    margin-bottom: 30px;
  }
  .formSelect {
    :global {
      .ant-select-focused.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(
          .ant-pagination-size-changer
        )
        .ant-select-selector,
      .ant-picker:not(.ant-picker-disabled).ant-picker-status-error.ant-picker-focused {
        box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
      }
    }
    :global {
      .ant-form-item-control {
        max-width: none !important;
      }
    }
  }
}
.reset_form_style {
  :global {
    .ant-form-item-control {
      max-width: none !important;
    }
  }
}
