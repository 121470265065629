.dateSwitch {
  margin-bottom: 10px;
  .datePickerArea {
    margin-left: 15px;
    display: inline-block;
  }
  .switchBtn {
    // max-width: 262px;
    display: inline-block;
  }
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;

  &:after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
