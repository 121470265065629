.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  .actionBtn {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.content {
  min-height: calc(100vh - 112px);
  margin: -20px;

  .footer {
    position: sticky;
    left: 28px;
    padding-left: 28px;
    bottom: 0;
    background-color: white;
    height: 60px;
    width: 100%;
    border-top: 1px solid #e7e7e7;
  }

  .button {
    padding: 25px 28px;
  }
}

.deviceModal {

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }

  .table_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 10px;
    
  }
  .tips {
    .red {
      color: #d62500;
      padding: 0 5px;
      font-weight: 500;
    }

    svg {
      color: #fee17a;
    }

    .grey {
      color: rgba(var(--base-text-color), 0.35);
      font-size: 12px;
    }
  }

  .action {
    .text {
      color: #333;
    }

    .href {
      padding: 0 5px;
      cursor: pointer;
    }
  }
}
