.drag_box {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  .drag_target {
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    z-index: 10;
    cursor: e-resize;
  }
}
