// g6 主体位置
:global {
  html.theme-dark {
    --mx-bg-color: #3e3e3e;
  }
}

// :global {
//   canvas {
//     position: relative;
//     z-index: 1;
//   }
//   // 网格位置
//   .g6-grid-container {
//     z-index: 0 !important;
//     *,
//     ::before,
//     ::after {
//       // 覆盖 vuetify  background-repeat: no-repeat;
//       background-repeat: repeat;
//     }
//   }
// }

.left {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.drawerStyle {
  :global {
    .ant-drawer-header {
      border-top: 2px solid;
      border-image: var(--mx-modal-border-top-color) 2 2 0 0;
    }
  }
}

.bgImg {
  background: var(--mx-bg-image, var(--mx-light-bg-image, #fff));
}

.block {
  position: absolute;
  top: 26px;
  left: 340px;
  transform: translateX(-50%);
  gap: 23px !important;
}

.item {
  padding-left: 21px;
  position: relative;
  font-size: 14px;
  color: var(--mx-text-base-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pipe {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    margin-right: 6px;
  }
}

.wheelzoomStyle {
  border-radius: 18px;
  height: 28px;
  width: 148px;
  background-color: var(--mx-bg-color, #fff);
  position: absolute;
  top: 26px;
  left: 16px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  text-align: center;
  .wheelzoomBtnStyle {
    width: 40px;
  }
}
