.wrapper {
  padding: 20px;

  .count {
    color: #d62500;
    font-weight: 500;
    padding: 0 6px;
  }

  .form {
    min-height: calc(100vh - 370px);

    .table {
      margin: 30px 10px 0 10px;
    }
  }

  .margin {
    margin-left: 4%;
  }

  .required {
    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }

  .inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;

    .label {
      font-weight: 500;
      width: 120px;
      text-align: right;

      &::after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
      }
    }

    .value {
      flex: 21;

      .count {
        color: #d62500;
        font-weight: 500;
        padding: 0 6px;
      }

      .action {
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: var(--component-background);
    height: 60px;
    width: 100%;
  }
}

.deviceModal {
  min-height: 585px;

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }

  .table_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;

    .tips {
      padding-bottom: 5px;

      .red {
        color: #d62500;
        padding: 0 5px;
        font-weight: 500;
      }

      svg {
        margin-left: 15px;
        color: #fee17a;
      }

      .grey {
        margin-left: 5px;
        color: rgba(var(--base-text-color), 0.35);
        font-size: 12px;
      }
    }
  }

  .action {
    .text {
      color: #333;
    }

    .href {
      padding: 0 5px;
      cursor: pointer;
    }
  }
}

.passwordConfirmModal {
  :global {
    .ant-modal-content .ant-modal-body .ant-modal-confirm-paragraph {
      max-width: 100%;
    }
  }
}
