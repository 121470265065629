.chartContainer {
  position: relative;
  height: calc(100vh - 470px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CardStyle {
  margin-bottom: 10px;
  background-color: #232324;
  border: 1px solid #ffffff12;
  position: relative;
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
  .title {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #fff;
    font-weight: 700;
    z-index: 9
  }
}

.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ProcessChartsTabStyle {
  :global {
    .ant-tabs{
      width: 650px;
    }
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      border-bottom: 1px solid #ffffff20;
    }
  }
}
