.sub_title {
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-text-secondary-color);
  padding-left: 10px;
  .sub_title_text {
    padding: 0 5px;
  }
}

.label_empty {
  :global {
    label {
      &:before {
        color: transparent !important;
      }
    }
  }
}
