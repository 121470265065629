.bottomFixButtonWrapper {
  position: fixed;
  bottom: 0;
  text-align: right;
  right: 0;
  padding: 14px 60px;
  z-index: 10;
}
.showBackground {
  background: rgba(var(--base-text-color), 0.1);
}
