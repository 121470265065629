.pickerArea {
  flex-grow: 1;
  .label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: var(--heading-color);
    font-size: 14px;
    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}
