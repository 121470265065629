.subContent {
  margin-bottom: 8px;
}

.addRowBtn {
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
  height: 32px;
}

.required {
  color: var(--mx-error-color);
}
