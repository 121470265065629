%padding {
  padding: 0 20px;
}

.filterWrapper {
  @extend %padding;
}

.spaceStyle {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: var(--mx-bg-image);
    opacity: 0.6;
  }
  height: 10px;
  background-color: var(--mx-page-bg-color);
}

.content {
  padding: 20px;

  .operationArea {
    margin-bottom: 10px;
  }
}

.form {
  width: 450px;
}
