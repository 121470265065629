.divi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .bef,
  .aft {
    border-top: 2px dashed rgba(255, 255, 255, 0.15);
    width: 100%;
  }
  p {
    margin-bottom: 0;
    white-space: nowrap;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
  }
}
