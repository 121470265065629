.layout {
  display: flex;
  justify-content: center;
  padding: 20px;
  .layout > div {
    width: 90%;
  }
  margin: 0;
}

.title {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  :nth-child(1) {
    margin-right: 3px;
  }
}
.centerIconStyle {
  width: 16px !important;
  height: 16px !important;
  margin-top: -2px;
  img {
    width: 16px !important;
    height: 16px !important;
  }
}

.InPro {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;

  line-height: 20px;
}

.topCard {
  width: 288px;
  background: #2d2d2d;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
  :nth-child(1) {
    height: 28px;
    font-size: 20px;
    margin: 9px 0 0 0;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  :nth-child(2) {
    text-align: center;
    height: 20px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.65);
  }
}

.backIcon {
  width: 16px;
  height: 16px;
  vertical-align: -3px;
}

.ani {
  display: inline-block;
  width: 16px !important;
  height: 16px !important;
  animation: rotate360 4s linear infinite;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rightIcon {
  display: flex;
  justify-content: space-between;
  height: 20px;
  .proLength {
    display: inline-block;
    width: 190px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    display: inline-block;
    // margin-top: 7px;
  }
}

.contentCard {
  margin-top: 20px;
  height: 636px;
  overflow-y: auto;

  .everyCard {
    width: 288px;
    background: #2d2d2d;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 8px;
  }
  .everyCardCheck {
    border-radius: 2px;
    border: 1px solid var(--mx-primary-color);
  }
  .proStatus {
    display: flex;
    justify-content: space-between;
    margin: 0;
    .proLength {
      display: inline-block;
      width: 117px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .productStateBox {
    display: inline-block;
    span {
      display: inline-block;
      margin-bottom: -2px;
      img {
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
        margin-right: 2px;
      }
    }
  }

  .proPlan {
    display: flex;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.65);
    font-weight: 500;
    div {
      width: 96px;
    }
    p {
      margin: 0;
    }
  }
}
.leftLine {
  display: flex;
  justify-content: space-between;
  div:nth-child(1) {
    height: 100%;
    width: 1px;
    height: 35px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin-left: 15px;
    margin-right: 8px;
  }
}

.doubleRightPosition {
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}

.detailStyles {
  width: 100%;
}
.detailBack {
  display: inline-block;
  cursor: pointer;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: var(--mx-primary-color);
  line-height: 20px;
  margin-bottom: 16px;
}
.detailList {
  display: flex;
  justify-content: space-between;
  :nth-child(1) {
    // width: 122px;
    height: 22px;
    font-size: 14px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
  }
  :nth-child(2) {
    width: 146px;
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
  }
}
