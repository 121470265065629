.wrapper {
  padding: 20px;
}
.tableTitle {
  display: flex;
  gap: 18px;
  margin: 18px 0 20px 0;
  font-weight: 500;
  font-size: 16px;
  color: var(--mx-text-base-color);
  line-height: 24px;
  align-items: center;
}
