.formListWrapper {
  position: relative;
  padding-top: 14px;
  padding-left: 20px;
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);

  .btnPosition {
    position: absolute;
    right: 20px;
    top: 31px;
  }
}

.warnInfo {
  color: #e64242;
  font-size: 14px;
}

.selectBtn {
  padding-bottom: 20px;
}
