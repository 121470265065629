.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
  .card_bg {
    height: 100%;
    background-image: url(../../../../imgs/card_bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    .left_top {
      width: 36px;
      height: 100%;
      position: relative;
      .AI_box {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 45px;
        height: 45px;
        background-image: url(../../../../imgs/AISpin.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        animation: rotateImage 1s linear infinite;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .AI_img {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 45px;
        height: 45px;
        background-image: url(../../../../imgs/AIText.png);
        background-size: 24px 14px;
        background-repeat: no-repeat;
        background-position: center center;
      }
      .AIEnergyIndex_box {
        position: absolute;
        top: 10px;
        left: 28px;
        z-index: -1;
        width: 102px;
        height: 60px;
        background: rgba(74, 144, 226, 0.2);
        box-shadow: inset 0px 0px 10px 0px #4a90e2;
        border-radius: 4px;
        border: 1px solid rgba(0, 173, 255, 1);
        .title {
          margin-top: 6px;
          text-align: center;
          font-weight: 500;
          color: #ffffff;
        }
        .value {
          text-align: center;
          font-family: YouSheBiaoTiHei;
          font-size: 21px;
          color: #ffffff;
          background: linear-gradient(180deg, #00aeff 0%, #65ffe9 39%, #00d4ff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .echarts_box {
      flex: 1;
      height: 100%;
      width: 100%;
      margin-top: 10px;
      position: relative;
      .spin_bg_box {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        .spin_bg {
          width: 170px;
          height: 170px;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%); /* 将元素移到视口中心 */
          background-image: url(../../../../imgs/spin.png);
          background-size: 170px 170px;
          background-repeat: no-repeat;
          background-position: center center; /* 背景图居中 */
          animation: rotateImage 2s linear infinite;
        }
      }
    }
  }
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
