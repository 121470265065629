.card {
  border-radius: 2px;
  border: 1px solid var(--border-color-split);
  cursor: pointer;

  .top {
    background: url('./component/scadaBackground.png') no-repeat center center;
    width: 100%;
    height: 230px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      max-width: 100%;
      max-height: 100%;
    }

    .icons {
      position: absolute;
      width: 90%;
      display: flex;
      justify-content: space-around;
      bottom: 10%;

      :global {
        .anticon {
          color: #9ea9b3;
          font-size: 20px;
        }
      }
    }
  }

  .content {
    padding: 10px 15px;

    .name {
      color: var(--heading-color);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      white-space: nowrap;
      width: 90%;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .ou {
      font-weight: 400;
      color: #8c8c8c;
      font-size: 12px;
      white-space: nowrap;
      width: 80%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin: 8px 0;
    }

    .btn {
      width: 100%;
      overflow-x: hidden;
    }
  }
}

.card:hover {
  box-shadow: 0 8px 25px 7px rgba(var(--base-text-color), 0.12);
  border-radius: 2px;

  .top {
    .icons {
      :global {
        .anticon {
          color: #fff;
        }
      }
    }
  }
}

.info {
  color: var(--info-color);
  margin: 5px 5px 0 0;
}

.afterIcons {
  display: inline-block;
  padding: 0 10px;
  background: rgba(var(--base-text-color), 0.04);
  line-height: 30px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  margin-left: -2px;
  border-radius: 0 2px 2px 0;
}

.wrapper {
  padding: 20px;
}
