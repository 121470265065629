.wrapper {
  padding: 20px;
}

.operationArea {
  margin-bottom: 10px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
