.wrapper {
  padding: 20px;
}

.form_box {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.btnBlank {
  margin-bottom: 10px;
}
