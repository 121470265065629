.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  .col {
    margin-bottom: 30px;
  }
}
.reset_form_style {
  :global {
    .ant-form-item-control {
      max-width: none !important;
    }
  }
}
