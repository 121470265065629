// .firstCell {
//   background-color: #fafafa;
// }

.activePowerTable {
  margin-bottom: 21px;

  :global {
    .ant-table-footer {
      padding: 0;
    }
  }
}
