.buttonWrapper {
  position: fixed;
  bottom: 0;
  background: rgba(var(--base-text-color), 0.1);
  width: calc(100% - 245px);
  margin-left: -30px;
  padding: 14px 60px;
  text-align: right;
}
.colBox {
  position: relative;
}
.colBoxChild {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.rowItemBox {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 12px;
  .rowItem {
    padding: 0 2px;
  }
  .rowItemLeft {
    width: 120px;
  }
  .rowItemRight {
    padding-left: 5px;
    cursor: pointer;
    color: var(--primary-color);
  }
}
:global {
  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    background-color: transparent;
  }
}
