.value {
  margin-top: 10px;

  .tag {
    margin-bottom: 5px;
  }
}

.deviceSelect_sty {
  display: flex;
}
