%color {
  color: var(--primary-color);
}

.pricingMethod {
  @extend %color;
  // font-size: 14px;
  &::before {
    width: 4px;
    height: 4px;
    display: inline-block;
    content: '';
    margin-right: 5px;
    margin-bottom: 3px;
    background: var(--primary-color);
  }
}

.color {
  @extend %color;
  margin-left: 2px;
  margin-right: 2px;
}
