.videoBoxItem {
  cursor: pointer;
  position: relative;
  background: #000;

  .err_block,
  .loading_block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  }
  .loading_block {
    color: var(--primary-color);
  }

  .videoBaseInfo {
    height: 40px;
    padding: 0 8px;
    font-size: 12px;
    background: #131313;
    border-radius: 0px 0px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deviceInfo {
      font-size: 14px;
      color: #16dd8e;
    }
  }
  .backMark {
    position: absolute;
    top: 48px;
    background: #ff9247;
    right: 8px;
    font-size: 12px;
    border-radius: 2px;
    width: 80px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .footer {
    cursor: auto;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #131313;
    .left_text {
      color: rgba(255, 255, 255, 0.85);
    }
    .location_text {
      flex: 1;
      padding: 0 8px;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgba(255, 255, 255, 0.65);
    }
    .right_text {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

.toolBar {
  background: rgba(19, 19, 19, 0.8);
  height: 48px;
  padding: 12px;
  position: absolute;
  top: 442px;

  .videoControlIcon {
    margin-right: 24px;
    color: rgba(255, 255, 255, 0.85);
    &:hover {
      color: rgb(255, 255, 255);
    }
    &.active,
    &:active {
      color: var(--mx-primary-color);
    }

    &.disabled {
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .rightIcon {
    float: right;
    margin: 0 0 0 24px;
    &:hover {
      color: rgb(255, 255, 255);
    }

    &.active,
    &:active {
      color: var(--mx-primary-color);
    }
    &.disabled {
      cursor: not-allowed;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .soundBox {
    position: relative;
    display: inline-flex;

    .volumeBox {
      width: 32px;
      height: 160px;
      padding: 8px 0;
      background: rgba(19, 19, 19, 0.8);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 40px;

      :global {
        .mx-rc-slider.ant-slider .ant-slider-handle::after {
          box-shadow: none;
          background: #00adff;
          border: 1px solid #fff;
        }

        .mx-rc-slider.ant-slider .ant-slider-track {
          background: linear-gradient(180deg, #2cc4f9 0%, #057abf 100%);
        }
      }
    }
  }

  .controlBox {
    position: absolute;
    bottom: 64px;
    right: 24px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(19, 19, 19, 0.6);
    border-radius: 50%;

    .dirBox {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .upBox {
      width: 84px;
      height: 34px;
      top: 0;
      align-items: start;
      &:hover {
        background-image: url('../../images/upbg-hover@2x.png');
      }
      &:active {
        background-image: url('../../images/upbg-active@2x.png');
      }
    }
    .downBox {
      width: 84px;
      height: 34px;
      bottom: 0;
      align-items: end;
      &:hover {
        background-image: url('../../images/downbg-hover@2x.png');
      }
      &:active {
        background-image: url('../../images/downbg-active@2x.png');
      }
    }
    .leftBox {
      height: 84px;
      width: 34px;
      left: 0;
      justify-content: left;
      &:hover {
        background-image: url('../../images/leftbg-hover@2x.png');
      }
      &:active {
        background-image: url('../../images/leftbg-active@2x.png');
      }
    }
    .rightBox {
      height: 84px;
      width: 34px;
      right: 0;
      justify-content: end;
      &:hover {
        background-image: url('../../images/rightbg-hover@2x.png');
      }
      &:active {
        background-image: url('../../images/upbg-active@2x(1).png');
      }
    }

    .controlOuter {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50%;
    }

    .controlInner {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      line-height: 40px;
      text-align: center;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
      background: rgba(19, 19, 19, 0.6);
      user-select: none;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      &.active,
      &:active {
        background: rgba(74, 144, 226, 0.5);
        box-shadow: inset 0px 0px 4px 0px #52e7ff, inset 0px 0px 4px 0px #52e7ff;
      }
    }
  }
}
.playbackControl {
  height: 128px;
  background: #131313;
  padding: 16px;

  .filter {
    display: flex;
    justify-content: space-between;
    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .playbackBtns {
    margin-top: 32px;
    :global {
      .mx-rc-btn {
        margin-right: 8px;
      }
    }
  }
}
