.radioStyles {
  :global {
    .ant-radio-button-wrapper {
      color: #7fa8d7;
      border-inline-start: 1px solid transparent;
      margin-right: 8px;
    }
    .ant-radio-button-wrapper:hover {
      background: rgba(74, 144, 226, 0.15);
    }
    .ant-radio-button-wrapper:first-child {
      border-inline-start: 1px solid rgba(0, 173, 255, 0.5);
    }
    .ant-radio-button-wrapper:last-child {
      border-inline-start: none;
    }
    .ant-radio-button-wrapper-checked {
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      color: #fff;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: #fff;
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
    }
  }
}

.rangePicker {
  width: 234px;
  :global {
    .ant-picker-range-separator {
      padding: 0 4px;
    }
  }
}

.translationStyles {
  animation: translateAnimation 1s ease-in-out infinite alternate;
}

@keyframes translateAnimation {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(8px);
  }
}

.centerInfo {
  padding: 16px 32px 24px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .center_left_Info {
    position: relative;
    .center_left_img {
      width: 72px;
      height: 72px;
      position: relative;
      :first-child {
        width: 72px;
        height: 72px;
        position: absolute;
        top: 0;
        left: 0;
        animation: rotation 1s linear infinite;
      }
      :last-child {
        width: 34px;
        height: 34px;
        position: absolute;
        top: 26%;
        left: 26%;
      }
    }
    .center_left_word {
      position: absolute;
      top: 8px;
      left: 88px;

      .firstP {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.85);
        line-height: 20px;
        width: 200px;
      }
      .secondP {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #00adff;
        line-height: 28px;
        background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .center_right_Info {
    .center_right_line {
      width: 1px;
      height: 51px;
      background: rgba(255, 255, 255, 0.15);
    }
    .center_right_word {
      :first-child {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding-top: 18px;
        margin-right: 40px;
      }
      :last-child {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
