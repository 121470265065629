.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  border-radius: 2px;
  // max-width: 220px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.65);

  .cardTop,
  .cardBottom {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }

  .valueTop,
  .valueBottom {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
  }

  .value {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.85);
  }
}

.onlyTwoRow {
  padding: 22px 16px;
}
