.wrapper {
  padding: 20px;
}

.flowingBtn {
  padding: 0 12px;
  line-height: 32px;
  border-radius: 26px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  box-shadow: 0px 7px 13px 0px rgba(74, 143, 225, 0.4);
  img {
    margin-right: 4px;
  }

  .aiHoverIcon {
    display: none;
  }
  &:hover {
    .aiDefaultIcon {
      display: none;
    }
    .aiHoverIcon {
      display: block;
    }
  }
}

.flowingBtn.disabledBtn {
  cursor: not-allowed;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.3);
  &::before,
  &::after {
    animation: none;
  }

  &::before {
    background: rgba(81, 161, 255, 0.3);
  }
}

.flowingBtn::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 320%;
  background: #4a90e2;
  background-image: conic-gradient(transparent, #16dd8e, transparent 50%);
  left: -50%;
  top: -110%;
  z-index: -2;
  animation: flowingBorder 1s linear infinite;
}

@keyframes flowingBorder {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flowingBtn::after {
  content: '';
  inset: 1px;
  background: #1b1b1b;
  border-radius: 32px;
  position: absolute;
  z-index: -1;
}

.flowingBtn:not(.disabledBtn):hover::after {
  background: #2e435d;
}
