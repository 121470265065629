.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
}

.danger {
  color: #f00 !important;
}

// .collHeader {
//   :global {
//     .ant-collapse-header {
//       width: 520px;
//     }
//   }
// }
