.wrapperStyles {
  min-height: calc(100vh - 128px);
  padding: 20px;
}
.infoBox {
  padding: 0;
  margin-bottom: 16px !important;
}
.title {
  color: var(--mx-text-desc-color);
  font-size: 14px;
}
.add-row-btn {
  width: 100%;
  .row-btn-inner {
    width: 100%;
  }
}

.save-row-btn {
  margin-top: 32px;
  margin-right: 8px;
}
