.treeArea {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  .treeTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .searchArea {
    margin-top: 8px;
  }

  .treeBox {
    flex: 1;
    overflow-x: hidden;

    :global {
      .mx-rc-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
        cursor: default;
        color: var(--mx-text-base-color);
      }
      .mx-rc-tree .ant-tree-title {
        width: 100%;
      }
    }

    .treeBtn {
      display: none;
    }

    .treeTitle:hover {
      display: flex;
      justify-content: space-between;
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .treeBtn {
        display: flex;
        margin-left: 4px;
      }

      .titleRenderIcon:hover {
        color: var(--mx-primary-color);
      }
    }

    .baseTreeTitle:hover {
      display: block;

      .treeBtn {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
}
