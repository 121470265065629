.files_wrapper {
  display: flex;
  align-items: center;
  .label_seat {
    min-width: 128px;
  }
}

.required {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ac222a;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
