.cardStyles {
  margin-bottom: 20px;
  :global {
    .ant-card-body {
      padding: 0;
    }
  }
}

.pStyles {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  line-height: 22px;
  margin: 12px 0 0 12px;
}

.formStyles {
  margin: 24px 0 0 24px;
}
