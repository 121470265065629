.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 22px 20px;
}

.col {
  margin-bottom: 35px;
}

.itemLabel {
  display: flex;
  align-items: flex-start;
  .leftBase {
    flex: 1;
  }
  .expandBtn {
    padding: 0;
  }
}

.stickyFooter {
  position: sticky;
  left: 0;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
}

.detailTabs {
  :global {
    .ant-tabs-nav::before {
      border-bottom: 1px solid var(--mx-base-border-color) !important;
    }
  }
}

.container {
  min-height: 300px;
}

.optionRow {
  display: flex;
  justify-content: space-between;
  margin: 32px 170px 8px 0;
}
