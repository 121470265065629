$cell-width: 100px;
$cell-height: 50px;
$border: 1px solid rgba(var(--base-text-color), 0.15);

.wrapper {
  height: 400px;
  overflow: auto;
  .pointWrapper {
    display: inline-block;
    &:last-child {
      border-bottom: $border;
    }
  }
  .row {
    display: flex;
    .col {
      display: flex;
      width: $cell-width;
      // min-height: 50px;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-left: $border;
      border-top: $border;
      &:last-child {
        border-right: $border;
      }
    }
  }
  .desc {
    background-color: var(--component-background);
  }
  .content {
    height: $cell-height;
  }
}
