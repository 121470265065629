.radioStyles {
  :global {
    .ant-radio-button-wrapper {
      color: #7fa8d7;
      border-inline-start: 1px solid transparent;
      margin-right: 8px;
    }
    .ant-radio-button-wrapper:hover {
      background: rgba(74, 144, 226, 0.15);
    }
    .ant-radio-button-wrapper:first-child {
      border-inline-start: 1px solid rgba(0, 173, 255, 0.5);
    }
    .ant-radio-button-wrapper:last-child {
      border-inline-start: none;
    }
    .ant-radio-button-wrapper-checked {
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      color: #fff;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
      color: #fff;
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
    }
  }
}

.progressCircleStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  // opacity: 0.5;
  border-radius: 50%;
  background: rgba(0, 118, 255, 0.05);
  box-sizing: border-box;
  padding: 4px;
  background-image: linear-gradient(180deg, rgba(37, 130, 183, 1), rgba(68, 120, 155, 0.25));
  .progressCircleInlineStyle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #1c202380;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    :first-child {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      line-height: 25px;
      margin-bottom: 5px;
    }
    :last-child {
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.9);
      line-height: 17px;
      margin-bottom: 5px;
    }
  }
}

.planContent {
  width: 100%;
  height: 100%;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.processStyle {
  :global {
    .ant-progress-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
