.ReactEcharts {
  width: 100%;
  height: 217px;
}
.content {
  position: relative;
  .rawMaterial {
    position: absolute;
    width: 26px;
    height: 26px;
    z-index: 100;
    left: 32px;
    top: 68px;
  }
  .deliveryTruck {
    position: absolute;
    width: 26px;
    z-index: 100;
    height: 26px;
    left: 32px;
    top: 112px;
  }
  .industryProduce {
    position: absolute;
    width: 26px;
    height: 26px;
    z-index: 100;
    left: 32px;
    top: 157px;
  }
}
