.productModule {
  :global {
    .mx-rc-form-item.ant-form-item {
      display: inline-block;
      text-align: left;
      width: 240px;
      margin-left: 8px;
      margin-bottom: 0 !important;
    }
  }
}
.carbonFootprintInfo {
  height: 40px;
  padding: 8px 0;
  background: linear-gradient(
    90deg,
    rgba(82, 231, 255, 0.1) 0%,
    rgba(82, 231, 255, 0.15) 50%,
    rgba(82, 231, 255, 0.1) 100%
  );
  border-radius: 2px;
  border: 1px solid rgba(82, 231, 255, 0.1);
  display: flex;

  .detail {
    padding-left: 16px;
    line-height: 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    &:last-child {
      border: none;
    }

    .desc {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
    }

    .count {
      display: inline-block;
      margin-left: 8px;
      font-weight: 500;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.productCFCon {
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 104px;
}
