.footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  .tip {
    margin-left: 2px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 20px;
  }
  .link {
    padding: 0px;
  }
}

.tipModalBox {
  color: rgba(255, 255, 255, 0.65);
  .descTxt {
    font-weight: 400;
    line-height: 17px;
    font-size: 12px;
  }
  .titleTxt {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
    & > span {
      margin-left: 3px;
    }
  }
  .cancelBtn {
    margin-right: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.calcModalBox {
  .tipWrapper {
    margin-bottom: 15px;
    .txt {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 17px;
      margin-left: 3px;
    }
  }
  .cancelBtn {
    margin-right: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  :global {
    .ant-picker-range {
      width: 100%;
    }
  }
}

.treeSelect {
  :global {
    .ant-select-tree .ant-select-tree-treenode:last-child {
      padding-bottom: 20px;
    }
  }
}
