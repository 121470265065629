.customNode {
  width: 100%;
  height: 100%;

  .nodeIcon {
    width: 40px;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;

    &:hover,
    &:visited {
      border: 1px solid #ffffff;
    }
    .nodeImg {
      width: 32px;
      height: 32px;
    }
  }

  .distributeNode {
    background: rgba(74, 144, 226, 0.25);
    border: 1px solid var(--mx-primary-color);
  }

  .handleNode {
    background: rgba(133, 78, 202, 0.25);
    border: 1px solid #854eca;
  }

  .nodeName {
    margin-top: 4px;
    font-size: 12px;
    color: var(--mx-text-base-color);
    line-height: 20px;
    font-style: normal;
    text-align: center;
    width: max-content;
  }
}

.nodeTip {
  :global {
    .ant-tooltip-arrow:before {
      display: none;
    }
    .mx-rc-tooltip.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 8px 12px;
    }
  }
  .titleWrapper {
    width: 200px;
    font-size: 12px;
    line-height: 20px;
    color: var(--mx-text-base-color);
    .port {
      margin-top: 5px;
    }
    .row {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 100%;
      }
      .nodeName {
        flex: 1;
        padding: 0 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
