.container {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  .headerWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    .title {
      color: var(--text-color);
      font-weight: 500;
      font-size: 16px;
    }

    .titleBorder {
      width: 16px;
      height: 16px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  .content_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .itemRank {
      display: flex;
      align-items: center;
      .rankNumber {
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        font-weight: 500;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        line-height: 24px;
      }

      .rank1 {
        color: #e64242;
        background: rgba(230, 66, 66, 0.2);
      }

      .rank2 {
        color: #faad14;
        background: rgba(250, 173, 20, 0.2);
      }

      .rank3 {
        color: #4a90e2;
        background: rgba(74, 144, 226, 0.2);
      }

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 220px;
        text-align: left;
        margin-right: 6px;
      }

      .process {
        width: 200px;
        color: rgba(255, 255, 255, 0.85);
        margin-right: 20px;
      }

      .percent {
        margin-right: 20px;
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }
}
