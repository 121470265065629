.page_box {
  display: flex;
  align-items: center;

  .page_before {
    padding-top: 20px;
    color: var(--mx-text-secondary-color);
    .red {
      color: red;
      padding: 0 5px;
    }
  }
  .page_after {
    flex: 1;
  }
}
