.footer {
  position: sticky;
  bottom: -30px;
  height: 80px;
  width: 100%;
  display: flex;
  padding: 0 0 0 12px;
  align-items: center;
  z-index: 99;
}
.head_content {
  padding: 0 10px;
}
.tips_line {
  display: flex;
  align-items: center;
  padding-left: 98px;
}