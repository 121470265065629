.tabs {
  height: 48px;
  margin: 0 10px 16px;
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;
        :before {
          border-bottom: none;
        }
      }
    }
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tab_box {
    flex: 1;
    overflow: hidden;
  }
}
