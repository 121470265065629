.customModal {
  .loadingText {
    .dot {
      animation: blink 1.2s infinite step-start;
    }

    .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  .ant-modal-content .ant-modal-header {
    border-bottom: none;
  }
  .mx-rc-rc-modal-content {
    padding: 16px 24px;
    max-height: 560px;
    display: flex;
    flex-direction: column;
  }

  .analysisSubTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 26px;
    padding-bottom: 14px;
    margin-bottom: 16px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  }

  .analysisContent {
    padding: 16px;
    background: linear-gradient(225deg, rgba(82, 231, 255, 0.1) 0%, rgba(74, 144, 226, 0.1) 100%);
    border-radius: 8px;
    flex: 1;
    overflow: auto;

    h3 {
      font-size: 16px;
    }
    h3,
    p {
      margin-bottom: 4px;
    }
  }
}

.customModalHeader {
  margin: -16px -24px;
  height: 160px;

  .titleContent {
    position: absolute;
    top: 84px;
    left: 152px;

    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;

    .title {
      font-family: YouSheBiaoTiHei;
      font-size: 32px;
      color: #16dd8e;
      line-height: 24px;
      margin-right: 24px;
    }
  }
}

@keyframes blink {
  0%,
  20% {
    opacity: 0;
  }
  40%,
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
