.content {
  padding: 0px !important;
}

.tabs {
  :global {
    .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
      bottom: inherit;
      top: 0;
    }
    .ant-tabs-nav {
      &::before {
        content: none;
      }
      margin-bottom: 0px;
      padding: 0 20px;
    }
  }
}
