.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
  justify-content: center;
  .echarts_wrapper {
    flex: 1;
    height: 100%;
    width: 100%;
  }
}
