@import '~@maxtropy/components/es/lib/theme/dark.scss';

#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Microsoft YaHei', 'PingFang SC', '黑体', '宋体', sans-serif;
  min-width: 1366px;
}
