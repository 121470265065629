.formListWrapper {
  padding-left: 20px;
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  border: 1px solid rgba(var(--base-text-color), 0.15);
  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.totalAmount {
  background: rgba(var(--base-text-color), 0.03);
  border-radius: 3px;
  padding: 7px 10px;
}
