.wrapper {
  min-height: calc(100vh - 115px) !important;
  padding: 20px;
  :global {
    .ant-form-item-label > label .ant-form-item-tooltip {
      color: var(--mx-warning-color);
    }
  }
}

.col {
  margin-bottom: 30px;
}
