.bottomStyles {
  margin: 10px 0;
  position: relative;
  flex: 1;
  min-height: 260px;
  :global {
    .ant-table-thead > tr > th,
    .ant-table-cell {
      padding: 10px 16px !important;
    }
    //.ant-table-tbody>tr>td
  }
}

.carbonRadio {
  margin-left: 60px;
  margin-bottom: 12px;
}
