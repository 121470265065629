$half-width: 13px;
$half-margin: 1px;

.peak-item {
  display: flex;
  min-width: calc(14 * 48px);
}

.radio-wrap {
  display: flex;
  align-items: center;
}

.tip-bar {
  width: 4px;
  height: 16px;
  display: inline-block;
  margin-left: 4px;
}

.bar {
  // margin-right: 1px;
  height: 32px;
  display: inline-block;
}

.half {
  width: $half-width;
  margin-right: $half-margin;
}

.hour {
  width: calc(2 * $half-width);
  margin-right: calc(2 * $half-margin);
}

.modal-content {
  color: var(--text-color);
  padding: 0 20px;
  .info {
    margin-top: -8px;
    margin-bottom: 30px;
  }
  .input-suffix {
    margin-bottom: 24px;
  }
}

.table-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  color: var(--text-color);
  .legend {
    width: 16px;
    height: 16px;
  }
}

.table-price {
  font-size: 14px;
  span {
    color: #ff6d2c;
  }
}
