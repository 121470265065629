.wrapper {
  margin-bottom: 15px;
}
.tips {
  display: flex;
  .red {
    padding: 0 5px;
    color: red;
  }
}
