.wrapper {
  border: 1px solid rgba(var(--base-text-color), 0.15);
  .borderBottom {
    border-bottom: 1px solid rgba(var(--base-text-color), 0.15);
  }
  .raw {
    height: 51px;
    padding-top: 12px;
  }
}
