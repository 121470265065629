.legend {
  text-align: right;
  .legendItem {
    display: inline-flex;
    align-items: center;
    margin-left: 16px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
}
.content {
  margin-top: 12px;
  max-height: 460px;
  overflow-y: scroll;
  padding-right: 4px;
  .list {
    display: flex;
    font-size: 16px;
    margin-bottom: 20px;
    height: 28px;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .rank {
      display: inline-block;
      width: 20px;
      background: #3e3e3e;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }

    .name {
      margin: 0 18px 0 8px;
      width: 180px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.barTooltip {
  :global {
    .ant-tooltip-content .ant-tooltip-inner {
      background: rgba(0, 0, 0, 0.8) !important;
      min-width: 200px;
    }
  }
  .barTooltipContent {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 20px;
    padding: 7px 6px;

    > div {
      display: flex;
      > span {
        flex: 1;

        .dot {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
    }
  }
}
