$primaryColor: var(--primary-color);
$base-text-color: var(--base-text-color);

.list {
  width: '100%';
  display: flex;
  :global {
    .ant-list-items {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-list-item {
      min-width: 500px;
      width: 49%;
      margin: 0.5%;
    }
  }

  .name {
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    margin: 5px 0;
  }
  .tag {
    color: $primaryColor;
    border: 1px solid $primaryColor;
    padding: 0 3px;
  }
  .content {
    width: 49%;
    display: flex;
    padding: 20px 10px;
    border: 1px solid transparent;
    border-top-color: var(--mx-primary-color);
    border-top-width: 2px;
    cursor: pointer;
    background-color: var(--mx-card-dark-bg-color);
    .descTextStyle {
      font-size: 16px;
      font-weight: 700;
    }
    .actionContent {
      text-align: right;
      margin-top: 10px;
      .action {
        margin-left: 30px;
        position: relative;
        display: inline-block;
        text-align: left;
        .btn {
          padding-left: 0;
          padding-right: 0;
          margin-right: 10px;
        }
        .gray {
          color: rgba($base-text-color, 0.5);
        }
      }
      .action:before {
        content: '';
        width: 2px;
        height: 14px;
        background: $primaryColor;
        position: absolute;
        left: -8px;
        top: 9px;
      }
    }
    :global {
      .ant-descriptions-row > th {
        padding-bottom: 8px;
      }
      .ant-descriptions-row > td {
        padding-bottom: 0;
      }
    }
  }
  .content:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.08),
      0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    border: 1px solid var(--border-color-split);
    border-top-color: var(--mx-primary-color);
    border-top-width: 2px;
  }
}
