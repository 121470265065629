.wrapper {
  padding: 20px;
}

.importBom {
  :global {
    .ant-form-item-label > label {
      color: var(--mx-text-secondary-color);
    }
  }
  .tip {
    font-size: 12px;
    color: var(--mx-text-desc-color);
    margin-bottom: 8px;
    :global {
      .anticon {
        color: var(--mx-warning-color);
        font-size: 14px;
        margin-right: 4px;
      }
    }
  }
}

.importErrorTxt {
  :global {
    .ant-btn {
      padding-left: 0;
    }
  }
}

// .detailForm {
//   :global {
//     .ant-form-item-label > label {
//       color: var(--mx-text-secondary-color);
//     }
//   }
// }

.pagingCon {
  position: relative;
  .selectedTxt {
    position: absolute;
    bottom: 0;
    font-size: var(--mx-font-size-normal);
    line-height: var(--mx-line-height-normal);
    color: var(--mx-text-secondary-color);

    span {
      display: inline-block;
      text-align: center;
      margin: 0 4px;
      color: var(--mx-error-color);
    }
  }
}

.quantityForm {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
