.nameAndTimeSty {
  display: flex;
  align-items: center;
  .currentSelectedName {
    width: 210px;
    border-right: 1px solid #ffffff40;
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .nameTextStyle {
      color: rgba(255, 255, 255, 0.85);
      font-weight: 500;
    }
  }
}

.cardContent {
  margin: 12px 0;
  display: flex;
  .mainCardContent {
    display: flex;
    padding-left: 16px;
    align-items: center;
    height: 72px;
    width: 206px;
    margin-right: 12px;
    background: rgba(255, 255, 255, 0.1);
    .mainCardImg {
      width: 48px;
      height: 48px;
    }

    .mainCardTitle {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 17px;
    }

    .mainCardValue {
      display: block;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      line-height: 25px;
    }
  }

  .restCardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 72px;
    width: calc(100% - 206px);
    background: rgba(255, 255, 255, 0.1);
    overflow-x: auto;
    .restCardContentTitle {
      padding-left: 16px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 17px;
    }
    .allresCardContent {
      display: flex;
      .restCardContentValue {
        width: 212px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        padding: 4px 16px 0px;
        .restCardContentValueTitle {
          font-size: 12px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
          line-height: 17px;
        }

        .restCardContentValuevalue {
          font-size: 14px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.85);
          line-height: 25px;
        }
        .restCardContentMoney {
          font-size: 14px;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.85);
          line-height: 25px;
          padding-left: 12px;
        }
      }
    }
  }
}
