$component-background: var(--mx-module-bg-color);
.wrapper {
  padding: 0;
  background: none;
}
.cardContainer {
  overflow-x: auto;
  // white-space: nowrap;
  // padding-bottom: 10px;
  .cardItem {
    margin-right: 9px;
    width: 210px;
    height: 88px;
    display: inline-block;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.cardContainerMoreThanTree {
  display: flex;
  padding-bottom: 10px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ffffff40;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: #ffffff10;
  }
  .cardItem {
    margin-right: 9px;
    flex: 1;
    min-width: 210px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.content {
  background: var(--component-background);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;

  .empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}

.sider {
  border-radius: 2px;
  transition: all 0.2s;
}

.filter {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // margin-bottom: 10px;
  overflow: hidden;
  // flex-wrap: wrap;
}
.commonBtn {
  float: right;
  margin-left: 10px;
}
.tabContent {
  padding: 20px;
  .topDivSty {
    width: 100%;
    display: flex;
    .topLeftDivSty {
      width: 750px;
      padding-right: 10px;
    }
    .topRightDivSty {
      position: relative;
      width: calc(100% - 750px);
    }
  }
}

.chartContainer {
  position: relative;
  height: calc(100vh - 480px);
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sortBtn {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_box {
  display: flex;
  // border-bottom: 1px solid #303030;
  height: 63px;
  margin-top: -8px;
  width: 100%;
  // margin-bottom: 10px;
}

.spanCursor {
  color: var(--mx-primary-color);
  margin: 5px 0 0 10px;
  cursor: pointer;
  display: inline-block;
}
// @media only screen and(max-width:1200px) {
//   .hidden {
//     display: none !important;
//   }
// }
.position_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.currentSelected {
  margin-bottom: 18px;
  height: 48px;
  background-color: #ffffff10;
  border-radius: 2px;
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .exportBtn {
    display: flex;
    padding-left: 10px;
    border-left: 1px solid #ffffff40;
  }
  .currentSelectedName {
    font-size: 14px;
    color: #fff;
    margin-right: 12px;
  }
}
