.content {
  :global {
    .ant-input-number-group-wrapper {
      width: 100%;
    }
    .mx-rc-date-picker {
      width: 100%;
    }
  }
  .remain {
    :global {
      .ant-input-number-group-wrapper {
        width: 100%;
      }
      .mx-rc-date-picker {
        width: 100%;
      }
      .ant-input-number-group-addon {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
