.originItem {
  :global {
    .ant-form-item-row {
      flex-flow: row wrap !important;
    }

    .ant-form-item-label {
      width: auto !important;
      max-width: none !important;
    }
  }
}

.ml128 {
  margin-left: 128px;
}
