.filterArea {
  padding: 0 10px;
  margin-bottom: 12px;
}

.searchArea {
  padding: 0 10px;
  margin: 12px 0;

  :global {
    .ant-input {
      background-color: transparent;
    }
  }
}

.filterArea {
  padding-top: 18px;
  display: flex;
  align-items: center;

  .label {
    flex: 1;
  }
}

.treeArea {
  height: calc(100vh - 142px);
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  .treeBox {
    flex: 1;
    overflow: auto;

    :global {
      .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        align-items: start;
      }
    }
  }
}

.iconStyle {
  scale: 0.67;
  width: 28px;
  height: 28px;
  margin-top: 2px;
  padding-right: 2px;
}
