.modeledProductChart {
  position: relative;
  margin-top: 16px;
  height: 350px;
  display: flex;
  flex: 7;
  justify-content: center;
  align-items: center;
  .modeledProductChartTitle {
    position: absolute;
    left: 0;
    top: 0;
  }
}
