.toolTip {
  padding: 0 16px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 5px;

  .text {
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: var(--text-color-secondary);
  }
}

.checkedTip {
  margin-top: 16px;
  color: var(--text-color-secondary);
  .checkedNum {
    color: #e64242;
  }
}

.title {
  margin-top: 1em;
}

.checkboxContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  :global {
    .ant-checkbox-wrapper {
      width: 25%;
      margin-inline-start: 0px;
      margin-top: 16px;
    }
  }
}

.deviceModal {
  max-width: none;
  :global {
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      width: 8px;
      height: 2px;
    }
  }
}

.dataGroup {
  margin-top: 16px;
  color: var(--text-color-secondary);
}

.footer {
  width: 100%;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
