.container {
  min-height: calc(100vh - 145px);
  .buttonWrapper {
    position: fixed;
    bottom: 0;
    background: rgba(var(--base-text-color), 0.1);
    width: calc(100% - 254px);
    margin-left: -30px;
    padding: 14px 60px;
    text-align: right;
  }
}
