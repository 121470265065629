.formContent {
  :global {
    .ant-row:not(.ant-form-item-row) {
      column-gap: 20px;
      & > .ant-col-8 {
        max-width: calc((100% - 40px) / 3);
      }
    }
  }
}
.textArea {
  width: 100%;
  background: #2d2d2d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 16px;

  .infoLine {
    height: 38px;
    display: flex;
    align-items: center;
  }
  .analysisContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    font-style: normal;
    padding-top: 7px;
    border-top: 1px dashed rgba(255, 255, 255, 0.2);
    .img {
      width: 16px;
      height: 16px;
    }
  }
  p {
    margin-bottom: 0;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    flex: 1;

    span {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}
.text {
  color: rgba(82, 231, 255, 1);
}

.flowdetail {
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 22px;
  font-style: normal;
  padding-bottom: 8px;
}
.text3 {
  color: #ff4d4f;
}
.text2 {
  color: #dca306;
}
.text {
  color: #00adff;
  padding: 0 4px;
}
.detail {
  height: 462px;
  margin-top: 16px;
  display: flex;

  .pieInfo {
    width: 400px;
    padding-bottom: 24px;
    position: relative;

    .img {
      position: absolute;
      width: 224px;
      height: 224px;
      left: 76px;
      top: 18px;
    }
    .empty {
      position: absolute;
      width: 98px;
      height: 80px;
      left: 96px;
      top: 32px;
    }
  }

  .title {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    margin-bottom: 0;
  }

  .divider {
    width: 1px;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 24px;
  }

  .sankeyInfo {
    width: 100%;
    .energyNumInfo {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 22px;
      margin-bottom: 0;
      margin-top: 30px;

      span {
        font-weight: 500;
        color: var(--mx-warning-color);
      }
    }
  }

  .mediumWidth {
    width: 100%;
  }
}
