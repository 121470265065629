.extraContent {
  display: flex;
  flex: 1;
  margin-left: 16px;
  align-items: center;
  .ggsTitleInfo {
    flex: 1;
    font-size: 14px;
    color: var(--mx-text-base-color);
    label {
      color: var(--mx-text-secondary-color);
    }
  }
}

.container {
  display: flex;
  gap: 16px;
  .stencil {
    width: 180px;
    min-width: 180px;
    height: calc(100vh - 223px);
    position: relative;
    margin-top: -14px;
  }

  .graphBox {
    flex: 1;
    height: calc(100vh - 223px);
    background: var(--mx-bg-image, var(--mx-light-bg-image, #fff));
    border: 1px solid var(--mx-base-border-color);
    border-radius: 2px;
    position: relative;

    .noMap {
      position: absolute;
      top: calc(50% - 56px);
      left: calc(50% - 140px);
      text-align: center;

      .img {
        width: 48px;
        height: 48px;
      }
      .desc {
        width: 280px;
        line-height: 22px;
        margin-top: 24px;
        color: var(--mx-text-secondary-color);
        font-size: 14px;
      }
    }

    .graph {
      flex: 1;
      height: calc(100vh - 216px);
      body {
        min-width: 24px;
      }
    }
    :global {
      .x6-cell-tool-button {
        circle {
          fill: #3e3e3e;
          stroke: #ffffff;
          stroke-width: 1px;
          border: 1px solid #ffffff;
        }
        &:hover {
          circle {
            fill: #4a90e2;
          }
        }
      }
    }
  }

  :global {
    .mx-rc-tooltip.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 8px 12px;
    }
  }

  :global {
    .x6-widget-stencil-title {
      display: none;
    }
    .x6-widget-stencil {
      background-color: transparent;
    }
  }
}

.wrapperStyles {
  min-height: calc(100vh - 128px);
  padding: 20px;
}

.antLine :local {
  animation: antLine 30s infinite linear;
}

@keyframes antLine {
  to {
    stroke-dashoffset: -1000;
  }
}
