.content {
  padding-left: 48px;
  :global {
    .ant-input-number-input-wrap {
      width: 213px;
    }
  }
}
.autoTip {
  margin-left: 153px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-text-secondary-color);
  line-height: 22px;
  margin-bottom: 56px;
}
.emptyTip {
  margin-bottom: 56px;
}
