.filterWrapper {
  padding: 10px 10px 0 10px;
}

.spaceStyle {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-image: var(--mx-bg-image);
    opacity: 0.6;
  }
  height: 10px;
  background-color: var(--mx-page-bg-color);
}
