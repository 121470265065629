.processEntryItemStyle {
  width: 32%;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: var(--mx-card-base-bg-color);
  border: 0px;
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    height: 300px;
    color: var(--mx-text-base-color) !important;
  }
}

.processExitItemStyle {
  width: 32%;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: var(--mx-card-base-bg-color);
  border: 0px;
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    // height: 260px;
    min-height: 200px;
    color: var(--mx-text-base-color) !important;
  }
}

.processNodeItemStyle {
  width: 32%;
  // height: 240px;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: var(--mx-card-base-bg-color);
  border: 0px;
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    // height: 220px;
    min-height: 200px;
    color: var(--mx-text-base-color) !important;
  }
}

.addport_entry_itemStyle {
  width: 32%;
  // height: 320px;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: transparent;
  border: 1px dashed var(--mx-base-border-color);
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    // height: 300px;
    min-height: 200px;
    color: var(--mx-text-base-color) !important;
  }
}

.addport_exit_itemStyle {
  width: 32%;
  // height: 280px;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: transparent;
  border: 1px dashed var(--mx-base-border-color);
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      // height: 280px;
      // min-height: 200px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    // height: 260px;
    min-height: 200px;
    color: var(--mx-text-base-color) !important;
  }
}

.addport_node_itemStyle {
  width: 32%;
  // height: 240px;
  min-height: 200px;
  border-radius: 2px;
  margin: 6px 0.5%;
  background-color: transparent;
  border: 1px dashed var(--mx-primary-color);
  :global {
    .ant-card-body {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      // height: 240px;
      // min-height: 200px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .addProcessBtnStyle {
    width: 100%;
    // height: 220px;
    min-height: 200px;
    color: var(--mx-text-base-color) !important;
  }
}
