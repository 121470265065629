.btns {
  position: absolute;
  right: 25px;
  top: 13px;
}

.tabs {
  overflow: visible;
  :global {
    .ant-tabs-nav {
      padding-left: 0px;
    }
  }
}

.content {
  // margin-bottom: 60px !important;
  padding: 15px 20px 32px 20px !important;
}

.top {
  .topTags {
    position: absolute;
    top: 18px;
    left: 260px;
    .hint {
      display: inline-block;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 5px 0;
    }
    :global {
      .ant-space-item {
        line-height: 12px;
      }
    }
  }
  .title {
    position: relative;
    color: var(--text-color);
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;
    margin: 0;
  }
  .title:before {
    content: '';
    width: 4px;
    height: 20px;
    background-color: var(--mx-primary-color);
    position: absolute;
    left: 0;
    top: 4px;
  }
}
