.tableStyles {
  margin-top: 8px;

  :global {
    .ant-table-summary {
      display: table-header-group;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
