.content {
  padding: 20px;
  :global {
    .ant-form-item-label > label {
      color: var(--mx-text-secondary-color);
    }
    .ant-form-item-control {
      color: var(--mx-text-base-color);
    }
  }
  .stepsStyle {
    width: 100%;
    height: 32px;
    display: flex;
    padding: 24px 174px 37px 174px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
  .firstStep {
    padding: 90px 25% 90px 30%;
    :global {
      .ant-select.ant-select-in-form-item {
        width: 464px;
      }
      .ant-picker-range {
        width: 464px;
      }
      .ant-input-number-group-wrapper {
        width: 464px;
      }
    }
    .checkIcon {
      position: absolute;
      width: 16px;
      height: 16px;
      color: #52c41a;
      z-index: 100;
      right: -48px;
      top: 9px;
    }
  }
  .secondStep {
    padding: 0 32px;
    .productonTitle {
      font-size: 16px;
      font-weight: 500;
      color: var(--mx-text-base-color);
      line-height: 24px;
      margin-bottom: 16px;
    }
    .activityContent {
      height: 100%;
      width: 100%;
      .flexTitle {
        display: flex;
        justify-content: space-between;
      }
      .activityTitle {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        color: var(--mx-text-base-color);
        line-height: 24px;
        margin-bottom: 14px;
      }
      .totalFootFit {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: var(--mx-text-base-color);
        line-height: 22px;
      }
      .blueFootFit {
        color: #00adff;
      }
    }
  }
  .thirdStep {
    padding: 17px 32px 0px 32px;

    .blueSpan {
      color: #00adff;
    }
  }
  .prevButtonStyle {
    display: inline-block;
  }
  .nextButtonStyle {
    display: inline-block;
  }
  .stickyShadowFooter {
    position: sticky;
    left: 0;
    padding-left: 28px;
    bottom: 0px;
    height: 60px;
    width: 100%;
    z-index: 999;
    background: var(--mx-module-bg-color);
  }
}
