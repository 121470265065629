.top {
  padding: 16px;
  border: 1px solid var(--mx-base-border-color);
  margin-top: 10px;
}

.extra {
  font-size: 12px;
  margin-top: 5px;
  width: 800px;
  p {
    display: inline-block;
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  background: var(--mx-disabled-bg-color);
  width: calc(100% - 268px);
  margin-left: -30px;
  padding: 14px 60px;
  text-align: right;
  z-index: 999;
}

.red {
  color: #f5222d;
}
.pvStatus {
  display: flex;
  .unDelivery {
    width: 60px;
  }
}
.deliveryBox {
  flex: 1;
  display: flex;
  gap: 8px;
  .deliveryBtn {
    color: var(--mx-warning-color);
  }
  .deliveryTip {
    color: var(--mx-text-desc-color);
  }
  .empty {
    flex: 2;
  }
}
.newNetBtn {
  display: flex;
  padding-bottom: 12px;
  .tipsBox {
    padding: 0 10px;
    align-items: center;
    flex: 1;
    display: flex;
    .tips {
      flex: 1;
      color: var(--mx-text-desc-color);
      padding: 0 5px;
    }
  }
}

.priceTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin: 20px 0;
}

.formRow {
  margin-bottom: 20px;
  :global {
    .ant-col {
      .ant-form-item {
        margin-bottom: 0px;
        .ant-form-item-row {
          flex-direction: row;
          align-items: baseline;
          line-height: 30px;
        }
      }
    }
  }
  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

:global {
  .ant-card {
    // border-top: 2px solid #5d5d5d;
    // border-bottom: 2px solid #5d5d5d;
    background-color: var(--mx-card-dark-bg-color);
  }
}

.radioStyle {
  :global {
    .ant-radio-wrapper {
      margin-left: 28px;
    }
  }
}

.title_line {
  font-size: 14px;
  color: var(--mx-text-base-color);
  padding-bottom: 10px;
  .right_btn {
    color: var(--primary-color);
    cursor: pointer;
  }
}
.title_line_box {
  font-size: 14px;
  color: var(--mx-text-base-color);
  padding-bottom: 10px;

  .right_tips {
    font-size: 14px;
    color: var(--mx-text-desc-color);
  }
}

.circuit_box {
  padding: 8px 12px;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--mx-base-bg-color);
  display: flex;
  align-items: center;
  .left {
    width: 120px;
    color: var(--mx-text-base-color);
  }
  .divider {
    width: 1px;
    height: 24px;
    background-color: var(--mx-base-border-color);
    margin: 0 8px 0 16px;
  }
  .right {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--mx-text-secondary-color);
  }
}
