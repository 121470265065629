.cardBoxSty {
  height: 0;
  padding-bottom: 2px;
  justify-content: center;
  .top_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .charts_wrapper {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tabsStyle {
  width: 100%;
  max-width: 185px;
  :global {
    .ant-tabs-nav {
      margin: 0px;
    }
    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav-list,
    .ant-tabs-nav-operations {
      height: 24px;
    }
    .ant-tabs-tab {
      font-size: 11px;
      font-weight: 400;
      color: #94bdec;
      line-height: 15px;
      padding: 3px 12px;
      border-radius: 2px;
      border: 1px solid #295281;
      height: 24px;
      &:hover {
        background: rgba(74, 144, 226, 0.15);
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 6px !important;
    }
    .ant-tabs-tab-active {
      height: 24px;
      background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      border-radius: 2px;
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;
      .ant-tabs-tab-btn {
        color: #fff !important;
      }
      &:hover {
        background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
      }
    }
    .ant-tabs-ink-bar {
      height: 1px;
      background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
    }
    .ant-tabs-nav-operations {
      margin-left: 6px;
      border-radius: 2px;
      border: 1px solid #295281;
    }
    .ant-tabs-nav-more {
      padding: 0px 8px !important;
    }
  }
}
.date_picker {
  width: 120px;
  height: 24px;
  padding: 4px;
}

.cardItem {
  height: auto;
  background: rgba(74, 144, 226, 0.1);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 6px 8px;

  &:hover {
    border: 1px solid var(--primary-color);
    cursor: pointer;
  }

  p {
    margin-bottom: 0px;
  }

  .firstLine {
    height: 22px;
    font-size: 10px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .firstLine_left,
    .firstLine_right {
      font-weight: 400;
    }

    span {
      margin-left: 8px;
    }
  }

  .secondLine {
    height: 22px;
    font-size: 10px;
    font-weight: 500;
    color: rgba(255, 255, 255);
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .secondLine_left {
      font-size: 12px;
    }
    .secondLine_right {
      font-weight: 400;
    }
  }
}

.cardItemClicked {
  background: rgba(74, 144, 226, 0.3);
  box-shadow: inset 0px 0px 10px 0px #4a90e2;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(360deg, rgba(0, 173, 255, 1), rgba(82, 231, 255, 1)) 1 1;
}

.swiperWrapper {
  position: relative;
}

.swiper_prev_btn {
  top: 21px;
  left: -15px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.swiper_next_btn {
  top: 21px;
  right: -15px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
