.wrapperStyles {
  padding: 0;
  background-color: transparent;
  overflow: hidden;
}
.layout {
  min-height: calc(100vh - 150px);
  background: transparent;
}
.content {
  padding: 0px !important;
  min-height: calc(100vh - 130px) !important;
  background: var(--mx-module-bg-color) !important;
}

.siderDemo {
  background: var(--mx-module-bg-color) !important;
  border-radius: 2px;
}
.tabs {
  :global {
    .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
      bottom: inherit;
      top: 0;
    }
    .ant-tabs-nav {
      &::before {
        content: none;
      }
      margin-bottom: 0px;
      padding: 0 20px;
    }
  }
}
