.wrapper {
  padding: 20px;

  :global {
    .ant-select.ant-select-in-form-item {
      max-width: 464px;
      // width: 100%;
    }
    .ant-form-item-label > label {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}

.trafficContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  padding-top: 12px;
  padding-bottom: 16px;
}

.basicInfo {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
  padding-bottom: 16px;
}
