.content {
  padding: 14px 32px 0px 32px;
}
.wrapperContent {
  padding: 20px;
}
.stickyShadowFooter {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  z-index: 999;
  background: var(--mx-module-bg-color);
}
