.treeArea {
  padding: 10px 0px;
  .searchArea {
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .treeArea {
    overflow-x: auto;
  }
  .darkTreeArea {
    :global {
      .ant-tree-switcher-noop {
        width: 14px;
      }
      .ant-tree .ant-tree-treenode {
        padding: 0px;
        margin-bottom: 4px;
        line-height: 32px;
      }
      .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        flex: unset;
        width: 100%;
        overflow: hidden;
      }
      .ant-tree-title {
        line-height: 32px;
      }
      .ant-tree-list-holder-inner {
        padding-bottom: 20px;
      }
      .ant-tree .ant-tree-node-content-wrapper {
        padding-right: 24px;
      }
      .ant-tree-title {
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
      }

      .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
      }
      .ant-tree-treenode-selected {
        position: relative;

        &:before {
          content: '';
          width: 2px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .lightTreeArea {
    :global {
      .ant-tree-switcher-noop {
        width: 14px;
      }
      .ant-tree .ant-tree-treenode {
        padding: 0px;
        margin-bottom: 4px;
        line-height: 32px;
        &:hover {
          z-index: 1;
        }
      }
      .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
        flex: unset;
        width: 100%;
        overflow: hidden;
      }
      .ant-tree-title {
        line-height: 32px;
      }
      .ant-tree-list-holder-inner {
        padding-bottom: 20px;
      }
      .ant-tree .ant-tree-node-content-wrapper {
        padding-right: 24px;
      }
      .ant-tree-title {
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
      }

      .ant-tree-treenode-selected {
        position: relative;

        &:before {
          content: '';
          width: 2px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}
