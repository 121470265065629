.operationArea {
  padding-top: 20px;
  padding-left: 20px;
  margin-right: 15px;
  border: 1px solid var(--mx-light-border-color);
  height: 90%;
  border-left: none;
  overflow: auto;
  width: 100%;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.unitFormItems {
  :global {
    .ant-space-item {
      width: 231px;
    }
  }
}

.DatePicker {
  width: 100%;
}

.InputNumber {
  width: 100%;
}
.Tabs {
  position: relative;
  top: 63px;
  left: 79px;
  z-index: 2;
}

.ReactEcharts {
  position: absolute;
  z-index: 0;
  left: -8px;
  top: -28px;
  // left: 17px;
  // top: 87px;
}

.BaselineModal {
  position: relative;
  :global {
    .ant-modal .ant-modal-header {
      margin-bottom: 0;
    }
  }
}

.advancedValueSty {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.tooltipSty {
  width: 100%;
}
.unitFormItem {
  margin-bottom: 10px;
}

// .divider {
//   width: 656px;
//   height: 1px;
//   background: rgba(255, 255, 255, 0.2);
// }
.advancedSpan {
  cursor: pointer;
  width: auto;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-primary-color);
  line-height: 22px;
}

.outPutInput {
  max-width: 480px;
}
.styleName {
  font-size: 16px;
  font-weight: 500;
  color: var(--mx-text-base-color);
}
.wrapperStyles {
  // height: calc(100vh - 130px) !important;
  padding: 0;
  background-color: var(--mx-page-bg-color);
  :global {
    .FormContent_headerWrapper__XsryC {
      padding-bottom: 0;
      font-size: 18px;
      font-weight: 500;
      color: var(--mx-text-base-color);
    }
  }
}

.layout {
  min-height: calc(100vh - 130px);
  background-color: transparent;
}

.content {
  background: var(--mx-module-bg-color);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: var(--mx-disabled-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mx-text-base-color);

    &:hover {
      background: rgba(var(--mx-main-color), 0.4);
    }
  }
}

.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contentWrapper {
  background: none;
}
.filterTop {
  padding: 10px 15px 0px 20px;
}
.filterTop::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  // background-image: url(https://eqinfo.test.maxtropy.com/static/media/bg.b9c89b5c70726252219c.svg);
  opacity: 0.6;
}
