.tags {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding: 10px 0;
  max-height: 84px;
  overflow-x: hidden;
  .tagItem {
    width: 175px;
    line-height: 24px;
    font-size: 12px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
    padding: 0 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;

    &.active {
      background: rgba(74, 144, 226, 0.3);
      border-color: #52e7ff;
    }
  }
}
