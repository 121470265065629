.carbon-chart-tooltip {
  min-width: 200px;
  font-size: 14px !important;
  line-height: 20px !important;
  .carbon-tooltip-title {
    color: rgba(255, 255, 255, 0.65);
  }
  > div {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    span {
      flex: 1;
      padding-left: 8px;
      text-align: right;
    }
  }
}

.carbon-chart-tooltip2 {
  min-width: 240px;
}
