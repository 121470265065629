.formSpaceContent {
  margin-bottom: 8px;
  width: 100%;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 20px;
  padding: 8px 0;
}

.value {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 20px;
}
