.tabs {
  width: 100%;
  // :global {
    // .ant-tabs-tab {
    //   color: #fff;
    //   margin: 0 30px;
    //   padding-left: 10px;
    //   padding-right: 10px;
    // }

    // .ant-tabs-top > .ant-tabs-nav::before,
    // .ant-tabs-bottom > .ant-tabs-nav::before,
    // .ant-tabs-top > div > .ant-tabs-nav::before,
    // .ant-tabs-bottom > div > .ant-tabs-nav::before {
    //   border-color: rgba(255, 255, 255, 0.35);
    // }
    // .ant-tabs-nav-list div:first-child {
    //   margin-right: 0px;
    // }
  // }
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  .tabDesc {
    font-size: 12px;
    margin-top: 2px;
  }
  :global {
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin-top: 0px;
    }
  }
}
