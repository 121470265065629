.wrapper {
  padding: 0;
  background-color: transparent;
  min-width: 1366px;
  .layout {
    min-height: calc(100vh - 130px);
    background-color: transparent;
  }
  .content {
    background: #232324;
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
    padding: 20px;

    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      top: 50%;
      margin-top: -33px;
      width: 20px;
      height: 66px;
      background: var(--mx-disabled-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--mx-text-base-color);
      &:hover {
        background: rgba(var(--mx-main-color), 0.4);
      }
    }
  }

  .deviceInfo {
    > div {
      margin-bottom: 32px;
    }

    .top {
      margin-bottom: 20px;
      display: flex;
      .switchPicker {
        flex: 1;
      }
    }

    .subTitle {
      font-weight: 500;
      font-size: 16px;
      color: var(--mx-text-base-color);
      line-height: 24px;
    }
    .deviceBoard {
      display: flex;
      gap: 16px;

      .item {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        padding: 8px 16px;

        &:first-child {
          width: 340px;
        }
        &:not(:first-child) {
          flex: 1;
          width: 0;
        }
      }
    }
  }
}
