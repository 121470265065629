.wrapper {
  padding: 0;
  background-color: transparent;
  .layout {
    min-height: calc(100vh - 130px);
    background-color: transparent;
  }
  .content {
    background: transparent;
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
    position: relative;
    display: flex;
    flex-direction: column;

    .filterTop {
      padding: 20px 20px 0px 20px;
      background-color: var(--mx-module-bg-color);
    }

    .operationArea {
      margin-top: 10px;
      padding: 20px;
      background-color: var(--mx-module-bg-color);
      flex: 1;
    }

    .toggleBtn {
      cursor: pointer;
      position: absolute;
      z-index: 11;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 66px;
      background: var(--mx-disabled-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--mx-text-base-color);

      &:hover {
        background: rgba(var(--mx-main-color), 0.4);
      }
    }
  }
}
