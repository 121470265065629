.page {
  min-height: calc(100vh - 50px);
  position: relative;
  background-color: unset;
  padding: 8px 13px 8px;
  background-color: var(--mx-page-bg-color);
  :global {
    .ant-layout .ant-layout-sider,
    .ant-layout .ant-layout-sider-children {
      background-color: #232324;
    }
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('./bg.svg');
    opacity: 0.6;
  }
  &__header {
    margin-bottom: 7px;
    padding-left: 10px;
    position: relative;
    height: 20px;
    box-sizing: border-box;
  }
  &__filter {
    background: var(--component-background);
    margin-bottom: 10px;
    padding: 10px 20px 0;
  }
  &__content {
    // padding: 15px 10px;
    min-height: calc(100vh - 180px);
    &__header {
      margin-bottom: 15px;
    }
    &__section-title {
      font-size: 16px;
      color: var(--text-color);
      padding-left: 11px;
      line-height: 24px;
      position: relative;
      margin-top: 35px;
      &::before {
        content: '';
        position: absolute;
        background: var(--primary-color);
        width: 4px;
        height: 16px;
        left: 0;
        top: 4px;
      }
    }
    &__footer {
      background-color: var(--component-background);
      margin-top: 20px;
      position: sticky;
      bottom: 0;
      z-index: 1000;
    }
  }
}

.border_wrapper {
  border-style: solid;
  border-width: 140px 180px;
  border-image-source: url('./border.svg');
  border-image-slice: 140 180;
  border-image-repeat: repeat;

  &_inner {
    margin: -123px -163px -122px -163px;
    position: relative;
  }
}
