.compareModalBody {
  min-height: 560px;
  display: flex;

  .leftCol {
    width: 282px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-right: 20px;

    .compareSection {
      padding: 20px 0;
    }
  }

  .rightCol {
    flex: 1;
    padding-left: 20px;

    .dateSwitch {
      // display: flex;
      align-items: center;
      // float: left;
      overflow: hidden;
      margin-bottom: 10px;
      .datePickerArea {
        margin-left: 15px;
        display: inline-block;
      }
      .switchBtn {
        // max-width: 262px;
        display: inline-block;
      }
      .operation {
        float: right;
        // display: inline-block;
      }
    }
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid var(--mx-primary-color);
  }

  :global {
    .ant-divider-horizontal.ant-divider-with-text {
      color: #1e5a7c;
    }
    .ant-divider {
      position: relative;

      .ant-divider-inner-text {
        padding: 0 14px;
        color: var(--mx-primary-color);
        font-size: 14px;
      }
    }
  }
}

.btnArea {
  // margin-top: 20px;
  text-align: center;
}
