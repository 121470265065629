$primaryColor: var(--primary-color);
$base-text-color: var(--base-text-color);

.title {
  color: var(--heading-color);
  font-weight: 500;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  margin: 0 30px 0 15px;
  line-height: 30px;
}

.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.list {
  .name {
    font-family: PingFangSC-Medium, PingFang SC, sans-serif;
    font-weight: 500;
    margin: 5px 0;
  }
  .tag {
    color: $primaryColor;
    border: 1px solid $primaryColor;
    padding: 0 3px;
  }
  .content {
    display: flex;
    padding: 20px 10px;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba(var(--base-text-color), 0.15);
    margin-top: -1px;
    cursor: pointer;
    .leftContent {
      min-width: 200px;
    }
    .badge {
      margin-right: 10px;
      white-space: nowrap;
      color: $primaryColor;
      position: relative;
      padding: 1px 10px;
      margin-bottom: 4px;
      display: inline-block;
      .bac {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $primaryColor;
        opacity: 0.2;
        top: 0;
        left: 0;
        border-radius: 20px;
      }
    }
    .word_wrap {
      word-wrap: break-word;
    }
    .actionContent {
      text-align: right;
      margin-top: 10px;
      .action {
        margin-left: 30px;
        position: relative;
        display: inline-block;
        text-align: left;
        .btn {
          padding-left: 0;
          padding-right: 0;
          margin-right: 10px;
        }
        .gray {
          color: rgba($base-text-color, 0.5);
        }
      }
      .action:before {
        content: '';
        width: 2px;
        height: 14px;
        background: $primaryColor;
        position: absolute;
        left: -8px;
        top: 9px;
      }
    }
    :global {
      .ant-descriptions-row > th {
        padding-bottom: 8px;
      }
      .ant-descriptions-row > td {
        padding-bottom: 0;
      }
    }
  }
  .content:hover {
    background: rgba($base-text-color, 0.02);
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    border: 1px solid var(--border-color-split);
  }
}
