.wrapperPadding {
  padding: 20px;
}

.wordStyle {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 5px;
  margin-bottom: 0px;
}
