.beatIntervalRange {
  width: 545px;
}

.formItemStyle {
  margin-bottom: 0 !important;
}
.inputNumberStart {
  display: inline-block;
  width: calc(50% - 40px);
}

.inputNumberEnd {
  display: inline-block;
  width: calc(50% - 40px);
}

.pInterval {
  display: inline-block;
  padding: 0 8px;
  margin-bottom: 0;
  margin-top: 5px;
}
