.modal {
  .tips {
    display: flex;
    justify-content: flex-end;
  }
}

.count {
  color: #d62500;
  font-weight: 500;
  padding: 0 6px;
}
