.innerContainer {
  height: 100%;
  margin: 0 24px;

  .title {
    position: relative;
    height: 56px;
    text-align: center;
    img {
      position: absolute;
      height: 56px;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    span {
      position: relative;
      font-family: YouSheBiaoTiHei;
      font-size: 24px;
      color: #ffffff;
      line-height: 56px;
      font-style: normal;
    }
  }
  .module {
    padding-bottom: 24px;
    &:first-child > div:first-child {
      padding-bottom: 24px;
    }
    > div:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  .content {
    display: flex;
    gap: 48px;
    flex: 1;
    overflow: hidden;
    .module {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
    }
  }
}

.outerContainer {
  overflow-x: hidden;
  background: #212a33;
  height: 100vh;
  min-width: 1366px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  margin: 0;
}
