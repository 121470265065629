.container {
  padding: 0;
  background-color: var(var(--mx-page-bg-color));

  .layout {
    min-height: calc(100vh - 130px);
    background-color: transparent;
  }

  .content {
    background: var(--mx-module-bg-color);
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
  }

  .toggleBtn {
    cursor: pointer;
    position: absolute;
    z-index: 11;
    top: 50%;
    margin-top: -80px;
    width: 20px;
    height: 66px;
    background: var(--mx-disabled-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mx-text-base-color);

    &:hover {
      background: rgba(var(--mx-main-color), 0.4);
    }
  }

  .paneConfig {
    padding: 20px;
    :global {
      .mx-rc-form.mx-rc-form-standard .mx-rc-form-item.ant-form-item {
        margin-bottom: 0;
      }
    }

    .extraDesc {
      flex: 1;
      color: var(--mx-text-desc-color);
      font-size: 14px;
      :global {
        .anticon {
          margin: 0 4px 0 8px;
        }
      }
    }

    .dynamicTable {
      .requiredTitle {
        &::after {
          content: '*';
          color: var(--mx-error-color);
          margin-left: 4px;
        }
      }
      :global {
        .mx-rc-form-item {
          margin-bottom: 0;

          .ant-form-item-control {
            max-width: 100%;
          }
        }
      }
    }

    .addBtn {
      margin-top: 16px;
      width: 100%;
      :global {
        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}
