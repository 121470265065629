.content {
  :global {
    .ant-select.ant-select-in-form-item {
      width: 308px;
    }
    .ant-input {
      height: 80px;
      width: 685px;
    }
  }
}
