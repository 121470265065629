.rightContent {
  padding: 20px;

  .progressContent {
    height: 160px;
    margin: 16px 0;

    .progressWrapper {
      width: 100%;
      height: 100%;
      padding: 26px 24px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;

      .progressCircleStyle {
        width: 76px;
        height: 76px;
        margin-left: 16px;
        background-image: url(../../assets/circle@2x.png);
        background-size: 100% 100%;

        .progressCircleInlineStyle {
          p {
            margin: 0;
          }

          .firstLine {
            padding-top: 13px;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
            line-height: 25px;
            text-align: center;
            font-style: normal;
          }

          .secondLine {
            margin-top: 8px;
            font-weight: 600;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.65);
            line-height: 17px;
            text-align: center;
            font-style: normal;
          }
        }
      }

      .wordDescription {
        padding-top: 25px;
        padding-left: 30px;
        p {
          margin: 0;
        }
        .firstLine {
          display: flex;
          justify-content: space-around;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 22px;
          text-align: left;
          font-style: normal;
          padding-bottom: 8px;
          .numStyle {
            color: rgba(255, 255, 255, 0.85);
          }
          .numStyleErr {
            color: #e64242;
          }
        }
        .secondLine {
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          padding-top: 7px;
        }
      }

      .timeAndInfo {
        display: flex;
        justify-content: space-between;

        .timeStyle {
          font-weight: 500;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.65);
          line-height: 24px;
          font-style: normal;
          margin: 0;

          .time {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            font-style: normal;
            background: linear-gradient(0deg, #51a1ff 0%, #51dbff 100%);
            color: #00adff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-left: 16px;
          }

          .unit {
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 22px;
            text-align: left;
            font-style: normal;
            padding-left: 4px;
          }
        }

        .infoCard {
          width: 238px;
          height: 40px;
          background: rgba(82, 196, 26, 0.1);
          border-radius: 2px;
          border: 1px solid rgba(82, 196, 26, 0.5);
          p {
            font-weight: 400;
            font-size: 14px;
            color: #52c41a;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            margin: 0;
            padding: 9px 17px;
          }
        }
        .infoCardWorn {
          // width: 238px;
          height: 40px;
          background: rgba(250, 173, 20, 0.1);
          border-radius: 2px;
          border: 1px solid rgba(250, 173, 20, 0.5);
          p {
            font-weight: 400;
            font-size: 14px;
            color: var(--mx-warning-color);
            line-height: 22px;
            text-align: left;
            font-style: normal;
            margin: 0;
            padding: 9px 17px;
          }
        }
      }

      .progressLine {
        margin-top: 24px;
        :global {
          .ant-progress .ant-progress-success-bg {
            background: linear-gradient(90deg, #51a1ff 0%, #51dbff 100%);
          }
        }
        .legendLine {
          padding-top: 8px;
          display: flex;
          .legend {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 0;
            }
            .legendColor {
              width: 8px;
              height: 8px;
              margin-right: 6px;
            }
            .legendWord {
              padding-right: 32px;
              font-weight: 400;
              font-size: 14px;
              color: rgba(255, 255, 255, 0.65);
              line-height: 22px;
              text-align: left;
              font-style: normal;
              .time {
                padding-left: 8px;
              }
              .rate {
                padding-left: 9px;
              }
            }
          }
          .legendBorder {
            padding-left: 8px;
            margin-right: 8px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
}
