.infoLine {
  height: 38px;
  background: #2d2d2d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  padding: 8px 16px;

  p {
    margin-bottom: 0;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    flex: 1;

    span {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.detail {
  height: 462px;
  margin-top: 16px;
  display: flex;

  .pieInfo {
    width: 400px;
    padding-bottom: 24px;
    position: relative;

    img {
      position: absolute;
      width: 224px;
      height: 224px;
      left: 76px;
      top: 18px;
    }
  }

  .title {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    margin-bottom: 0;
  }

  .divider {
    width: 1px;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 24px;
  }

  .sankeyInfo {
    width: 542px;

    .energyNumInfo {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      line-height: 22px;
      margin-bottom: 0;
      margin-top: 30px;

      span {
        font-weight: 500;
        color: #faad14;
      }
    }
  }

  .mediumWidth {
    width: 100%;
  }
}
