.title {
  font-size: 16px;
  margin-top: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  line-height: 24px;
}

.echart_box {
  display: flex;
  flex-direction: column;
  .echarts {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.pieBg {
  background-image: url(../../assets/chart-bg@2x.png);
  width: 368px;
  scale: 0.458;
  height: 368px;
  position: absolute;
  left: -86px;
  animation: rotation 15s linear infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
