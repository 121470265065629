$component-background: var(--mx-module-bg-color);
.searchArea {
  // padding: 0 10px;
  padding-bottom: 20px;
}
.page_outer {
  :global {
    .page__content {
      background-color: transparent;
      padding: 0;
    }
  }
}
.content_left,
.content_right {
  background: var(--mx-module-bg-color);
  padding: 20px;
  padding-bottom: 0px;
}
.content_right {
  margin-left: 10px;
}
.layout {
  min-height: calc(100vh - 128px);
  background-color: transparent;
}

.content {
  background: var(--mx-module-bg-color);
  border-radius: 2px;
  position: relative;
  margin-left: 10px;
}

.tree_outer {
  height: calc(100vh - 290px);
  margin: 20px 0 0 0;
  background: #2a2a2b;
  overflow-y: auto;
  width: 300px;
  .tree_area {
    padding: 20px 0;
  }
}
