.inputNumberStart {
  display: inline-block;
  width: calc(50% - 40px);
}

.inputNumberEnd {
  display: inline-block;
  width: calc(50% - 40px);
}

.formItemStyle {
  margin-bottom: 0 !important;
}

.featureDiv {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 16px;
  margin-bottom: 24px;
}

.pInterval {
  display: inline-block;
  padding: 0 8px;
  margin-bottom: 0;
  margin-top: 5px;
}
