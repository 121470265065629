.bothBox {
  display: flex;
  gap: 24px;
}
.leftDate {
  width: 220px;
  height: 449px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  .title {
    padding: 8px 8px 20px;
    font-weight: 500;
    font-size: 14px;
    color: var(--mx-text-base-color);
    line-height: 20px;
  }
  .dateList {
    overflow-y: auto;
    height: calc(100% - 60px);
  }
  .rangeName {
    padding: 0 16px;
    line-height: 32px;
    color: var(--mx-text-base-color);
    cursor: pointer;
  }
  .selected {
    background: #3e3e3e;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      left: 0px;
      top: 0px;
      width: 2px;
      height: 32px;
      background-color: #00adff;
    }
  }
}

.rightInfo {
  height: 449px;
  flex: 1;
  overflow-y: auto;
  .title {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 22px;
  }
  .row {
    display: flex;
    margin-bottom: 24px;
    .label {
      color: var(--mx-text-secondary-color);
    }

    & > div {
      flex: 1;
    }

    .beatInfo {
      margin-bottom: 4px;
    }
  }
}
