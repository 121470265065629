$prefixCls: 'mx-device-tags';

$primaryColor: var(--primary-color);

.#{$prefixCls} {
  &-add {
    color: $primaryColor;
    border: 1px dashed $primaryColor;
    background: var(--component-background);
  }
}
