.titleButtonUnChecked {
  background: #232324 !important;
  border-radius: 2px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.65);
}

.titleButtonChecked {
  background: rgba(74, 144, 226, 0.2) !important;
  border-radius: 2px !important;
  border: 1px solid var(--mx-primary-color) !important;
  color: var(--mx-primary-color);
}
