.form {
  :global {
    .ant-form-item-label > label {
      white-space: normal;
      height: 38px;
    }
  }
}

.icon {
  color: #9e730e;
  margin-right: 5px;
}

.table_text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 8px;
  .red {
    color: #d62500;
    padding: 0 5px;
    font-weight: 500;
  }
}

.input-suffix {
  margin-bottom: 24px;
  margin-left: 80px;
}

.line_red {
  color: red;
}

.empty_h {
  height: 20px;
}

.base_content {
  color: rgba(255, 255, 255, 0.85);
}

.tips_text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);

  .txt {
    padding: 0 5px;
  }
}

.flex {
  display: flex;
}

.clickViews {
  cursor: pointer;
  padding: 0 10px;
  color: var(--primary-color);
}

.xing {
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ac222a;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}
