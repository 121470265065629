.time_box {
  display: flex;
  .time_gap {
    flex: 1;
    background-color: #656666;
    height: 32px;
    transform: all 0.2s;
  }
  .bigGap {
    margin-right: 3px;
  }
  .smallGap {
    margin-right: 1px;
  }
  .interval {
    margin-right: 4px;
  }
}
.time_fix {
  display: flex;
  width: calc(50% - 3px);
  justify-content: space-between;
  padding: 10px 0;
}
.all {
  width: 100%;
}
.halfRight {
  margin-left: 4px;
}
