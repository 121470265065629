$version-background: var(--border-color-split);

.modal {
  .tips {
    svg {
      color: #009fa8;
    }

    .grey {
      color: rgba(var(--base-text-color), 0.35);
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.estunDetails {
  .action {
    line-height: 32px;
  }

  .version {
    width: 66px;
    height: 24px;
    background: $version-background;
    border-radius: 16px;
    text-align: center;
  }
}

.mockingbirdForm {
  .action {
    line-height: 32px;
  }

  .version {
    width: 66px;
    height: 24px;
    background: $version-background;
    border-radius: 16px;
    text-align: center;
  }
}
