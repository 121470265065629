.outer {
  background-color: #131313;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  .header {
    font-size: 16px;
    padding: 10px 10px 5px;
    .head_icon {
      width: 30px;
      height: 30px;
      background: #104d94;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .footer {
    padding: 5px 0;
    border-top: 1px solid #22415f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    :global {
      .ant-btn {
        padding: 4px 0px;
      }
    }
  }
  .content {
    padding: 15px 10px 15px 25px;
    .line {
      padding-bottom: 10px;
      display: flex;
      .label {
        width: 50px;
      }
      .value {
        flex: 1;
        color: rgba(255, 255, 255, 0.65);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        

      }
    }
  }
}
