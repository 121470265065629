.treeArea {
  padding-top: 4px;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  margin-bottom: 20px;
  border: 1px solid var(--mx-light-border-color);
  .treeBox {
    flex: 1;
    overflow: auto;
  }
  :global {
    .ant-spin-nested-loading {
      height: 100px;
    }
  }
}
