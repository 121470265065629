.indicatorValue_card_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .itemcard_wrapper {
    width: 182px;
    height: 64px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    padding: 8px 16px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }
  .itemcard_wrapper_top_label {
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .itemcard_wrapper_top_value {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }

  .long_itemcard_wrapper {
    width: 320px;
    min-height: 64px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
    padding: 8px 16px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
  }

  .itemDesc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .itemRow {
    margin-top: 4px;
    display: flex;
  }

  .subTitle {
    display: inline-block;
    width: 80px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 22px;
  }

  .row_value {
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 22px;
    width: 60px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.chart_box_inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
