.leftPlanList {
  width: 270px;
  height: calc(100vh - 188px);
  padding-right: 10px;

  .searchArea {
    padding: 15px 12px 16px 0;
  }

  .list {
    height: calc(100vh - 335px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ffffff40;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #232324;
      border-radius: 10px;
    }
  }
}

.cardItem {
  height: 66px;
  background: #2d2d2d;
  border-radius: 2px;
  border: 1px solid var(--mx-light-border-color);
  padding: 8px 0 8px 16px;
  position: relative;

  &:hover {
    border: 1px solid var(--mx-primary-color);
    cursor: pointer;
  }

  .firstLine {
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: var(--mx-text-base-color);
    line-height: 24px;
    margin-bottom: 0px;
  }

  .secondLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;

    .secondLine_left {
      height: 22px;
      font-size: 14px;
      font-weight: 400;
      color: var(--mx-text-secondary-color);
      line-height: 22px;
      margin-bottom: 0px;
    }
  }

  .closeIcon {
    position: absolute;
    top: 4px;
    right: 6px;
  }
}

.cardItemClicked {
  background: rgba(74, 144, 226, 0.1);
  border: 1px solid var(--mx-primary-color);
}
