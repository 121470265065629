.content {
  min-height: calc(100vh - 142px);
  margin: -20px;

  .button {
    padding: 25px 28px;
  }
}

.detail {
  padding: 0 4px 24px;

  .inline {
    margin-bottom: 30px;

    .field {
      align-items: center;
    }
  }

  .field {
    display: flex;
    flex-direction: row;
    .label {
      color: var(--mx-text-secondary-color);
    }

    .border {
      width: 66px;
      height: 24px;
      background: var(--border-color-split);
      border-radius: 16px;
      text-align: center;
      font-weight: 400;
    }
  }

  .textArea {
    width: 500px;
    word-wrap: break-word;
  }

  .horizontal {
    margin: 12px -15px;
    height: 12px;
    background: var(--body-background);
  }
}

.attributeDetail {
  div.attributeItem:first-child {
    border: none !important;
  }
}
