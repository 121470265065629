.card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 8px;
  // max-width: 220px;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.65);

  .cardTitle {
    flex: 0.8;
    font-size: 14px;
  }

  .value {
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
  }
  .valueContainer {
    flex: 0.2;
    min-width: 30px;
    margin-top: 6px;
  }
}
