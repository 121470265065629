.siteTreeSearchValue {
  color: #f50;
}

.content {
  :global {
    .ant-col-20 {
      max-width: 100%;
    }
  }
}
