.formItemX {
  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-form-item-control-input-content {
      // width: 239.5px;
      width: 202px;
    }
  }
}
