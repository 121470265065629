.FormCardItemStyle {
  :global {
    .ant-form-item {
      margin: 4px 0;
    }
  }
}

.titleDivStyle {
  width: '100%';
  height: 40;
  background-color: '#2F2F2F';
  .titleStyle {
    font-size: 16;
    font-weight: 700;
    padding: 8px;
  }
}
