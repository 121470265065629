.DatePicker {
  max-width: 474px;
  display: block;
}
.message {
  display: block;
  margin-left: 145px;
  margin-bottom: 10px;
}
.peakTip {
  padding-bottom: 24px;
  margin-left: 136px;
  color: var(--mx-text-desc-color);
}
.specialTip {
  margin-left: 136px;
  color: var(--mx-text-desc-color);
}
.content {
  padding: 20px;

  :global {
    .ant-input {
      max-width: 474px;
    }
    .ant-select.ant-select-in-form-item {
      max-width: 474px;
    }
    .CreateMeterReading_DatePicker__Zd2qK {
      max-width: 474px;
      display: block;
    }
    .ant-radio-wrapper span.ant-radio + * {
      // width: 136px;
      display: inline-block;
      padding-top: 2px;
    }
    .ant-radio-group {
      display: flex;
    }
    .ant-radio-wrapper {
      margin-inline-end: 0px;
    }
  }
}
.sticky {
  margin-left: 20px;
}
.selectTag {
  max-width: 1000px;
  height: 24px;
  margin-top: 5px;
  background: rgba(74, 144, 226, 0.1);
  border-radius: 3px;
  border: 1px solid rgba(74, 144, 226, 0.5);
  margin-right: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--mx-primary-color);
  line-height: 22px;
}
.treeBackground {
  margin-left: 136px;
  :global {
    .ant-tree {
      padding-top: 10px;
      background: #1d1d1d;
    }
    .ant-space {
      margin-top: 10px;
    }
  }
}
.steps {
  margin-top: 20px;
  margin-bottom: 32px;
}

.dividerText {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: none;
}

.processText {
  margin-bottom: 8px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
}
.addRowBtn {
  margin-top: 16px;
  width: 100%;
}
.allCheck {
  margin-left: 136px;
}

.TableData {
  width: 100%;
}

.selectName {
  margin-left: 136px;
  margin-bottom: 10px;
}

.siteTreeSearchValue {
  color: #00adff;
}
