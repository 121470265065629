.leftSider {
  background-color: var(--mx-module-bg-color);
  height: calc(100vh - 125px);
  width: 100%;

  .treeArea {
    overflow: hidden;
    height: 100%;
    margin-top: 15px;
    overflow-y: auto;

    // :global {
    //   .ant-tree-switcher-noop {
    //     width: 14px;
    //   }
    //   .ant-tree .ant-tree-treenode {
    //     padding: 0px;
    //     margin-bottom: 4px;
    //     line-height: 32px;
    //     // &:hover {
    //     //   background-color: rgba(64, 64, 64);
    //     // }
    //   }
    //   .ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
    //     flex: unset;
    //     width: 100%;
    //     overflow: hidden;
    //   }
    //   // .ant-tree .ant-tree-node-content-wrapper:hover {
    //   //   background-color: rgb(255, 255, 255, 0);
    //   // }
    //   .ant-tree-title {
    //     line-height: 32px;
    //   }
    //   .ant-tree-list-holder-inner {
    //     padding-bottom: 20px;
    //   }
    //   .ant-tree-switcher {
    //     // color: var(--primary-color);
    //     line-height: 32px;
    //     margin-left: 8px;
    //   }
    //   .ant-tree .ant-tree-node-content-wrapper {
    //     padding-right: 24px;
    //   }
    //   .ant-tree-title {
    //     word-break: break-all;
    //     white-space: nowrap;
    //     overflow: hidden;
    //   }

    //   .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    //     // background-color: transparent;
    //   }
    //   .ant-tree-treenode-selected {
    //     // background-color: rgba(64, 64, 64, 1);
    //     position: relative;

    //     &:before {
    //       content: '';
    //       width: 2px;
    //       height: 100%;
    //       // background-color: #00adff;
    //       position: absolute;
    //       left: 0;
    //       top: 0;
    //     }
    //   }
    //   .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    //     // background-color: rgba(64, 64, 64);
    //   }
    // }
  }
}

.tree_box {
  display: flex;
  overflow: auto;
  // height: calc(100vh - 242px);
  height: 100%;
  :global {
    .ant-tree {
      flex: 1;
    }
  }
}

.treeNodeTitle {
  display: flex;
  justify-content: space-between;

  .treeNodePeriod {
    position: absolute;
    right: 0;
  }
}
