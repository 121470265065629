%padding {
  padding: 0 20px;
}

.filterWrapper {
  @extend %padding;
}
.line_bg {
  height: 10px;
  background:url('../../../assets/images/bg.svg') repeat;
}
.content {
  padding: 20px;
  .operationArea {
    margin-bottom: 10px;
  }
}

.form {
  margin-top: 5px;
}
