.modelingCon {
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 144px;
  .modelInfo {
    display: flex;
    height: 80px;
    flex: 1;
    align-items: center;
    padding: 12px 0 10px;
    background: url(../../assets/linearBg.png);
    background-size: 100% 100%;
    border-radius: 2px;

    .detail {
      flex: 1;
      padding-left: 16px;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      &:last-child {
        border: none;
      }

      .count {
        font-weight: 500;
        font-size: 24px;
        color: rgba(255, 255, 255, 0.85);
        line-height: 32px;
      }
      .desc {
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        line-height: 22px;
      }
    }
  }
}
