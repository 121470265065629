.wrapper {
  min-height: calc(100vh - 110px) !important;
  padding: 20px;
  .formWrapper {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    background: rgba(var(--base-text-color),0.03);
    border-radius: 3px;
    border: 1px solid var(--border-color-split);
    :global {
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
