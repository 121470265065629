.filterStyle {
  display: flex;
  margin-left: 40px;
}

.btnStyle {
  margin-left: 12px;
}

.resetStyle {
  margin-left: 12px;
}

.formStyle {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.tabsStyle {
  :global {
    .ant-tabs-content-holder {
      height: 56vh;
      overflow-y: auto;
    }
  }
}

.pagingStyle {
  margin-right: 15px;
}

.wrapper {
  min-height: calc(100vh - 130px) !important;
  padding: 20px;
}

.time {
  :global {
    .ant-form-item-label > label {
      margin-right: 8px;
    }
  }
}
