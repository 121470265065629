.tableStyles {
  margin-top: 8px;

  :global {
    .ant-table-summary {
      display: table-header-group;
    }
    .ant-table-wrapper .ant-table-row-expand-icon:hover {
      color: var(--mx-primary-color);
    }
  }
}

.summaryCellStyle {
  background-color: #111d2c !important;
}
