.planContent {
  width: 100%;
  height: calc(100vh - 188px);
  border-top: 1px solid var(--mx-light-border-color);
  display: flex;
  padding-left: 20px;

  .divider {
    height: 100%;
    width: 1px;
    background: var(--mx-light-border-color);
    margin-right: 9px;
  }
  .rightStyle {
    width: calc(100% - 270px);
    height: 100%;
    padding: 15px 20px 0 0;
    overflow: auto;
  }
}

.editPlanWrapper {
  padding: 20px 0 0 0;
}
